import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
    selector: 'app-dup-dialog-row',
    templateUrl: './dup-dialog-row.component.html',
    styleUrls: ['./dup-dialog-row.component.css']
})
export class DupDialogRowComponent implements OnInit {
    constructor(private utilitiesService: UtilitiesService) { }

    @Input() rowData: any;
    @Input() controller: string;
    @Input() disabled: boolean = false;
    @Input() apiOptions: any;
    @Output() valueChange = new EventEmitter<any>();

    public TTL_OPTIONS = [
        {
            label: '1 hour',
            value: 1,
        },
        {
            label: '2 hours',
            value: 2,
        },
        {
            label: '3 hours',
            value: 3,
        },
        {
            label: '4 hours',
            value: 4,
        },
        {
            label: '5 hours',
            value: 5,
        },
        {
            label: '6 hours',
            value: 6,
        },
        {
            label: '7 hours',
            value: 7,
        },
        {
            label: '8 hours',
            value: 8,
        },
        {
            label: '9 hours',
            value: 9,
        },
        {
            label: '10 hours',
            value: 10,
        },
        {
            label: '11 hours',
            value: 11,
        },
        {
            label: '12 hours',
            value: 12,
        },
        {
            label: '13 hours',
            value: 13,
        },
        {
            label: '14 hours',
            value: 14,
        },
        {
            label: '15 hours',
            value: 15,
        },
        {
            label: '16 hours',
            value: 16,
        },
        {
            label: '17 hours',
            value: 17,
        },
        {
            label: '18 hours',
            value: 18,
        },
        {
            label: '19 hours',
            value: 19,
        },
        {
            label: '20 hours',
            value: 20,
        },
        {
            label: '21 hours',
            value: 21,
        },
        {
            label: '22 hours',
            value: 22,
        },
        {
            label: '23 hours',
            value: 23,
        },
    ]

    public LINK_REF_OPTIONS = []

    public ENV_OPTIONS = [
        { label: 'dev', value: 'dev' },
        { label: 'qa', value: 'qa' },
        { label: 'prod', value: 'prod' }
    ]

    ngOnInit() {
    }

    onValueChange(value: any) {
        if (!this.disabled) {
            this.valueChange.emit(value);
        }
    }

    resetTimeToMidnight(value: any, event: Event, model: any = null, index: any = null) {
        event.preventDefault();
        
        if (model) {
            if (index !== null && Array.isArray(value)) {
                // Handle array case (multiselectDups)
                const date = new Date(value[index][model]);
                date.setHours(0, 0, 0, 0);
                value[index][model] = date;
            } else {
                // Handle single object case (singleDup)
                const date = new Date(value[model]);
                date.setHours(0, 0, 0, 0);
                value[model] = date;
            }
            this.onValueChange(value);
        } else {
            // Handle direct date value case (dateInput)
            if (value) {
                const date = new Date(value);
                date.setHours(0, 0, 0, 0);
                value = date;
                this.onValueChange(value);
            }
        }
    }

    getFormattedDate(date: any): string {
        return date ? new Date(date).toISOString().split('T')[0] : '';
    }
} 
