import { AuthGuard } from "../auth/auth.guard";
import { SourcingGroupFormComponent } from "../cms-v2/entities/sourcing-group/sourcing-group-form/sourcing-group-form.component";
import { SourcingGroupViewComponent } from "../cms-v2/entities/sourcing-group/sourcing-group-view/sourcing-group-view.component";
import { SourcingGroupTableAgGridComponent } from "../cms-v2/entities/sourcing-group/sourcing-group-table-ag-grid/sourcing-group-table-ag-grid.component";

export const sourcingGroupsRoutes = {
  prefix: 'sourcing-groups',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SourcingGroupTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SourcingGroupFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SourcingGroupViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SourcingGroupFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};