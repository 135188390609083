import { AuthGuard } from "../auth/auth.guard";
import { SponsorTableAgGridComponent } from "../cms-v2/sponsor/sponsor-table-ag-grid/sponsor-table-ag-grid.component";
import { SponsorFormV2Component } from "../cms-v2/sponsor/sponsor-form-v2/sponsor-form-v2.component";
import { SponsorViewV2Component } from "../cms-v2/sponsor/sponsor-view-v2/sponsor-view-v2.component";

export const sponsorsRoutes = {
  prefix: 'sponsors',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SponsorTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SponsorFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SponsorViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SponsorFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};