import { AuthGuard } from "../auth/auth.guard";
import { DailyRewardsFormV3Component } from "../cms-v2/entities/daily-rewards/components/daily-rewards-form-v3/daily-rewards-form-v3.component";
import { DailyRewardsViewComponent } from "../cms-v2/entities/daily-rewards/components/daily-rewards-view/daily-rewards-view.component";
import { DailyRewardsTableAgGridComponent } from "../cms-v2/entities/daily-rewards/daily-rewards-table-ag-grid/daily-rewards-table-ag-grid.component";

export const dailyRewardsRoutes = {
  prefix: 'daily-rewards',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: DailyRewardsTableAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: DailyRewardsFormV3Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: DailyRewardsViewComponent,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: DailyRewardsFormV3Component,    
        canActivate: [AuthGuard],
      },
    ]
  }
};