import { AuthGuard } from "../auth/auth.guard";
import { SeriesAgGridComponent } from "../cms-v2/entities/series/components/series-ag-grid/series-ag-grid/series-ag-grid.component";
import { SeriesFormV2Component } from "../cms-v2/entities/series/components/series-form-v2/series-form-v2/series-form-v2.component";
import { SeriesViewV2Component } from "../cms-v2/entities/series/components/series-view-v2/series-view-v2.component";
import { ReleaseSeriesViewV2Component } from "../entities/release/release-series-view-v2/release-series-view-v2/release-series-view-v2.component";

export const seriesRoutes = {
  prefix: 'series',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SeriesAgGridComponent, 
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SeriesFormV2Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SeriesViewV2Component,                  
        canActivate: [AuthGuard],
      },
      {
        path: `releases/series/:id`,
        component: ReleaseSeriesViewV2Component,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SeriesFormV2Component,  
        canActivate: [AuthGuard],
      },
    ]
  }
};