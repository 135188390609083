<div>
  <!-- Team Colors -->
  <div *ngIf="field.key === 'team_colors'">
    <h2>Team Colors:</h2>
    <div *ngIf="record[field.key]" class="p-grid">
      <div *ngFor="let colorSet of record[field.key]; let i = index" class="p-col-12">
        <div>
          <strong>Team {{ i+1 }} Colors:</strong>
        </div>
        <div class="color-list p-col-12">
          <span *ngFor="let color of colorSet">
            <div class="color-display" [ngStyle]="{'background-color': color}"></div>
            {{ color }}
          </span>
        </div>
      </div>
    </div>
  </div>
  
    <!--  Colors -->
  <div *ngIf="field.key === 'colors'">
      <h2>{{ field.label }}</h2>
    <div *ngIf="record[field.key]" class="p-grid">
      <div *ngFor="let color of record[field.key]; let i = index" class="p-col-12">
        <div>
          <strong class="color-title">Color {{ i+1 }}:</strong> 
        </div>
        <div [ngClass]="{'color-list': true, 'horizontal': layoutMode === 'horizontal', 'vertical': layoutMode === 'vertical'}">
          <div class="color-display" [ngStyle]="{'background-color': color}"></div>
          {{ color }}
        </div>
      </div>
    </div>
  </div>
</div>

