import { AuthGuard } from "../auth/auth.guard";
import { ShapeTableAgGridComponent } from "../cms-v2/entities/shape/components/shape-table-ag-grid/shape-table-ag-grid.component";
import { ShapeFormV2Component } from "../cms-v2/entities/shape/components/shape-form-v2/shape-form-v2.component";
import { ShapeViewV2Component } from "../entities/shape/shape-view-v2/shape-view-v2.component";

export const shapesRoutes = {
  prefix: 'shapes',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ShapeTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ShapeFormV2Component,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ShapeViewV2Component,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ShapeFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};