import { AuthGuard } from "../auth/auth.guard";
import { ExternalPlantDataTableAgGridComponent } from "../cms-v2/entities/external-plant-data/components/external-plant-data-table-ag-grid/external-plant-data-table-ag-grid.component";
import { ExternalPlantDataViewV2Component } from "../cms-v2/entities/external-plant-data/components/external-plant-data-view-v2/external-plant-data-view-v2.component";
import { ExternalPlantDataFormV3Component } from "../cms-v2/entities/external-plant-data/components/external-plant-data-form-v3/external-plant-data-form-v3.component";

export const externalPlantDataRoutes = {
  prefix: 'external-plant-data',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ExternalPlantDataTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ExternalPlantDataFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ExternalPlantDataViewV2Component ,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ExternalPlantDataFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};