import { AuthGuard } from "../auth/auth.guard";
import { NurtureTableAgGridComponent } from "../cms-v2/entities/nurture/components/nurture-table-ag-grid/nurture-table-ag-grid.component";
import { NurtureFormV3Component } from "../cms-v2/entities/nurture/components/nurture-form-v3/nurture-form-v3.component";
import { NurtureViewV3Component } from "../cms-v2/entities/nurture/components/nurture-view-v3/nurture-view-v3.component";

export const nurtureRoutes = {
  prefix: 'nurture',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: NurtureTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: NurtureFormV3Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: NurtureViewV3Component,  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: NurtureFormV3Component,  
        canActivate: [AuthGuard],
      },
    ]
  }
};