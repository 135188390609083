import { AuthGuard } from "../auth/auth.guard";
import { NurtureRarityFormComponent } from "../cms-v2/entities/nurture/nurture-rarity/nurture-rarity-form/nurture-rarity-form.component";
import { NurtureRarityTableAgGridComponent } from "../cms-v2/entities/nurture/nurture-rarity/nurture-rarity-table-ag-grid/nurture-rarity-table-ag-grid.component";
import { NurtureRarityViewComponent } from "../cms-v2/entities/nurture/nurture-rarity/nurture-rarity-view/nurture-rarity-view.component";

export const nurtureRarityRoutes = {
  prefix: 'nurture-rarity',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: NurtureRarityTableAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: NurtureRarityFormComponent,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: NurtureRarityViewComponent,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: NurtureRarityFormComponent,  
        canActivate: [AuthGuard],
      },
    ]
  }
};