import { TestBed } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { ColorTextComponent } from 'src/app/common/ag-grid-table/cell-editors/color-text/color-text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';

@Component({
  selector: 'app-store-listing-v2-table-ag-grid',
  templateUrl: './store-listing-v2-table-ag-grid.component.html',
  styleUrls: ['./store-listing-v2-table-ag-grid.component.sass']
})
export class StoreListingV2TableAgGridComponent implements OnInit {

  public entity: string = 'store-listings-v2';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};


  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const selectedEnvironment = this.agGridToolsService.getSelectedEnvironment()

    this.columnDefs = this.getAllColumns(selectedEnvironment.value);

    this.tableParams = {
      customActionsLabel: 'Store Actions',
      customActionButtons: ['syncStoreListings', 'addToCart', 'viewDifferencesStore'],
      defaultQuery: {  ["CmsEnabled." + selectedEnvironment.value]: true  }
    }


    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
    ];


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs, true);

  }

  getAllColumns(env: string): ColDef[] {
    // Define all your column definitions here, as shown in your component file
    // This includes columns for all environments (dev, qa, prod)
    return [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,
        cellRendererParams: {
          enableDuplicateFeature: true,
          enableDeleteFeature: true,
        }
      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
        cellClass: 'center-cell-content',
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRendererParams: {
          entity: this.entity,
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
      },
      // displayName
      {
        headerName: 'Display Name',
        field: 'displayName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // START
      {
        headerName: 'Start',
        field: 'storeStart',
        sortable: true,
        unSortIcon: true,
        suppressSizeToFit: true,
        width: 300,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('storeStart'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      // END
      {
        headerName: 'End',
        field: 'storeEnd',
        sortable: true,
        unSortIcon: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        width: 300,
        valueGetter: this.agGridToolsService.dateValueGetter('storeEnd'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      // credits
      {
        headerName: 'Credit(s)',
        field: 'credits_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
          defaultType: 'items'
        },
      },
      // image_ref
      {
        headerName: 'Image',
        field: 'image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        width: 500,
        autoHeight: true,
        filterParams: {
          populate: true,
          select: 'id name _id'
        },
      },
      {
        headerName: 'Thumbnail',
        field: 'thumbnail_img',
        sortable: false,
        resizable: true,
        width: 135,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          // Attempt to access the thumbnail in the order of dev, qa, then prod
          if (params.data.image_ref && params.data.image_ref?.thumbnails && params.data.image_ref?.thumbnails[1]) {
            return params.data.image_ref.thumbnails[1].path;
          } else {
            // Default thumbnail if none of the environments have the desired thumbnail
            return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
          }
        },
      },
      // saleLabel
      {
        headerName: 'Sale Label',
        field: 'saleLabel',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // description
      {
        headerName: 'Description',
        field: 'description',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // pricePoint_ref
      {
        headerName: 'Price Point',
        field: 'pricePoint_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'price-points'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'pricePoint_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          populate: true,
          select: 'id name _id'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      // priceLabel
      {
        headerName: 'Price Label',
        field: 'priceLabel',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // pricePointText
      {
        headerName: 'Price Point Text',
        field: 'pricePointText',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // useStoreFrontEndTime
      {
        headerName: 'Use StoreFront End Time',
        field: 'useStoreFrontEndTime',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      // useTileUI
      {
        headerName: 'Use Tile UI',
        field: 'useTileUI',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      // displaySubtitle
      {
        headerName: 'Display Subtitle',
        field: 'displaySubtitle',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // backgroundHex
      {
        headerName: 'Background HEX',
        field: 'backgroundHex',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      // minimumLevel
      {
        headerName: 'Minimum Level',
        field: 'minimumLevel',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn',
      },
      // analyticsBucket
      {
        headerName: 'Analytics Bucket',
        field: 'analyticsBucket',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // originalStoreListingName
      {
        headerName: 'Original StoreListing Name',
        field: 'originalStoreListingName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // fallbackStoreListingName
      {
        headerName: 'Fallback StoreListing Name',
        field: 'fallbackStoreListingName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // secondaryStoreListingName
      {
        headerName: 'Secondary StoreListing Name',
        field: 'secondaryStoreListingName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // customerLimit
      {
        headerName: 'Customer Limit',
        field: 'customerLimit',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn',
      },
      // TAGS
      {
        headerName: 'Tags',
        field: 'tags',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Customer Limit Reset Fixed',
        field: 'CustomerLimitResetFixed',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Customer Limit Reset Hours',
        field: 'CustomerLimitResetHours',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn',
      },
    ];


  }

  async setOptions(){
    const entities = [

      { ref: 'currencies_ref', entity: 'currencies', query: {}, select: 'name id _id' },
      { ref: 'resources_ref', entity: 'resources', select: 'name id _id' },
      { ref: 'titles_ref', entity: 'titles', select: 'name id _id' },
    ];

    // Using the new approach
    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }
}
