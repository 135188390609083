import { AuthGuard } from "../auth/auth.guard";
import { TitlesFormV2Component } from "../cms-v2/entities/titles/components/titles-form-v2/titles-form-v2.component";
import { TitlesViewV2Component } from "../cms-v2/entities/titles/components/titles-view-v2/titles-view-v2.component";
import { TitlesAgGridComponent } from "../cms-v2/entities/titles/components/titles-ag-grid/titles-table-ag-grid.component";

export const titlesRoutes = {
  prefix: 'titles',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: TitlesAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: TitlesFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: TitlesViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: TitlesFormV2Component,
        canActivate: [AuthGuard],
      },

    ]
  }
};