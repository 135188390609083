import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef, GridApi } from 'ag-grid-community';
import { AgGridTableComponent } from 'src/app/common/ag-grid-table/ag-grid-table.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { LinkCellEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/link/link.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { RichTextEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/rich-text/rich-text.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { BuildStatusCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { UtilitiesService, OptionsParams } from 'src/app/common/services/utilities.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-progression-level-table-view-ag-grid',
  templateUrl: './progression-level-table-view-ag-grid.component.html',
  styleUrls: ['./progression-level-table-view-ag-grid.component.sass']
})
export class ProgressionLevelTableViewAgGridComponent implements OnInit {
  @ViewChild('agGridTable', { static: false }) agGridTable: AgGridTableComponent;

  // ...

  entity: string = 'items';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  id: any;
  level: any;

  localizedValues: any;
  displayLocalizedValues: boolean = false;
  parentViewName: string = 'progressionLevelView';

  public tableParams: any = {};
  public bulkUpdateColDefs: any[] = [];

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private route: ActivatedRoute,
    private dataService: DataService,
  ) { }

  async ngOnInit(): Promise<void> {

    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
    })
    await this.setOptions();
    console.log(this.id)
    this.level = await this.dataService.getDocumentAsync('progression-levels', { query: {id: +this.id}, autopopulate: true, virtuals: true })
    console.log(this.level._id)
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Thumbnail',
        field: 'thumbnail_img',
        sortable: false,
        resizable: true,
        width: 135,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          return params.data.thumbnail_ref.thumbnails && params.data.thumbnail_ref.thumbnails[1] ?
            params.data.thumbnail_ref.thumbnails[1].path :
            'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
        },
      },
      {
        headerName: 'Re Release Start',
        field: 'reReleaseStart',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear'],
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('reReleaseStart'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Re Release End',
        field: 'reReleaseEnd',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('reReleaseEnd'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Build Status Date',
        field: 'masterBuildDate',
        sortable: false,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['clear'],
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'greaterThan',
            'inRange'
          ],
          filterKey: 'prefab_ref',
          isMasterBuildDate: true,
          isAggregate: true,

        },
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          entity: this.entity
          // key: 'prefab_ref'
        },
        valueGetter: (params: any) => {
          const source = params.data.prefab_ref && params.data.prefab_ref.buildOutput ? params.data.prefab_ref.buildOutput : null;
          return this.agGridToolsService.getBuildDate(source);
        },
        resizable: true,
      },
      {
        headerName: 'Art Status',
        field: 'vendorStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Assigned', 'In Revision', 'Approved'],

        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Content Status',
        field: 'itemStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Approved', 'Awaiting Revision', 'QA Ready'],

        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Asset Type',
        field: 'assetType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Hard Surface', 'HS Recolor', 'Organics', 'Organic Recolors'],

        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true,
        },
        cellEditorPopupPosition: 'under',
        type: 'editableColumn',
      },
      {
        headerName: 'Batch',
        field: 'batch_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'batches',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.batch_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },

      {
        headerName: 'Blurb',
        field: 'blurb',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 350,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Blurb Status',
        field: 'blurbStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Approved', 'Needs Blurb', 'Ready for Review', 'Revision Needed'],
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Build Status',
        field: 'buildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'prefab_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'prefab_ref',
          isBuildStatus: true,
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Category',
        field: 'category_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'categories',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.category_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true,
        },
      },
      {
        headerName: 'Linked Challenge(s)',
        field: 'challenges_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenges',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: [
            'contains',
            'notContains',
            'equals',
            'notEqual',
            'blank',
            'notBlank'
          ]
        },
        floatingFilter: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'Climate(s)',
        field: 'climates_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'climates',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.climates_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Collection(s)',
        field: 'collection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'collections',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.collection_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value.id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true,
      },
      {
        headerName: 'Dominant Colors',
        field: 'dominantColors',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterOptions: [
            'contains',
            'notContains',
            'blank',
            'notBlank'
          ]
        },
        autoHeight: true,
        valueGetter: (params) => {
          return params.data.dominantColors ? params.data.dominantColors.join(', ') : 'N/A';
        },
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorArray: true,
          isColorDisplay: true,
        }
      },
      {
        headerName: 'Color(s)',
        field: 'colors_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'colors',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.colors_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Content Hold',
        field: 'contentHold_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'content-hold',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.contentHold_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true
      },
      {
        headerName: 'Cost(s)',
        field: 'costs_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                },
                comparator: this.agGridToolsService.nameASC
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
                maxNumConditions: 1
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
          defaultType: 'currencies'
        },
      },
      {
        headerName: 'Cultivar',
        field: 'cultivar',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Dim X (Width)',
        field: 'dimensionX',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.prefab_ref && params.data.prefab_ref.dimensionX) {
            return params.data.prefab_ref.dimensionX.toFixed(4)
          } else {
            return null
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          isAggregate: true,
          filterKey: 'prefab_ref',
          isDimension: true
        },
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Dim Y (Height)',
        field: 'dimensionY',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.prefab_ref && params.data.prefab_ref.dimensionY) {
            return params.data.prefab_ref.dimensionY.toFixed(4)
          } else {
            return null
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          isAggregate: true,
          filterKey: 'prefab_ref',
          isDimension: true
        },
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Dim Z (Depth)',
        field: 'dimensionZ',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.prefab_ref && params.data.prefab_ref.dimensionZ) {
            return params.data.prefab_ref.dimensionZ.toFixed(4)
          } else {
            return null
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          isAggregate: true,
          filterKey: 'prefab_ref',
          isDimension: true
        },
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Radius',
        field: 'radius',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.prefab_ref && params.data.prefab_ref.radius) {
            return params.data.prefab_ref.radius.toFixed(4)
          } else {
            return null
          }
        },
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          isAggregate: true,
          filterKey: 'prefab_ref',
          isDimension: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'External Plant Data',
        field: 'externalPlantData_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'external-plant-data'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.externalPlantData_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'File Name',
        field: 'fileName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Art Hold',
        field: 'flagged',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['In QA', 'Hold', 'Marked for Deletion'],
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Height',
        field: 'height',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Image Build Status',
        field: 'imageBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'thumbnail_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'thumbnail_ref',
          isBuildStatus: true,

        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'F.Size - IOS',
        field: 'ios_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'ios');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,


        autoHeight: true,
      },
      {
        headerName: 'F.Size - AND',
        field: 'and_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'and');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - MAC',
        field: 'mac_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'mac');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - LIN',
        field: 'lin_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'lin');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - WIN',
        field: 'win_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'win');
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Internal Notes',
        field: 'internalNotes',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: RichTextEditorComponent,
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
        cellEditorPopup: true,
      },
      {
        headerName: 'Internal Reference Links',
        field: 'internalReferenceLinks',
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          isRefLink: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agTextColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
        cellEditor: LinkCellEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'File Type',
        field: 'itemFileType_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-file-types',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.itemFileType_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Latin Name',
        field: 'latinName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Material(s)',
        field: 'materials_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'materials',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.materials_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Patterns(s)',
        field: 'patterns_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'patterns',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.patterns_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Plant Family',
        field: 'plantFamily',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Prefab Path',
        field: 'prefab',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Progression Level',
        field: 'progressionLevel_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'progression-levels'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.progressionLevel_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor Reference Links',
        field: 'referenceLinks',
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          isRefLink: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agTextColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
        cellEditor: LinkCellEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Related Item(s)',
        field: 'releatedItems',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'items',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.items_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Shape',
        field: 'shape_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'shapes',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.shape_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Spread',
        field: 'spread',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Spruce Data Status',
        field: 'spruceDataStatus',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Linked',
          offLabel: 'Not Linked',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Linked' : 'Not Linked'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Sourcing Item Ref',
        field: 'sourcingItem_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'sourcing-groups',
          isSourcingItem: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
      },
      {
        headerName: 'Sourcing Group',
        field: 'sourcingGroup_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'sourcing-groups',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.sourcingGroup_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
      },
      {
        headerName: 'Style(s)',
        field: 'styles_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'styles',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.styles_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true
      },
      // not adding subType
      {
        headerName: 'Tag(s)',
        field: 'tags_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'tags',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.tags_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true
      },
      {
        headerName: 'Thumbnail Path',
        field: 'thumbnail',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Trait(s)',
        field: 'traits_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'traits',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.traits_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Type',
        field: 'type_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-types'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.type_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor',
        field: 'vendor_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'vendors',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.vendor_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor Dimensions',
        field: 'vendorDimensions',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Vendor Notes',
        field: 'vendorNotes',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: RichTextEditorComponent,
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Year',
        field: 'year',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['2021', '2022', '2023', '2024', '2025'],
          addEmptyFilters: true
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Item Promoted Status',
        field: 'isAssetUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.prefab_ref?.lastHash;
          const versions = params.data.prefab_ref?.asset_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;

          let response = 'No data';

          if (!lastHash && versions && versions.length > 0) {
            return 'Outdated';
          }

          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }
          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'No data', 'Outdated', 'Up to date'
          ],
          isAggregate: true,
          filterKey: 'prefab_ref',
        },
        floatingFilter: true,

      },
      {
        headerName: 'Thumbnail Promoted Status',
        field: 'isImageUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.thumbnail_ref?.lastHash;
          const versions = params.data.thumbnail_ref?.image_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;

          let response = 'No data';
          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }

          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'No data', 'Outdated', 'Up to date'
          ],
          isAggregate: true,
          filterKey: 'thumbnail_ref',
        },
        floatingFilter: true,

      },
      {
        headerName: 'Vendor Height',
        field: 'vendorHeight',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Vendor Width',
        field: 'vendorWidth',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Vendor Length',
        field: 'vendorLength',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Bundle Asset (IOS)',
        field: 'bundleAsset',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Asset (AND)',
        field: 'bundleAssetAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle',
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Thumbnail Record',
        field: 'thumbnail_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'thumbnail_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          populate: true,
          select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
        },
        width: 500,
        autoHeight: true,
      },
      {
        headerName: 'Prefab Record',
        field: 'prefab_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'prefab_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          populate: true,
          select: '_id id name path files buildOutput lastHash asset_versions dimensionX dimensionY dimensionZ radius thumbnails entityType assetType'
        },
        width: 500,
        autoHeight: true,

      },
      {
        headerName: 'Nurture',
        field: 'nurture_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'nurture'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.nurture_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Keyword(s)',
        field: 'keywords_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-keywords',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.keywords_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Set(s)',
        field: 'itemSet_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-sets',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.itemSet_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true,
      },
      {
        headerName: 'Spawn Audios',
        field: 'spawnAudios_ref',
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: true,
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audios',
        field: 'loopAudios_ref',
        cellRendererParams: {
          isArray: true
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Spawn Audio Collections',
        field: 'spawnAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audio Collections',
        field: 'loopAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
      {
        name: 'Re Release Start', id: 'reReleaseStart', type: 'date'
      },
      {
        name: 'Re Release End', id: 'reReleaseEnd', type: 'date'
      },
      {
        name: 'Vendor Status', id: 'vendorStatus', type: 'dropdown', isFlatList: true
      },
      {
        name: 'Asset Type', id: 'assetType', type: 'dropdown', isFlatList: true
      },
      {
        name: 'Batch', id: 'batch_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Blurb', id: 'blurb', type: 'long-text'
      },
      {
        name: 'Blurb Status', id: 'blurbStatus', type: 'dropdown', isFlatList: true
      },
      {
        name: 'Category', id: 'category_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Climate(s)', id: 'climates_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Collection(s)', id: 'collection_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Color(s)', id: 'colors_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Content Hold', id: 'contentHold_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Costs', id: 'costs_ref', type: 'line-items', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Cultivar', id: 'cultivar', type: 'text'
      },
      {
        name: 'Internal Notes', id: 'internalNotes', type: 'rich-text'
      },
      {
        name: 'Year', id: 'year', type: 'dropdown', isFlatList: true
      },
      {
        name: 'Bundle Image (IOS)', id: 'bundleImage', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Bundle Asset (IOS)', id: 'bundleAsset', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Bundle Image (AND)', id: 'bundleImageAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Bundle Asset (And)', id: 'bundleAssetAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Loop Audios', id: 'loopAudios_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Spawn Audios', id: 'spawnAudios_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Loop Audio Collections', id: 'loopAudioCollections_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Spawn Audio Collections', id: 'spawnAudioCollections_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
    ];

    this.tableParams = {
      customName: this.level.name + " Items",
      hideAdd: true,
      defaultQuery: {  'progressionLevel_ref': this.level._id  },
    }
  }



  async setOptions(){
    const entities = [
      { ref: 'batch_ref', entity: 'batches' },
      { ref: 'category_ref', entity: 'categories' },
      { ref: 'climates_ref', entity: 'climates' },
      { ref: 'collection_ref', entity: 'collections' },
      { ref: 'colors_ref', entity: 'colors' },
      { ref: 'contentHold_ref', entity: 'content-hold' },
      { ref: 'externalPlantData_ref', entity: 'external-plant-data' },
      { ref: 'itemFileType_ref', entity: 'item-file-types' },
      { ref: 'materials_ref', entity: 'materials' },
      { ref: 'patterns_ref', entity: 'patterns' },
      { ref: 'progressionLevel_ref', entity: 'progression-levels' },
      { ref: 'shape_ref', entity: 'shapes' },
      { ref: 'sourcingGroup_ref', entity: 'sourcing-groups' },
      { ref: 'styles_ref', entity: 'styles' },
      { ref: 'tags_ref', entity: 'tags' },
      { ref: 'traits_ref', entity: 'traits' },
      { ref: 'type_ref', entity: 'item-types' },
      { ref: 'vendor_ref', entity: 'vendors' },
      { ref: 'nurture_ref', entity: 'nurture' },
      { ref: 'keywords_ref', entity: 'item-keywords' },
      { ref: 'items_ref', entity: 'items' },
      { ref: 'currencies_ref', entity: 'currencies' },
      { ref: 'resources_ref', entity: 'resources' },
      { ref: 'titles_ref', entity: 'titles' },
      { ref: 'spawnAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [22] } } },
      { ref: 'loopAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [23] } } },
      { ref: 'spawnAudioCollections_ref', entity: 'audio-collections', query: { type: 1 } },
      { ref: 'loopAudioCollections_ref', entity: 'audio-collections', query: { type: 2 } }
    ];

    // Using the new approach
    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }

  fileSizeGetter(params: any, platform: string, getNumber: boolean = false){
    if(params.data.prefab_ref && params.data.prefab_ref.path && params.data.prefab_ref.files ) {
      let fileSizeName = '';
      fileSizeName = `${params.data.prefab_ref.path.slice(
        params.data.prefab_ref.path.lastIndexOf('/') + 1,
      )}_${platform}`;
      return params.data.prefab_ref.files[`${fileSizeName}`] || null
    } else {
      return null
    }
  }

  editLevel() {
    const url = `/progression-levels/edit/${this.level.id}`;
    window.open(url, '_blank');
  }


  async fetchLocalized() {
    const data = await this.dataService.fetchGridlyRecord(this.id, 'progression-levels');
    this.localizedValues = this.formatData(data);
    this.displayLocalizedValues = true;
  }

  formatData(data: any[]): any[] {
    const excludedColumns = ['update_trigger', 'glossaryRes', 'start', 'translationRequest', 'verified', 'isLive'];

    return data.map(record => {
      const readableId = record.id.split('_')[1] || record.id;
      const formattedRecord: any = { id: readableId.charAt(0).toUpperCase() + readableId.slice(1) };
      record.cells.forEach((cell: any) => {
        if (!excludedColumns.includes(cell.columnId)) {
          formattedRecord[cell.columnId] = cell.value;
        }
      });
      return formattedRecord;
    });
  }

  getColumns(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).filter(key => key !== 'id');
  }

}
