import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonEntityService } from '../../services/common-entity.service';
import { ENTITY_FIELD_CONFIGURATIONS } from './constants';
import { MessageService, MenuItem } from 'primeng/api';
import { AgGridToolsService } from '../../ag-grid-table/services/ag-grid-tools.service';
import { OptionsParams, UtilitiesService } from '../../services/utilities.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
interface CustomValue {
  [key: string]: any;
}

interface DuplicationPayload {
  parentId: string;
  fieldsToDuplicate: string[];
  customValues: CustomValue[];
}

// Add new interface for bulk records
interface BulkRecords {
  [key: string]: any;
}

// Update the interface for duplicated records
interface DuplicatedRecord {
  sourceId: string;
  newId: string;
  success: boolean;
  subCreations?: any;
  error?: string;
}

@Component({
  selector: 'app-dup-dialog',
  templateUrl: './dup-dialog.component.html',
  styleUrls: ['./dup-dialog.component.css']
})
export class DupDialogComponent implements OnInit {

  @Input() entity: string;
  @Input() viewRoute: string;
  @Input() record: any;
  @Output() duplicationSuccess = new EventEmitter<any>();

  private savedSelections: { [key: string]: boolean } = {};

  constructor(
    private commonEntityService: CommonEntityService,
    private messageService: MessageService,
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService
  ) {}

  public _display: boolean = false;
  public payload: any = {};
  public tableData: any[] = [];
  public allSelected: boolean = false;
  public isStoreEntity: boolean = false;
  private storeEnv: 'dev' | 'test' | 'qa' | 'prod' = 'dev'
  public isBulk: boolean = false;
  public options: any[] = [];

  // Add new properties for bulk handling
  public records: BulkRecords = {};
  public currentRecordIndex: number = 0;
  public totalRecords: number = 0;
  
  // Add property for accordion states
  public expandedPanels: { [key: string]: boolean } = {};
  public allPanelsExpanded: boolean = true;

  // Add properties for results
  public duplicatedRecords: DuplicatedRecord[] = [];
  public duplicationProgress: number = 0;
  public isDuplicating: boolean = false;

  public apiOptions: any = {};

  @Input()
  set display(value: boolean) {
    if (value) {
      this.restoreSelections();
    }
    this._display = value;
  }
  get display(): boolean {
    return this._display;
  }

  async ngOnInit(): Promise<void> {
    if (['price-points', 'store-listings-v2'].includes(this.entity)) {
      const selectedEnvironment = this.agGridToolsService.getSelectedEnvironment();
      this.storeEnv = selectedEnvironment.value;
      this.isStoreEntity = true;
    }
  }

  async show(payload: any): Promise<void> {
    this.payload = payload;
    this.duplicatedRecords = [];
    
    if(payload.isBulk) {
      this.isBulk = true;
      payload._id.forEach((id: string) => {
        this.expandedPanels[id] = true;
      });
    } else {
      this.isBulk = false;
    }

    await this.getRecordData();
    await this.buildDataForDisplay();

    this.display = true;
  }

  hide(): void {
    this.payload = {};
    this.display = false;
  }

  async getRecordData() {
    if (this.isBulk) {
      // Fetch all records for bulk duplication
      const promises = this.payload._id.map((_id: string) => 
        this.commonEntityService.findOneWithQuery(this.entity, { query: { _id: _id } })
      );
      const results = await Promise.all(promises);
      
      // Store results in records object with _id as key
      this.records = results.reduce((acc, record) => {
        acc[record._id] = record;
        return acc;
      }, {});
      
      // Set initial record and total
      this.record = results[0];
      this.totalRecords = results.length;
      this.currentRecordIndex = 0;
    } else {
      // Existing single record logic
      this.record = await this.commonEntityService.findOneWithQuery(this.entity, { query: { _id: this.payload._id } });
    }
  }

  async buildDataForDisplay() {
    const allowedFieldsForDup = ENTITY_FIELD_CONFIGURATIONS[this.entity] || [];

    if (this.isBulk) {
      // Build table data for each record
      Object.keys(this.records).forEach(async recordId => {
        const currentRecord = this.records[recordId];
        this.records[recordId].tableData = await this.buildTableDataForRecord(currentRecord, allowedFieldsForDup);
      });
    } else {
      this.tableData = await this.buildTableDataForRecord(this.record, allowedFieldsForDup);
    }
  }

  // Helper method to build table data for a single record
  private buildTableDataForRecord(record: any, allowedFields: any[]) {
    return Promise.all(allowedFields.map(async field => {
      let fieldValue = record[field.key];

      if (this.isStoreEntity && field.isStoreValue) {
        fieldValue = fieldValue ? fieldValue[this.storeEnv] : undefined;
      }

      let displayValue;
      let showDateInputs = false;
      let showNameInput = false;
      let showTTLInput = false;
      let showEnvInput = false;
      let showProductId = false;
      let showLinkRef = false;
      switch (field.type) {
        case 'text':
          displayValue = Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
          break;
        case 'multiselect':
          displayValue = fieldValue ? fieldValue.join(', ') : '';
          break;
        case 'ref':
          displayValue = fieldValue ? `${fieldValue.name} (${fieldValue.id || fieldValue._id})` : '';
          break;
        case 'date':
          displayValue = fieldValue ? fieldValue.toISOString().split('T')[0] : '';
          break;
        case 'dateInput':
          if(field.newValue) {
            displayValue = new Date(new Date().setHours(0, 0, 0, 0));
          } else {
            displayValue = fieldValue ? new Date(fieldValue) : '';
          }
          break;
        case 'multiselectDups':
          displayValue = fieldValue?.map((item: any) => ({
            id: item.id,
            _id: item._id,
            name: item.name,
            start: item.start,
            end: item.end,
            selected: true,
            ttl: null,
            env: item.env,
            productId: item.productId || null,
            link_ref: item.link_ref || null
          }));
          let params = new OptionsParams();
          params.entity = field.controller;
          await this.utilitiesService.getOptions(this.options, field.key, params);
          showDateInputs = field.showDateInputs || false;
          showNameInput = field.showNameInput || false;
          showTTLInput = field.showTTLInput || false;
          showEnvInput = field.showEnvInput || false;
          showProductId = field.showProductId || false;
          showLinkRef = field.showLinkRef || false;

          break;
        case 'multiselectEnv':
          if(field.defaultValue) {
            displayValue = field.defaultValue;
          } else {
            displayValue = fieldValue || [];
          }
          break;
        case 'singleDup':
          displayValue = fieldValue ? {
            id: fieldValue.id,
            _id: fieldValue._id,
            name: fieldValue.name,
            start: fieldValue.start,
            end: fieldValue.end,
            selected: true,
            ttl: null,
            env: fieldValue.env,
            productId: fieldValue.productId || null,
            link_ref: fieldValue.link_ref || null
          } : '';
          showDateInputs = field.showDateInputs || false;
          showNameInput = field.showNameInput || false;
          showTTLInput = field.showTTLInput || false;
          showEnvInput = field.showEnvInput || false;
          showProductId = field.showProductId || false;
          showLinkRef = field.showLinkRef || false;

          break;
        case 'boolean':
          displayValue = fieldValue ? 'Yes' : 'No';
          break;
        case 'simpleDropdown':
          displayValue = fieldValue || null;
          break;
        case 'booleanInput':
          displayValue = field.defaultValue !== undefined ? field.defaultValue : fieldValue;
          break;
        default:
          displayValue = fieldValue;
      }

      if(showLinkRef) {
        const options = new OptionsParams();
        options.entity = 'link-destination';
        options.select = '_id id linkText linkDestination';
        this.apiOptions[field.key] = await this.utilitiesService.getOptions(
          this.apiOptions, field.key, options);
      }

      return {
        key: field.key,
        controller: field.controller || null,
        label: field.label,
        type: field.type,
        value: displayValue,
        duplicate: field.required ? true : false,
        required: field.required ? true : false,
        isInput: field.isInput ? true : false,
        options: field.options || null,
        onLabel: field.onLabel || null,
        offLabel: field.offLabel || null,
        duplicateMessage: field.duplicateMessage || null,
        ...(showDateInputs && { showDateInputs }),
        ...(showNameInput && { showNameInput }),
        ...(showTTLInput && { showTTLInput }),
        ...(showEnvInput && { showEnvInput }),
        ...(showProductId && { showProductId }),
        ...(showLinkRef && { showLinkRef })
      };
    }));
  }

  // Update the selectionTableData getter
  public get selectionTableData(): any[] {
    if (this.isBulk && 
        Object.keys(this.records).length > 0 && 
        this.payload?._id?.length > 0) {  // Add validation for payload._id
      // For bulk, use the first record's table data as template
      const firstRecordId = this.payload._id[0];
      return this.records[firstRecordId]?.tableData || [];
    }
    return this.tableData;
  }

  // Update the toggleAllSelection method
  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    if (this.isBulk) {
      Object.keys(this.records).forEach(recordId => {
        this.records[recordId].tableData?.forEach((row: any) => {
          row.duplicate = this.allSelected || row.required;
          this.savedSelections[row.label] = row.duplicate;
        });
      });
    } else {
      this.tableData?.forEach(row => {
        row.duplicate = this.allSelected || row.required;
        this.savedSelections[row.label] = row.duplicate;
      });
    }
  }

  // Update onItemSelected method
  onItemSelected(rowData: any) {
    this.savedSelections[rowData.label] = rowData.duplicate;
    
    if (this.isBulk) {
      Object.keys(this.records).forEach(recordId => {
        const matchingRow = this.records[recordId].tableData?.find((row: any) => row.label === rowData.label);
        if (matchingRow) {
          matchingRow.duplicate = rowData.duplicate;
        }
      });
    }
    
    this.updateAllSelected();
  }

  // Update updateAllSelected method
  updateAllSelected() {
    if (this.isBulk) {
      const firstRecordId = this.payload._id[0];
      this.allSelected = this.records[firstRecordId]?.tableData?.every((item: any) => item.duplicate);
    } else {
      this.allSelected = this.tableData?.every(item => item.duplicate);
    }
  }

  clearSelections() {
    this.allSelected = false;
    this.savedSelections = {};
    this.tableData?.forEach(row => {
      row.duplicate = row.required || false;
    });
  }

  async onDuplicate() {
    this.spinnerService.loadSpecificSpinner('dup-dialog-spinner');
    this.isDuplicating = true;
    this.duplicationProgress = 0;

    if (!this.isBulk) {
      try {
        const selectedFields = this.tableData
          .filter(item => item.duplicate)
          .map(item => ({
            key: item.key,
            value: item.value,
            type: item.type,
            isInput: item.isInput
          }));

        if (selectedFields.length === 0) {
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Duplication Error',
            detail: 'No fields selected for duplication. Please select fields to duplicate and try again.'
          });
          return;
        }

        const payload: DuplicationPayload = {
          parentId: this.record._id,
          fieldsToDuplicate: selectedFields.map(field => field.key),
          customValues: []
        };

        if (this.isStoreEntity || ['challenges', 'series'].includes(this.entity)) {
          payload.customValues = selectedFields.filter(field => field.isInput).map(field => ({ [field.key]: field.value }));
          payload.customValues = this.cleanUnselectedCustomValues(payload.customValues);
        }

        const result = await this.commonEntityService.duplicate(this.entity, payload);
        this.duplicatedRecords.push({ 
          sourceId: this.record.id,
          newId: result.id,
          success: true,
          subCreations: result.subCreations
        });
        this.duplicationProgress = 100;
        this.duplicationSuccess.emit(result);

        setTimeout(() => {
          window.open(`/${this.viewRoute}/${result.id}`, '_blank');
        }, 500);
      } catch (error: any) {
        console.log(error);

        this.messageService.add({
          severity: 'error',
          summary: 'Duplication Error',
          detail: error?.error?.message || 'Failed to duplicate record'
        });

        this.duplicatedRecords.push({ 
          sourceId: this.record.id,
          newId: '',
          success: false, 
          error: JSON.stringify(error)
        });
        this.duplicationProgress = 100;
      }
    } else {
      const totalRecords = this.payload._id.length;
      let processedCount = 0;

      for (const currentId of this.payload._id) {
        try {
          const currentRecord = this.records[currentId];
          const selectedFields = currentRecord.tableData
            .filter((item: any) => item.duplicate)
            .map((item: any) => item.key);

          const payload: DuplicationPayload = {
            parentId: currentId,
            fieldsToDuplicate: selectedFields,
            customValues: []
          };

          if (this.isStoreEntity || ['challenges', 'series'].includes(this.entity)) {
            payload.customValues = currentRecord.tableData
              .filter((item: any) => item.isInput && item.duplicate)
              .map((field: any) => ({ [field.key]: field.value }));
          }

          const result = await this.commonEntityService.duplicate(this.entity, payload);
          this.duplicatedRecords.push({ 
            sourceId: currentRecord.id,
            newId: result.id,
            success: true 
          });
          this.duplicationSuccess.emit(result);
        } catch (error: any) {
          this.duplicatedRecords.push({ 
            sourceId: this.records[currentId].id,
            newId: '',
            success: false, 
            error: error?.error?.message || 'Unknown error'
          });
        }
        
        processedCount++;
        this.duplicationProgress = Math.ceil((processedCount / totalRecords) * 100);
      }
    }

    this.isDuplicating = false;
    this.clearSelections();
    this.savedSelections = {};
    this.spinnerService.endSpecificSpinner('dup-dialog-spinner');
    await this.buildDataForDisplay();
  }

  onCancel() {
    this.clearSelections();
    this.display = false;
  }

  // Add methods to navigate between records in bulk mode
  nextRecord() {
    if (this.currentRecordIndex < this.totalRecords - 1) {
      this.currentRecordIndex++;
      this.buildDataForDisplay();
    }
  }

  previousRecord() {
    if (this.currentRecordIndex > 0) {
      this.currentRecordIndex--;
      this.buildDataForDisplay();
    }
  }

  private restoreSelections() {
    if (this.allSelected) {
      // If all was selected, select all rows
      this.tableData?.forEach(row => row.duplicate = true);
    } else {
      // Restore individual selections
      this.tableData?.forEach(row => {
        row.duplicate = this.savedSelections[row.label] || row.required || false;
      });
    }
  }

  private saveSelections() {
    // Save current selections
    this.savedSelections = {};
    this.tableData?.forEach(row => {
      this.savedSelections[row.label] = row.duplicate;
    });
  }

  // When dialog visibility changes
  ngOnChanges(changes: SimpleChanges) {
    if (changes['display'] && !changes['display'].firstChange) {
      if (this.display) {
        this.restoreSelections();
      } else {
        this.saveSelections();
      }
    }
  }

  // Add method to toggle accordion panels
  togglePanel(recordId: string) {
    this.expandedPanels[recordId] = !this.expandedPanels[recordId];
  }

  // Add method to toggle all panels
  toggleAllPanels() {
    this.allPanelsExpanded = !this.allPanelsExpanded;
    Object.keys(this.expandedPanels).forEach(recordId => {
      this.expandedPanels[recordId] = this.allPanelsExpanded;
    });
  }

  // Add method to handle value changes from row component
  onRowValueChange(rowData: any, newValue: any) {
    rowData.value = newValue;
  }

  private cleanUnselectedCustomValues(customValues: any[]): any[] {
    return customValues.map(customValue => {
      const [key] = Object.keys(customValue);
      const value = customValue[key];
      
      // If the value is an array and its items have a 'selected' property
      if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && 'selected' in value[0]) {
        return {
          [key]: value.filter(item => item.selected)
        };
      }
      
      return customValue;
    }).filter(customValue => {
      const [key] = Object.keys(customValue);
      const value = customValue[key];
      return !Array.isArray(value) || value.length > 0;
    });
  }

}
