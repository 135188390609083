import { AuthGuard } from "../auth/auth.guard";
import { CategoryCollectionViewV2Component } from "../cms-v2/entities/category-collection/category-collection-view-v2/category-collection-view-v2.component";
import { CategoryCollectionFormV3Component } from "../cms-v2/entities/category-collection/category-collection-form-v3/category-collection-form-v3.component";
import { CategoryCollectionTableAgGridComponent } from "../cms-v2/entities/category-collection/category-collection-table-ag-grid/category-collection-table-ag-grid.component";

export const categoryCollectionsRoutes = {
  prefix: 'category-collections',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: CategoryCollectionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: CategoryCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: CategoryCollectionViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: CategoryCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};