import { AuthGuard } from "../auth/auth.guard";
import { ItemSetTableAgGridComponent } from "../cms-v2/entities/item-set/item-set-table-ag-grid/item-set-table-ag-grid.component";
import { ItemSetFormV2Component } from "../cms-v2/entities/item-set/item-set-form-v2/item-set-form-v2.component";
import { ItemSetViewV2Component } from "../cms-v2/entities/item-set/item-set-view-v2/item-set-view-v2.component";

export const itemSetsRoutes = {
  prefix: 'item-sets',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ItemSetTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ItemSetFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ItemSetViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ItemSetFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};