import { AuthGuard } from "../auth/auth.guard";
import { ResourceTableAgGridComponent } from "../cms-v2/entities/resource/components/resource-table-ag-grid/resource-table-ag-grid.component";
import { ResourceFormV2Component } from "../cms-v2/entities/resource/components/resource-form/resource-form.component";
import { ResourceViewV2Component } from "../cms-v2/entities/resource/components/resource-view-v2/resource-view-v2.component";

export const resourcesRoutes = {
  prefix: 'resources',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ResourceTableAgGridComponent,   
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ResourceFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ResourceViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ResourceFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};