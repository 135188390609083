import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

type Bundle = {
  type: 'free' | 'paid' | null;
  index: number
  // Add more properties if necessary
};

export default class EndlessOfferTrackDTO extends BaseEntityDto {
  name?: string;
  rewards?: any[];
  bundles?: Bundle[];
  type?: number;
  EOrewards?: any[];
  EObundles?: any[];

  [key: string]: any; // Index signature
}
