import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
  HttpContextToken,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialUser } from '@abacritt/angularx-social-login';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

export const SKIP_INTERCEPTOR = new HttpContextToken<boolean>(() => false);


@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  currentUser: SocialUser;

  constructor (
    private authService: AuthService,
    private userContextService: UserContextService,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add JWT to auth header.
    if (request.context.get(SKIP_INTERCEPTOR)) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      },
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const logginIn = this.authService.getLoggedIn();
        if (error.status === 401 && !!logginIn) {
          this.authService.logout();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        return throwError(() => error);
      }),
    );
  }
}

export const authTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenInterceptor,
  multi: true,
};
