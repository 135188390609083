import { AuthGuard } from "../auth/auth.guard";
import { ProgressionLevelFormV3Component } from "../cms-v2/entities/progression-level/components/progression-level-form-v3/progression-level-form-v3.component";
import { ProgressionLevelTableViewAgGridComponent } from "../cms-v2/entities/progression-level/components/progression-level-table-v2/progression-level-view-v2/progression-level-table-view-ag-grid.component";
import { ProgressionLevelTableAgGridComponent } from "../entities/progression-level/progression-level-table-ag-grid/progression-level-table-ag-grid.component";
import { ReleaseProgressionLevelsV2Component } from "../entities/release/release-progression-levels-v2/release-progression-levels-v2.component";

export const progressionLevelsRoutes = {
  prefix: 'progression-levels',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ProgressionLevelTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ProgressionLevelFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ProgressionLevelTableViewAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `releases/${this.prefix}/:id`,
        component: ReleaseProgressionLevelsV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ProgressionLevelFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};