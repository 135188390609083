import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import RestrictionDTO from '../dtos/RestrictionDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-restriction-form-v3',
  templateUrl: './restriction-form-v3.component.html',
  styleUrls: ['./restriction-form-v3.component.sass']
})
export class RestrictionFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  restrictionRecord: RestrictionDTO = new RestrictionDTO();
  constructor(injector: Injector) 
  {
    super(injector);
  }

  /**
   * Restriction Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Restriction Data",
        fields: 
        [
          { 
            name: "Name", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],   
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name', 
            disabled: false 
          },
          { 
            name: "Count", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],   
            clearField: true, 
            key: 'count', 
            isRequired: true, 
            label: 'Count', 
            disabled: false 
          },
          { 
            name: "Rules",
            inputType: DynamicInputType.RestrictionComponent,
            key: 'rules_ref',
          }
        ],
        type: DynamicCardType.Card
      },
    ]

    this.title = "Restriction";
    this.viewRoute = 'restrictions';
    this.isLoading = false;
  }
}
