import { AuthGuard } from "../auth/auth.guard";
import { StoreTransferV2ViewComponent } from "../cms-v2/store/store-transfer-v2-view/store-transfer-v2-view.component";
import { StoreTransferTableAgGridComponent } from "../cms-v2/store/store-transfer/store-transfer-table-ag-grid/store-transfer-table-ag-grid.component";

export const storeTransferRoutes = {
  prefix: 'store-transfer',        
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: StoreTransferTableAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: StoreTransferV2ViewComponent,                                              
        canActivate: [AuthGuard],
      },
    ]
  }
};