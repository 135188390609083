import { AuthGuard } from "../auth/auth.guard";
import { InfiniteSeriesTableAgGridComponent } from "../cms-v2/entities/series/infinite-series/components/infinite-series-table-ag-grid/infinite-series-table-ag-grid.component";
import { InfiniteSeriesFormComponent } from "../cms-v2/entities/series/infinite-series/components/infinite-series-form/infinite-series-form.component";
import { InfiniteSeriesViewComponent } from "../cms-v2/entities/series/infinite-series/components/infinite-series-view/infinite-series-view.component";

export const infiniteSeriesRoutes = {
  prefix: 'infinite-series',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: InfiniteSeriesTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: InfiniteSeriesFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: InfiniteSeriesViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: InfiniteSeriesFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};