import { AuthGuard } from "../auth/auth.guard";
import { ClimateTableAgGridComponent } from "../cms-v2/entities/climate/components/climate-table-v2/climate-table-ag-grid/climate-table-ag-grid.component";
import { ClimateViewComponent } from "../entities/climate/climate-view/climate-view.component";
import { ClimateFormV2Component } from "../cms-v2/entities/climate/components/climate-form-v2/climate-form-v2.component";

export const climatesRoutes = {
  prefix: 'climates',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ClimateTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ClimateFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ClimateViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ClimateFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};