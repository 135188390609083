import { AuthGuard } from "../auth/auth.guard";
import { GamedataDiffComponent } from "../build-gamedata/prod-gamedata-push/gamedata-diff/gamedata-diff.component";
import { SeasonPassViewComponent } from "../entities/season-pass/season-pass-view.component";

export const gamedataRoutes = {
  prefix: 'gamedata/diff',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: GamedataDiffComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SeasonPassViewComponent,                  
        canActivate: [AuthGuard],
      },
    ]
  }
};