import { AuthGuard } from "../auth/auth.guard";
import { VendorViewV2Component } from "../cms-v2/entities/vendor/components/vendor-view-v2/vendor-view-v2.component";
import { VendorFormV3Component } from "../cms-v2/entities/vendor/components/vendor-form-v3/vendor-form-v3.component";
import { VendorTableAgGridComponent } from "../cms-v2/entities/vendor/components/vendor-table-ag-grid/vendor-table-ag-grid.component";

export const vendorsRoutes = {
  prefix: 'vendors',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: VendorTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: VendorFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: VendorViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: VendorFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};