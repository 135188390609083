import { AuthGuard } from "../auth/auth.guard";
import { LocalizationConfigFormComponent } from "../cms-v2/entities/localization/form/localization-config-form-component/localization-config-form-component";
import { LocalizationConfigurationViewComponent } from "../cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-view.component";
import { LocalizationConfigurationTableComponent } from "../cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-table/localization-configuration-table/localization-configuration-table.component";

export const localizationConfigurationRoutes = {
  prefix: 'localization-configuration',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: LocalizationConfigurationTableComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: LocalizationConfigFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: LocalizationConfigurationViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: LocalizationConfigFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};