import { AuthGuard } from "../auth/auth.guard";
import { KeywordsViewV2Component } from "../cms-v2/entities/keywords/keywords-view-v2/keywords-view-v2.component";
import { ItemKeywordsComponent } from "../entities/item/item-keywords/item-keywords.component";

export const itemKeywordsRoutes = {
  prefix: 'item-keywords',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ItemKeywordsComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};