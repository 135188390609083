import { AuthGuard } from "../auth/auth.guard";
import { DiffToolAgAgridComponent } from "../entities/diff-tool-ag-agrid/diff-tool-ag-agrid.component";
import { DiffToolViewComponent } from "../entities/diff-tool-view/diff-tool-view.component";

export const diffJobsRoutes = {
  prefix: 'diff-jobs',        
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: DiffToolAgAgridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: DiffToolViewComponent,                              
        canActivate: [AuthGuard],
      },
    ]
  }
};