import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import RestrictionDTO from '../dtos/RestrictionDTO';

@Component({
  selector: 'restriction-form-field',
  templateUrl: './restriction-form-field.component.html',
  styleUrls: ['./restriction-form-field.component.sass']
})
export class RestrictionFormFieldComponent implements OnInit
{
  @Input() restrictionRecord: RestrictionDTO = new RestrictionDTO();
  @Input() isEdit: Boolean = false;
  @Input() utilitiesService: any;
  @Output() onRecordChange = new EventEmitter<any>();
  options: any = [];
  suggestions: any = [];
  constructor(
    private dataService: DataService
  ) { 
  }

  /**
   *  Restriction Form Field Component Initialization
   */
  async ngOnInit()
  {
    await this.setOptions();
    if(this.isEdit) {
      this.restrictionRecord.rules_ref.forEach((element: any) => {
        var index = this.options['filterModel'].findIndex((option: any) => option.model === element.filterModel)
        element.filterModel = this.options['filterModel'][index]
      })
    }
  }

  /**
   * Handle Record Changes
   *
   * @param field Field that changed.
   * @param event Value changed.
   */
  onChange(event: any)
  {
    this.onRecordChange.emit({record: this.restrictionRecord, change: event });
  }

  /**
   * Adds a rule to the array of rules ref
   */
  addRule() {
    this.restrictionRecord.rules_ref.push(
      { filterModel: '', filterTableName: '', filter_ref: {} }
    );
  }

  /**
   * Deletes the rule based on an index
   * @param index index of the rule to delete
   */
  deleteRule(index: number) {
    if (index > -1) { // only splice array when item is found
      this.restrictionRecord.rules_ref.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.onRecordChange.emit({record: this.restrictionRecord});
  }
  
  /**
   * Set Options for the dropdowns
   */
  async setOptions() {
    // from refs (managed lists)

    // un-managed lists
    // 'filter': these MUST be in sync with the restriction entity (RuleProp)
    this.options['filterModel'] = [
      { name: 'Category', model: 'Category', tableName: 'categories' },
      { name: 'Item Type', model: 'ItemType', tableName: 'item-types' },
      { name: 'Color', model: 'Color', tableName: 'colors' },
      { name: 'Trait', model: 'Trait', tableName: 'traits' },
      { name: 'Shape', model: 'Shape', tableName: 'shapes' },
      { name: 'Material', model: 'Material', tableName: 'materials' },
      { name: 'Pattern', model: 'Pattern', tableName: 'patterns' },
      { name: 'Style', model: 'Style', tableName: 'styles' },
    ];

  }
  /**
   * Update a dependent field with the value of another field.
   * TODO: handle non arrays.
   *
   * @param e contains the value of the field the event was triggered on.
   * @param refField field object that triggered the event.
   * @param dependentField the field you want to update the value of
   * @param index index in the form array.
   */
  updateDependentField(
    event: any,
    index: any
  ) 
  {
    let value = event.value.tableName == 'item-types' ? 'item_types' : event.value.tableName;
    this.restrictionRecord.rules_ref[index].filterTableName = value;
    this.onChange(event)
  }
}
