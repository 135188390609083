import { AuthGuard } from "../auth/auth.guard";

import { SeedPackBoxTypeFormComponent } from "../cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-type-form/seed-pack-box-type-form.component";
import { SeedPackBoxTypeTableAgGridComponent } from "../cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-type-table-ag-grid/seed-pack-box-type-table-ag-grid.component";
import { SeedPackBoxTypeViewComponent } from "../cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-view/seed-pack-box-type-view.component";

export const seedPackBoxTypesRoutes = {
  prefix: 'seed-pack-box-types',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SeedPackBoxTypeTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SeedPackBoxTypeFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SeedPackBoxTypeViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SeedPackBoxTypeFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};