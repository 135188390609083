import { AuthGuard } from "../auth/auth.guard";
import { TraitFormV3Component } from "../cms-v2/entities/traits/components/trait-form-v3/trait-form-v3.component";
import { TraitViewV2Component } from "../entities/trait/trait-view-v2/trait-view-v2.component";
import { TraitsTableAgGridComponent } from "../cms-v2/entities/traits/components/traits-table-ag-grid/traits-table-ag-grid.component";

export const traitsRoutes = {
  prefix: 'traits',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: TraitsTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: TraitFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: TraitViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: TraitFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
    }
};