import { AuthGuard } from "../auth/auth.guard";
import { StyleFormV3Component } from "../cms-v2/entities/style/components/style-form-v3/style-form-v3.component";
import { StyleViewV2Component } from "../entities/style/style-view-v2/style-view-v2.component";
import { StyleTableAgGridComponent } from "../cms-v2/entities/style/components/style-table-ag-grid/style-table-ag-grid.component";

export const stylesRoutes = {
  prefix: 'styles',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: StyleTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: StyleFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: StyleViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: StyleFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};