import { AuthGuard } from "../auth/auth.guard";
import { PricePointTableAgGridComponent } from "../cms-v2/store/price-point/price-point-table-ag-grid/price-point-table-ag-grid.component";
import { PricePointFormComponent } from "../cms-v2/store/price-point/price-point-form/price-point-form.component";
import { PricePointViewComponent } from "../cms-v2/store/price-point/price-point-view/price-point-view.component";

export const pricePointsRoutes = {
  prefix: 'price-points',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: PricePointTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: PricePointFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: PricePointViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: PricePointFormComponent  ,
        canActivate: [AuthGuard],
      },
    ]
  }
};