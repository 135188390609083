import { AuthGuard } from "../auth/auth.guard";
import { SourcingChallengeGroupFormComponent } from "../cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-form/sourcing-challenge-group-form.component";
import { SourcingChallengeGroupViewComponent } from "../cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-view/sourcing-challenge-group-view.component";
import { SourcingChallengeGroupTableAgGridComponent } from "../cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-table-ag-grid/sourcing-challenge-group-table-ag-grid.component";

export const sourcingChallengeGroupsRoutes = {
  prefix: 'sourcing-challenge-groups',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SourcingChallengeGroupTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SourcingChallengeGroupFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SourcingChallengeGroupViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SourcingChallengeGroupFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};