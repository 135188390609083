import { ValidationsService } from 'src/app/common/services/validations.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { TableService } from 'src/app/services/table.service';
import { ConfirmationService } from 'primeng/api';
import { LoggerService } from 'src/app/common/services/logger.service';
import { BuildType } from 'src/app/enums/build-type';
import { settingsConstants } from './constants';
import { SettingsService } from './services/settings.service';
import { environment } from 'src/environments/environment';
import { DataManagementService } from 'src/app/common/services/data-management.service';
import { Title } from '@angular/platform-browser';
import { EntityTypeSettingsService } from './services/entity-type-settings.service';
import { ImagerySettingsService } from './services/imagery-settings.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass'],
  providers: [ConfirmationService, MessageService],
})
export class SettingsComponent implements OnInit {
  settingsConstants = settingsConstants;
  type: string = '';
  key: string = '';
  domainEnv: 'development' | 'test' |'production';

  submitUrl: string;
  action = 'add';
  id: number;
  isEditMode: boolean = false;
  existingDoc: any = {};

  cols: any;
  rows: any;
  sceneTypesRows: any;
  imagerySettingsRows: any;
  entityTypeSettingsRows: any;
  display: boolean = false;
  user: any;
  submitted: boolean;

  userDialog: any;
  editUserDialog: any;
  checked: boolean;

  fields: any = {};
  selectedRows: any[] = [];
  selectedRows_Imagery: any[] = [];
  selectedRows_Entity: any[] = [];
  userForm: UntypedFormGroup;

  queues: Array<any> = [
    { name: 'Images', code: BuildType.Images },
    { name: 'Items', code: BuildType.Items },
    { name: 'Levels', code: BuildType.Levels },
  ];
  pauseSelectedQueue: BuildType;
  resumeSelectedQueue: BuildType;

  newRecordData: any;
  newRecordFields: any = {};
  newRecordContainer: any;
  newRecordSubmitted: any;
  newRecordDialog: any;
  newRecordForm: UntypedFormGroup;
  editRecordDialog: any;

  newRecordData_Imagery: any;
  newRecordFields_Imagery: any = {};
  newRecordContainer_Imagery: any;
  newRecordSubmitted_Imagery: any;
  newRecordDialog_Imagery: any;
  newRecordForm_Imagery: UntypedFormGroup;
  editRecordDialog_Imagery: any;

  newRecordData_Entity: any;
  newRecordFields_Entity: any = {};
  newRecordContainer_Entity: any;
  newRecordSubmitted_Entity: any;
  newRecordDialog_Entity: any;
  newRecordForm_Entity: UntypedFormGroup;
  editRecordDialog_Entity: any;

  // CMS Settings
  cmsSettings: any;
  autoPromoteEnabled: boolean;
  autoProdPushEnabled: boolean;
  autoDevQAPushEnabled: boolean;
  manualDevQAGamedataPushEnabled: boolean;
  manualDevGamedataPushEnabled: boolean;
  manualQAGamedataPushEnabled: boolean;
  manualProdPushButtonEnabled: boolean;

  // Check if we are in production environment.
  isProd: boolean = false;
  env: string;

  // managed lists
  linkDestinationFields: any[];
  linkDestinationFBFields: any;

  imagerySettings: Array<any> = [];

  imageryOptions: Array<any> = [];
  selectedImageOptions: Array<any> = [];

  entityTypeSettings: Array<any> = [];
  entityTypeOptions: Array<any> = [];

  // Toaster Life
  toasterLife: number = 3000;

  constructor(
    private messageService: MessageService,
    private tableService: TableService,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private dataService: DataService,
    public authService: AuthService,
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private settingsService: SettingsService,
    private dataManagementService: DataManagementService,
    private titleService: Title,
    private imagerySettingsService: ImagerySettingsService,
    private entityTypeSettingsService: EntityTypeSettingsService,
    private validationService: ValidationsService,
    private router: Router
  ) {}

  async ngOnInit()
  {
    this.setEnv();
    let result = await this.settingsService.getSettingByQuery({
      query: { name: 'cms-settings' },
    });
    this.loggerService.log('CMS Settings: ', result);
    if (result) {
      this.cmsSettings = result;
      this.autoPromoteEnabled = result.autoPromoteEnabled;
      this.autoProdPushEnabled = result.autoProdPushEnabled;
      this.autoDevQAPushEnabled = result.autoDevQAPushEnabled;
      this.manualDevQAGamedataPushEnabled = result.manualDevQAGamedataPushEnabled;
      this.manualDevGamedataPushEnabled = result.manualDevGamedataPushEnabled;
      this.manualQAGamedataPushEnabled = result.manualQAGamedataPushEnabled;
      this.manualProdPushButtonEnabled = result.manualProdPushButtonEnabled;
    }

    this.submitUrl = '';

    this.fields = {
      username: null,
      password: null,
      enabled: false,
    };

    this.newRecordFields = {
      name: null,
      pathName: null,
      enabled: false,
    };

    this.newRecordFields_Imagery = {
      name: null,
      path: 'images/',
      value: null,
      entityType: null,
      enabled: true,
      showOnImageTypeList: true,
      showOnAssetBundleOnly: false
    };

    this.newRecordFields_Entity = {
      name: null,
      value: null,
      enabled: true,
      showOnAssetBundleOnly: false
    };

    await this.initForm();

    // Get row data
    // await this.tableService.getRows(this.type).then(async (data: any) => {
    //   this.rows = data;
    // });

    // get managed lists data
    await this.tableService
      .getRows(settingsConstants.sceneTypes)
      .then(async (data: any) => {
        this.loggerService.log(`sceneTypes:`, data);
        this.sceneTypesRows = data;
      });

    await this.tableService
      .getRows(settingsConstants.imagerySettings)
      .then(async (data: any) => {
        console.log(`imagerySettings:`, data);
        this.imagerySettingsRows = data;
      });

      await this.tableService
      .getRows(settingsConstants.entityTypeSettings)
      .then(async (data: any) => {
        this.entityTypeSettingsRows = data;
      });

    // managed lists fields
    this.linkDestinationFields =
      this.settingsConstants.managedLists.linkDestinationFields;

    this.titleService.setTitle('Settings');
    this.loggerService.log("Env: ", this.env);

    this.getEntityTypeSettings()
    // Need this in the future to get the imagery settings (v2)
    // this.getImagerySettings()
  }

  /**
   * Sets the env value.
   */
  setEnv() {
    this.env = window.location.href.includes('dev.cms') ? 'dev' : (window.location.href.includes('localhost') ? 'local' : (window.location.href.includes('test.cms') ? 'test' : 'prod'));
    this.isProd = environment.production && this.env == 'prod';

    if (window.location.href.includes('prod.cms') ){
      this.domainEnv = 'production';
    } else if (window.location.href.includes('test.cms') ) {
      this.domainEnv = 'test';
    } else {
      this.domainEnv = 'development';
    }
  }

  /**
   * Syncs DB with Prod
   */
  async syncDB() {
    this.dataManagementService.syncDatabase(this.env);
    this.messageService.add({
      severity: 'success',
      summary: 'DB Sync Started',
      detail: `DB Sync result will be notified through Slack.`,
      life: this.toasterLife
    }); 
  }

  async initForm() {
    this.userForm = this.fb.group({
      username: [this.fields.username],
      password: [this.fields.password],
      enabled: [this.fields.enabled],
    });

    this.newRecordForm = this.fb.group({
      name: [this.newRecordFields.name],
      pathName: [this.newRecordFields.pathName],
      enabled: [this.newRecordFields.enabled],
    });

    this.newRecordForm_Imagery = this.fb.group({
      name: [this.newRecordFields_Imagery.name],
      path: [this.newRecordFields_Imagery.path],
      value: [this.newRecordFields_Imagery.value],
      entityType: [this.newRecordFields_Imagery.entityType],
      enabled: [this.newRecordFields_Imagery.enabled],
      showOnImageTypeList: [this.newRecordFields_Imagery.showOnImageTypeList],
      showOnAssetBundleOnly: [this.newRecordFields_Imagery.showOnAssetBundleOnly],
    });

    this.newRecordForm_Entity = this.fb.group({
      name: [this.newRecordFields_Entity.name],
      value: [this.newRecordFields_Entity.value],
      enabled: [this.newRecordFields_Entity.enabled],
      showOnAssetBundleOnly: [this.newRecordFields_Entity.showOnAssetBundleOnly],
    });
  }

  /**
   * sets-up variables for a new form submission
   * @param entity entity name that we are trying to open
   */
  openNew(entity: string) {
    switch (entity) {
      case this.settingsConstants.entities.readOnlyUser:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog = true;
        break;
      case this.settingsConstants.entities.sceneTypes:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog = true;
        break;
      case this.settingsConstants.entities.imagery:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog_Imagery = true;
        this.newRecordForm_Imagery.reset({
          name: null,
          path: 'images/',
          entityType: null,
          enabled: true,
          showOnImageTypeList: true,
          showOnAssetBundleOnly: false
        });
        break;
      case this.settingsConstants.entities.entityType:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog_Entity = true;
        this.newRecordForm_Entity.reset({
          name: null,
          enabled: true,
          showOnAssetBundleOnly: false
        });
        break;
      default:
        break;
    }
  }

  managedListDialog(type: string) {
    console.log(`managedListDialog: ${type}`);
    this.newRecordData = this.settingsConstants.sceneTypesData;
    this.newRecordContainer = {};
    this.newRecordSubmitted = false;
    this.newRecordDialog = true;
  }
  managedListDialog_Imagery(type: string) {
    console.log(`managedListDialog: ${type}`);
    this.newRecordData_Imagery = this.settingsConstants.imagerySettingsData;
    this.newRecordContainer_Imagery = {};
    this.newRecordSubmitted_Imagery = false;
    this.newRecordDialog_Imagery = true;
  }
  managedListDialog_Entity(type: string) {
    console.log(`managedListDialog: ${type}`);
    this.newRecordData_Entity = this.settingsConstants.entityTypeSettingsData;
    this.newRecordContainer_Entity = {};
    this.newRecordSubmitted_Entity = false;
    this.newRecordDialog_Entity = true;
  }
  addUserDialog() {
    this.display = true;
  }

  hideDialog() {
    this.userDialog = false;
    this.editUserDialog = false;
    this.submitted = false;
    this.newRecordDialog = false;
    this.editRecordDialog = false;
    this.newRecordDialog_Imagery = false;
    this.editRecordDialog_Imagery = false;
    this.newRecordDialog_Entity = false;
    this.editRecordDialog_Entity = false;
  }

  editUserModal(row: any) {
    this.isEditMode = true;
    this.editUserDialog = true;

    this.userForm = this.fb.group({
      id: [row.id],
      username: [row.username],
      password: [row.password],
      enabled: [row.enabled],
    });
  }

  generatePassword(row: any) {
    let randomstring = Math.random().toString(36).slice(-8);
    this.loggerService.log('random password', randomstring);

    // cryto testing
    const array = new Uint32Array(10);
    let testPassword = crypto.getRandomValues(array);

    this.loggerService.log('crypto', testPassword[0]);

    let generatePassword = randomstring + testPassword[0];
    this.loggerService.log('crypto', generatePassword);

    this.userForm.controls.password.setValue(generatePassword);
  }

  async updateNewRecord() {
    let id = this.newRecordForm.get('id')!.value;
    let name = this.newRecordForm.get('name')!.value;
    let pathName = this.newRecordForm.get('pathName')!.value;
    let enabled = this.newRecordForm.get('enabled')!.value;
    this.type = 'scene-types';

    await this.dataService.updateRecord(id, this.type, {
      name: name,
      pathName: pathName,
      enabled: enabled,
    });
    await this.tableService.getRows(this.type).then(async (data: any) => {
      this.sceneTypesRows = data;
    });
    this.editRecordDialog = false;
  }
  async updateNewRecord_Imagery() {
    let id = this.newRecordForm_Imagery.get('id')!.value;
    let name = this.newRecordForm_Imagery.get('name')!.value;
    let path = this.newRecordForm_Imagery.get('path')!.value;
    let value = this.newRecordForm_Imagery.get('value')!.value;
    let entityType = this.newRecordForm_Imagery.get('entityType')!.value;
    let enabled = this.newRecordForm_Imagery.get('enabled')!.value;
    let showOnImageTypeList = this.newRecordForm_Imagery.get('showOnImageTypeList')!.value;
    let showOnAssetBundleOnly = this.newRecordForm_Imagery.get('showOnAssetBundleOnly')!.value;
    this.type = 'ImagerySettings';

    this.loggerService.log('updateNewRecord_Imagery', entityType);

    let isValid = this.validateForm();
    if (isValid) {
      await this.dataService.updateRecord(id, this.type, {
        name: name,
        path: path,
        value: value,
        entityType: entityType,
        enabled: enabled,
        showOnImageTypeList: showOnImageTypeList,
        showOnAssetBundleOnly: showOnAssetBundleOnly
      });
      await this.tableService.getRows(this.type).then(async (data: any) => {
        this.imagerySettingsRows = data;
      });
      this.editRecordDialog_Imagery = false;
    }
  }
  // create new updateNewRecord_Entity function
  async updateNewRecord_Entity() {
    let id = this.newRecordForm_Entity.get('id')!.value;
    let name = this.newRecordForm_Entity.get('name')!.value;
    let value = this.newRecordForm_Entity.get('value')!.value;
    let enabled = this.newRecordForm_Entity.get('enabled')!.value;
    let showOnAssetBundleOnly = this.newRecordForm_Entity.get('showOnAssetBundleOnly')!.value;
    this.type = 'entity-type-settings';

    await this.dataService.updateRecord(id, this.type, {
      name: name,
      value: value,
      enabled: enabled,
      showOnAssetBundleOnly: showOnAssetBundleOnly
    });
    await this.tableService.getRows(this.type).then(async (data: any) => {
      this.entityTypeSettingsRows = data;
    });
    this.editRecordDialog_Entity = false;
  }


  async revokeUserAccess(row: any) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to revoke access to ${row.username}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.updateReadOnlyUser(row.id, this.type, {
          enabled: false,
        });
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.rows = data;
        });
      },
    });
  }

  onSubmit() {
    this.submitted = true;
    this.userDialog = false;
    var { value } = this.userForm;
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    this.formService
      .submitForm(value, this.submitUrl, this.isEditMode)
      .subscribe(
        async (val) => {
          console.log('POST call successful value returned in body', val);
          // adjust submit message for edit/new.
          if (this.isEditMode) {
            this.messageService.add({
              severity: 'success',
              summary: 'Update Successful',
              detail: `"${value.username}" was successfully updated`,
              life: this.toasterLife
            });
            window.scrollTo(0, 0);
            this.userDialog = false;
          } else {
            this.messageService.add({
              severity: 'success',
              summary: 'Submit Successful',
              detail: `"${value.username}" was successfully created`,
              life: this.toasterLife
            });
            window.scrollTo(0, 0);
            await this.tableService
              .getRows(this.type)
              .then(async (data: any) => {
                this.rows = data;
              });
          }
        },
        async (response) => {
          console.log('POST call in error', response);

          let summary: string = 'Submit Error';
          let error: string = 'There was an error submitting.';

          if (
            response &&
            response.error &&
            response.error.error.message &&
            response.error.error.message.includes(
              'Error, expected username to be unique.'
            )
          ) {
            summary = 'Duplicated Username';
            error = 'Error, username already exists.';
          }
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: summary,
            detail: error,
          });
          window.scrollTo(0, 0);
          await this.tableService.getRows(this.type).then(async (data: any) => {
            this.rows = data;
          });
        },
        () => {
          console.log('The POST observable is now completed.');
          this.userForm.reset(
          {
            username: "",
            password: "",
            enabled: false,
          });
        }
      );
  }
  
  submitNewRecord() {
    this.loggerService.log('onSubmitNewRecord');
    this.submitted = true;
    this.newRecordDialog = false;
    this.submitUrl = 'scene-types/add';
    this.type = 'scene-types';
    var { value } = this.newRecordForm;

    this.loggerService.log('submitUrl', this.submitUrl);
    this.loggerService.log('value', value);
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    this.formService.submitForm(value, this.submitUrl, false).subscribe(
      async (val) => {
        console.log('POST call successful value returned in body', val);
        this.messageService.add({
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${value.name}" was successfully createds`,
          life: this.toasterLife
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.sceneTypesRows = data;
        });
      },
      async (response) => {
        console.log('POST call in error', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: 'There was an error submitting.',
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.sceneTypesRows = data;
        });
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
  }
  editNewRecordModal(record: any) {
    this.isEditMode = true;
    this.editRecordDialog = true;

    this.newRecordForm = this.fb.group({
      id: [record.id],
      name: [record.name],
      pathName: [record.pathName],
      enabled: [record.enabled],
    });
  }

  confirmDeleteNewRecordModal(record: any) {
    this.type = 'scene-types';
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${record.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(record.id, this.type);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.sceneTypesRows = data;
        });
      },
    });
  }

  submitNewRecord_Imagery() {

    let isValid = this.validateForm();

    if (isValid) {
    this.loggerService.log('onSubmitNewRecord');
    this.submitted = true;
    this.newRecordDialog_Imagery = false;
    this.submitUrl = 'ImagerySettings/add';
    this.type = 'ImagerySettings';
    var { value } = this.newRecordForm_Imagery;

    this.loggerService.log('submitUrl', this.submitUrl);
    this.loggerService.log('value', value);
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    this.formService.submitForm(value, this.submitUrl, false).subscribe(
      async (val) => {
        console.log('POST call successful value returned in body', val);

        this.messageService.add({
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${value.name}" was successfully created`,
          life: this.toasterLife
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.imagerySettingsRows = data;
        });
      },
      async (response) => {
        console.log('POST call in error', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: 'There was an error submitting.',
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.imagerySettingsRows = data;
        });
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
    }
  }

  editNewRecordModal_Imagery(record: any) {
    this.isEditMode = true;
    this.editRecordDialog_Imagery = true;

    this.newRecordForm_Imagery = this.fb.group({
      id: [record.id],
      name: [record.name],
      path: [record.path],
      entityType: [record.entityType],
      value: [record.value],
      enabled: [record.enabled],
      showOnImageTypeList: [record.showOnImageTypeList],
      showOnAssetBundleOnly: [record.showOnAssetBundleOnly],
    });
  }

  submitNewRecord_Entity() {
    this.loggerService.log('onSubmitNewRecord');
    this.submitted = true;
    this.newRecordDialog_Entity = false;
    this.submitUrl = 'entity-type-settings/add';
    this.type = 'entity-type-settings';
    var { value } = this.newRecordForm_Entity;

    this.loggerService.log('submitUrl', this.submitUrl);
    this.loggerService.log('value', value);
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    this.formService.submitForm(value, this.submitUrl, false).subscribe(
      async (val) => {
        console.log('POST call successful value returned in body', val);

        this.messageService.add({
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${value.name}" was successfully created`,
          life: this.toasterLife
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.entityTypeSettingsRows = data;
        });
      },
      async (response) => {
        console.log('POST call in error', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: 'There was an error submitting.',
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.entityTypeSettingsRows = data;
        });
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
  }
  editNewRecordModal_Entity(record: any) {
    this.isEditMode = true;
    this.editRecordDialog_Entity = true;

    this.newRecordForm_Entity = this.fb.group({
      id: [record.id],
      name: [record.name],
      value: [record.value],
      enabled: [record.enabled],
      showOnAssetBundleOnly: [record.showOnAssetBundleOnly],
    });
  }
  confirmDeleteNewRecordModal_Imagery(record: any) {
    this.type = 'ImagerySettings';
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${record.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(record.id, this.type);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.imagerySettingsRows = data;
        });
      },
    });
  }

  confirmDeleteNewRecordModal_Entity(record: any) {
    this.type = 'entity-type-settings';
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${record.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(record.id, this.type);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.entityTypeSettingsRows = data;
        });
      },
    });
  }

  /**
   * Pause a build queue
   */
  async pauseQueue() {
    if (this.pauseSelectedQueue || this.pauseSelectedQueue == BuildType.Items) {
      let result = await this.dataService.pauseBuildQueue(
        this.pauseSelectedQueue
      );

      if (result.Success) {
        this.queueAlertMessage(true, 'Pause Successful', result.Message);
      } else {
        this.queueAlertMessage(
          false,
          'Pause Error',
          'There is an error pausing the queue.'
        );
      }
    }
  }

  /**
   * Resume a build queue
   */
  async resumeQueue() {
    if (
      this.resumeSelectedQueue ||
      this.pauseSelectedQueue == BuildType.Items
    ) {
      let result = await this.dataService.resumeBuildQueue(
        this.resumeSelectedQueue
      );

      if (result.Success) {
        this.queueAlertMessage(true, 'Resume Successful', result.Message);
      } else {
        this.queueAlertMessage(
          false,
          'Resume Error',
          'There is an error resuming the queue.'
        );
      }
    }
  }

  /**
   * Display alert message to indicate user the result of an action
   * related to the queue management
   *
   * @param success Flag that sets whether or not is a success alert.
   * @param summary Summary of the alert.
   * @param detail Details of the alert.
   */
  queueAlertMessage(success: boolean, summary: string, detail: string) {
    this.messageService.add({
      sticky: true,
      severity: success ? 'success' : 'error',
      summary: summary,
      detail: detail,
    });
  }

  /**
   * Handle changes on auto promote toggle
   *
   * @param event Events comming from input switch element
   */
  async handleAutoPromoteChange(event: any) {
    await this.settingsService.updateSetting(this.cmsSettings.id, {
      autoPromoteEnabled: this.autoPromoteEnabled,
    });
  }

  /**
   * Handle changes on auto prod push toggle
   *
   * @param event Events comming from input switch element
   */
  async handleProdPushChange(event: any, isAutoPromoteSetting: boolean = false) {
    if (isAutoPromoteSetting) {
      await this.settingsService.updateSetting(this.cmsSettings.id, {
        autoProdPushEnabled: this.autoProdPushEnabled,
      });
    } else {
      await this.settingsService.updateSetting(this.cmsSettings.id, {
        manualProdPushButtonEnabled: this.manualProdPushButtonEnabled
      });
    }
  }

  /**
   * Handle changes on auto dev + auto qa gamedata push toggle
   *
   * @param event Events comming from input switch element
   */
  async handleAutoDevQAPushEnabledChange(event: any)
  {
    await this.settingsService.updateSetting(this.cmsSettings.id, { autoDevQAPushEnabled: this.autoDevQAPushEnabled });
  }

  /**
   * Handle changes on manual dev + qa gamedata push toggle
   *
   * @param event Events comming from input switch element
   */
  async handleManualDevQAGamedataPushEnabledChange(event: any)
  {
    await this.settingsService.updateSetting(this.cmsSettings.id, { manualDevQAGamedataPushEnabled: this.manualDevQAGamedataPushEnabled });
  }

  /**
   * Handle changes on manual dev gamedata push toggle
   *
   * @param event Events comming from input switch element
   */
  async handleManualDevGamedataPushEnabledChange(event: any)
  {
    await this.settingsService.updateSetting(this.cmsSettings.id, { manualDevGamedataPushEnabled: this.manualDevGamedataPushEnabled });
  }

  /**
   * Handle changes on manual qa gamedata push toggle
   *
   * @param event Events comming from input switch element
   */
  async handleManualQAGamedataPushEnabledChange(event: any)
  {
    await this.settingsService.updateSetting(this.cmsSettings.id, { manualQAGamedataPushEnabled: this.manualQAGamedataPushEnabled });
  }

    /**
   * Retrieves all Imagery Settings records.
   * This may be needed for the future if we want to add a new option for bundleType
   * Leaving this here for now.
   */

    // async getImagerySettings()
    // {
    //   let response = await this.imagerySettingsService.getAllImagerySettingsWithQuery( { query: {  entityType: { $in: ['Misc. Image', 'All'] } } });

    //   if(response && response.length > 0)
    //   {
    //     this.imagerySettings = response;
    //     this.loggerService.log('imagerySettings: ', this.imagerySettings);

    //     this.imagerySettings.forEach((imagerySetting: any) => {
    //       this.imageryOptions.push({ label: imagerySetting.name, value: imagerySetting.value });
    //     }
    //     );
    //       this.imageryOptions.sort((a, b) => a.value - b.value);
    //   }
    // }

    // This may be needed for the future if we want to add a new option for bundleType
    // onValueChanged() {
    //   // this.isEntityBundleSelected = false;
    // }

    /**
     * Retrieves all Entity Type Settings records.
     */
    async getEntityTypeSettings()
    {
      let response = await this.entityTypeSettingsService.getAllEntityTypeSettings();

      if(response && response.length > 0)
      {
        this.entityTypeSettings = response;
        this.loggerService.log('entityTypeOptions: ', this.entityTypeOptions);

         this.entityTypeSettings.forEach((entityTypeSetting: any) => {
         this.entityTypeOptions.push({ label: entityTypeSetting.name, value: entityTypeSetting.value });
         });

         this.loggerService.log('entityTypeOptions: ', this.entityTypeOptions);
      }
    }

    validateForm() {
      let isValid = true;

      if(this.newRecordForm_Imagery.get('path')!.value
        && this.validationService.stringHasSlash(this.newRecordForm_Imagery.get('path')!.value))
        {
          this.alertMessage(
            false,
            'Submit Error',
            'Path contains a \'/ at the beginning. Please Remove the slash and try again.');
          isValid = false;

          return false;

        }
      return isValid;
    }

    /**
   * Display alter message
   *
   * @param success Flag that sets whether or not is a succes alert
   * @param message Message to display.
   * @param detail Additional details.
   */
  alertMessage(success: boolean = true, message: string, detail: string) {
    this.messageService.add({
      severity: success ? 'success' : 'error',
      summary: message,
      detail: detail,
      life: this.toasterLife
    });
  }

  /**
   * @deprecated It'll be removed
   */
  goToTestUsers() {
    this.router.navigate(['/test-users']);
  }
}
