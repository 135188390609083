import { AuthGuard } from "../auth/auth.guard";
import { ItemFormV2Component } from '../entities/item/item-form-v2/item-form-v2.component';
import { ItemTableAgGridComponent } from "../cms-v2/entities/item/components/item-table-ag-grid/item-table-ag-grid.component";
import { ItemViewV2Component } from '../entities/item/item-view-v2/item-view-v2.component';
import { ItemHistoryComponent } from "../entities/item/item-history/item-history.component";

export const itemsRoutes = {
  prefix: 'items',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ItemTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ItemFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ItemViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ItemFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/history/:id`,
        component: ItemHistoryComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};
