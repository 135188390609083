import { DataService } from '../../../services/data.service';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from '../../../auth/auth.service';
import { Output, EventEmitter } from '@angular/core';
import { BuildType } from 'src/app/enums/build-type';
import { UserContextService } from '../../services/user-context.service';
import { LoggerService } from '../../services/logger.service';
import { FormService } from 'src/app/services/form.service';
import { MiscImageService } from 'src/app/entities/misc-image/services/misc-image.service';
import * as _ from 'lodash';
import { EntityTypeSettingsService } from 'src/app/entities/settings/services/entity-type-settings.service';
import { constants } from 'src/app/entities/misc-image/constants';
import { ImagerySettingsService } from 'src/app/entities/settings/services/imagery-settings.service';
import { ValidationsService } from '../../services/validations.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'asset-popup-form',
  templateUrl: './asset-popup-form.component.html',
  styleUrls: ['./asset-popup-form.component.css'],
  providers: [ConfirmationService]
})
export class AssetPopupFormComponent implements OnInit
{
  @Input() isVisible: boolean = false;
  @Input() entity: any = {};
  @Input() buildType: BuildType;
  @Input() type: string = 'miscellaneous-build';
  @Input() showOnAssetBundleOnly: boolean = false;
  @Input() parentEntityTypeOptions: Array<any> = [];
  @Input() imagerySettings: Array<any> = [];
  @Output() formSubmitted = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<any>();
  //
  constants: any = constants;
  entityTypeOptions: Array<any> = [];
  entityTypeSettings: Array<any> = [];
  imageryOptions: Array<any> = [];
  isValidPath: boolean = false;
  approvedAssetsPrefix: string = 'approved_assets/';
  path: string = '';
  validateDupPath: boolean = false;
  isMultipleMode: boolean = false;
  csvFile: File | null = null;
  multipleAssets: Array<{name: string, path: string}> = [{name: '', path: ''}];
  multipleEntityType: any;
  multipleAssetType: any;

  constructor(
    private entityTypeSettingsService: EntityTypeSettingsService,
    private loggerService: LoggerService,
    private imagerySettingsService: ImagerySettingsService,
    private messageService: MessageService,
    private validationService: ValidationsService,
    private gettersService: GettersService,
    private papa: Papa
  ) {}

  /**
   * Build Asset Component Initialization
   */
  ngOnInit(): void
  {
    if (this.parentEntityTypeOptions && this.parentEntityTypeOptions.length > 0) {
      this.entityTypeOptions = this.parentEntityTypeOptions.filter(option => option.value !== -1);
    }
  }

  async buildPath(event: any)
  {
    let pathInput = '';
    if(event.isSelectChange)
    {
      pathInput = event.value.path;
    }
    else
    {
      pathInput = event.value;
    }
    if (this.validatePath(pathInput)) {
      this.entity.path = this.approvedAssetsPrefix + pathInput;
      console.log(this.entity);
      this.isValidPath = true;
    } else {
      this.isValidPath = false;
    }
  }

  validatePath(path: string) {
    if(path && path.length > 0)
    {
      let foundProtected = this.constants.protectedPaths.find(
        (v: string) => path == v
      );
      // let isValidFolder = path.substr(-1) !== '/';
      let isValidFolder = this.validationService.stringHasSlash(path)

      if(isValidFolder){
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Path cannot begin with a slash'
        });
      }

      console.log('foundProtected: ', foundProtected);
      console.log('isValidFolder: ', isValidFolder);

      if (!foundProtected && !isValidFolder) {
        return true;
      } else {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  /**
   * Retrieves all Imagery Settings records.
   */
  async getImagerySettings()
  {
    this.imagerySettings.forEach((imagerySetting: any) => {
      console.log('imagerySetting: ', imagerySetting);
      if(imagerySetting.name!=="All"){
        let tmpSetting = { label: imagerySetting.name, value: imagerySetting.value }
        this.imageryOptions.push(tmpSetting);
      }
    }
    );
    this.imageryOptions.sort((a, b) => a.value - b.value);
  }

  async onEntityValueChanged(eventValue: any, isMultiple: boolean = false) {
    const selectedOption = this.entityTypeOptions.find(option => option.value === eventValue);
    const selectedOptionName = selectedOption ? selectedOption.label : null;

    if (isMultiple) {
      this.imageryOptions = [];
    } else {
      this.imageryOptions = [];
    }

    if(eventValue) {
      if(this.imagerySettings && this.imagerySettings.length > 0) {
        this.imagerySettings.forEach((imagerySetting: any) => {
          if(eventValue !== -1 && imagerySetting.entityType === selectedOptionName) {
            this.imageryOptions.push({ label: imagerySetting.name, value: imagerySetting.value });
          } else if(eventValue === -1) {
            this.imageryOptions = this.entityTypeOptions;
          }
        });
        this.imageryOptions.sort((a, b) => a.value - b.value);
      }
    } else {
      this.imageryOptions = this.entityTypeOptions;
    }
  }

  /**
   * Handle changes type selection
   *
   * @param event Type selected.
   */

  changePathPrefix(event: any)
  {
    if(event && event.value)
    {
      //loop through imagery settings and find the path that matches the selected type
      this.imagerySettings.forEach((imagerySetting: any) => {
        if(imagerySetting.value == event.value)
        {
          this.path = imagerySetting.path;
        }
      })
    }
  }

  formSubmittedTrigger(event:any){
    this.formSubmitted.emit(true);
  }

  onHideEmitter(){
    console.log('onHideEmitter');
    this.onHide.emit({hide: true});
  }

  /**
   * Transforms CSV data into an array of objects
   * @param csvData - Raw CSV string data
   * @returns Array of objects where keys are CSV headers and values are row data
   */
  transformCsvToObjects(csvData: string): Array<any> {
    const result = this.papa.parse(csvData, {
      header: true,           // Treat first row as headers
      skipEmptyLines: 'greedy', // Skip both empty lines and lines with only whitespace/delimiters
      complete: (results) => {
        return results.data;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'CSV Parse Error',
          detail: error.message
        });
        return [];
      }
    });

    return result.data;
  }

  /**
   * Handles CSV file input changes
   * @param event File input event
   */
  onCsvFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.multipleAssets = [];  // Clear previous data
      
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const csvData = e.target.result;
        const transformedData = this.transformCsvToObjects(csvData);
        this.multipleAssets = transformedData.map((row: any) => ({
          name: row.NAME || '',
          path: row.PATH || ''
        }));
        
        // Ensure at least one row exists
        if (this.multipleAssets.length === 0) {
          this.multipleAssets = [{name: '', path: ''}];
        }
      };
      reader.readAsText(file);
    }
  }

  addNewAssetRow() {
    this.multipleAssets.push({name: '', path: ''});
  }

  removeAssetRow(index: number) {
    if (this.multipleAssets.length > 1) {
      this.multipleAssets.splice(index, 1);
    }
  }

  getMultipleAssetsEntity() {
    return {
      entityType: this.multipleEntityType,
      assetType: this.multipleAssetType,
      assets: this.multipleAssets,
      isMultiple: true
    };
  }

  isValidMultipleAssets(): boolean {
    return (
      this.multipleEntityType !== undefined &&
      this.multipleEntityType !== null &&
      this.multipleAssetType !== undefined &&
      this.multipleAssetType !== null &&
      this.multipleAssets.length > 0 &&
      this.multipleAssets.every(asset => 
        asset.name?.trim() && 
        asset.path?.trim() && 
        this.validatePath(asset.path)
      )
    );
  }
}
