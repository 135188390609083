import { AuthGuard } from "../auth/auth.guard";
import { ChallengeCollectionTableAgGridComponent } from '../cms-v2/entities/challenge-collection/components/challenge-collection-table-ag-grid/challenge-collection-table-ag-grid.component';
import { ChallengeCollectionFormV3Component } from "../cms-v2/entities/challenge-collection/components/challenge-collection-form-v3/challenge-collection-form-v3.component";
import { ChallengeCollectionTableViewAgGridComponent } from "../cms-v2/entities/challenge-collection/components/challenge-collection-view-ag-grid/challenge-collection-table-view-ag-grid.component";

export const challengeCollectionsRoutes = {
  prefix: 'challenge-collections',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ChallengeCollectionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ChallengeCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ChallengeCollectionTableViewAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ChallengeCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};
