import { AuthGuard } from "../auth/auth.guard";
import { SeasonalPrizesAgGridComponent } from "../cms-v2/entities/seasonal-prizes/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid.component";
import { SeasonalPrizesFormV2Component } from "../cms-v2/entities/seasonal-prizes/seasonal-prizes-form-v2/seasonal-prizes-form-v2/seasonal-prizes-form-v2.component";
import { SeasonalPrizesViewV2Component } from "../cms-v2/entities/seasonal-prizes/seasonal-prizes-view-v2/seasonal-prizes-view-v2/seasonal-prizes-view-v2.component";

export const seasonalPrizesRoutes = {
  prefix: 'seasonal-prizes',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SeasonalPrizesAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SeasonalPrizesFormV2Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SeasonalPrizesViewV2Component,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SeasonalPrizesFormV2Component,    
        canActivate: [AuthGuard],
      },
    ]
  }
};