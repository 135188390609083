<p-dialog [(visible)]="display" [style]="{width: '80vw'}" [modal]="true" (onHide)="hide()" [draggable]="false">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between align-items-center w-full">
      <h3 class="m-0">Duplicate Record</h3>
      <div>
        <!-- Select All button -->
        <p-button [label]="allSelected ? 'Deselect All' : 'Select All'"
          [icon]="allSelected ? 'fa-solid fa-square-minus' : 'fa-solid fa-square-check'" iconPos="right"
          (onClick)="toggleAllSelection()" class="p-button-secondary"></p-button>

        <!-- Expand All button for bulk -->
        <p-button *ngIf="isBulk" [label]="allPanelsExpanded ? 'Collapse All' : 'Expand All'"
          [icon]="allPanelsExpanded ? 'fa-solid fa-compress' : 'fa-solid fa-expand'" iconPos="right"
          (onClick)="toggleAllPanels()" class="p-button-secondary p-ml-2"></p-button>
      </div>
    </div>
  </ng-template>

  <!-- Preview - Edit values -->
  <div *ngIf="!isDuplicating && duplicatedRecords.length === 0">
    <!-- Bulk preview -->
    <div *ngIf="isBulk">
      <p-accordion>
        <p-accordionTab *ngFor="let recordId of payload._id"
          [header]="'Source ID: ' + records[recordId]?.id + (records[recordId]?.name ? ' - ' + records[recordId].name : '')"
          [(selected)]="expandedPanels[recordId]">
          <div class="p-grid header-row">
            <div class="p-col-fixed" style="width: 15%"><strong>Field</strong></div>
            <div class="p-col-fixed" style="width: 10%"><strong>Duplicate?</strong></div>
            <div class="p-col"><strong>Value</strong></div>
          </div>
          <div class="p-grid" *ngFor="let rowData of records[recordId].tableData">
            <div class="p-col-fixed" style="width: 15%">
              {{ rowData.label }}
              <span *ngIf="rowData.required" style="color: red" class="p-mx-1">*</span>
            </div>
            <div class="p-col-fixed" style="width: 10%">
              <p-checkbox [(ngModel)]="rowData.duplicate" [binary]="true" (onChange)="onItemSelected(rowData)"
                [disabled]="rowData.required">
              </p-checkbox>
              <span *ngIf="rowData.duplicateMessage" class="p-ml-2 text-small" pTooltip="{{rowData.duplicateMessage}}">
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </div>
            <div class="p-col">
              <app-dup-dialog-row [rowData]="rowData" [controller]="rowData.controller" [apiOptions]="apiOptions"
                (valueChange)="onRowValueChange(rowData, $event)" [disabled]="!rowData.duplicate"></app-dup-dialog-row>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <!-- Single record preview -->
    <div *ngIf="!isBulk">
      <div class="p-grid header-row">
        <div class="p-col-fixed" style="width: 15%"><strong>Field</strong></div>
        <div class="p-col-fixed" style="width: 10%"><strong>Duplicate?</strong></div>
        <div class="p-col"><strong>Value</strong></div>
      </div>

      <div class="p-grid" *ngFor="let rowData of tableData">
        <div class="p-col-fixed" style="width: 15%">
          {{ rowData.label }}
          <span *ngIf="rowData.required" style="color: red" class="p-mx-1">*</span>
        </div>
        <div class="p-col-fixed" style="width: 10%">
          <p-checkbox [(ngModel)]="rowData.duplicate" [binary]="true" (onChange)="onItemSelected(rowData)"
            [disabled]="rowData.required">
          </p-checkbox>
          <span *ngIf="rowData.duplicateMessage" class="p-ml-2 text-small" pTooltip="{{rowData.duplicateMessage}}">
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </div>
        <div class="p-col">
          <app-dup-dialog-row [rowData]="rowData" [controller]="rowData.controller" [apiOptions]="apiOptions"
            (valueChange)="onRowValueChange(rowData, $event)" [disabled]="!rowData.duplicate"></app-dup-dialog-row>
        </div>
      </div>
    </div>
  </div>

  <!-- Show progress bar when duplicating -->
  <div *ngIf="isDuplicating" class="p-text-center p-mb-3">
    <ngx-spinner name="dup-dialog-spinner" bdColor="rgba(36,34,36,0.8)" size="small" color="#81c784" type="line-scale">
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
    <div class="p-pt-5">
      <hr>
      <p class="p-my-4">Duplicating records... {{duplicationProgress}}%</p>
      <hr>
    </div>
  </div>

  <!-- Results view -->
  <div *ngIf="duplicatedRecords.length > 0" class="results-container p-mt-3">
    <div class="p-grid results-header">
      <div class="p-col-fixed" style="width: 20%">Source ID</div>
      <div class="p-col-fixed" style="width: 40%">New Record</div>
      <div class="p-col">Status</div>
    </div>
    <div class="p-grid results-grid" *ngFor="let record of duplicatedRecords">
      <div class="p-col-fixed" style="width: 20%">
        <a [href]="'/' + viewRoute + '/' + record.sourceId" target="_blank"
          [ngStyle]="{ color: 'var(--primary-color)' }">
          {{record.sourceId}}
        </a>
      </div>
      <div class="p-col-fixed" style="width: 40%">
        <a *ngIf="record.success" [href]="'/' + viewRoute + '/' + record.newId" target="_blank"
          [ngStyle]="{ color: 'var(--primary-color)' }">
          {{record.newId}}
        </a>
      </div>
      <div class="p-col">
        <span [class]="record.success ? 'success-status' : 'error-status'">
          {{record.success ? 'Success' : 'Failed'}}
        </span>
        <span *ngIf="!record.success" class="error-message">
          : {{record.error}}
        </span>
      </div>

      <!-- Sub-creations section -->
      <div class="p-col-12" *ngIf="record.subCreations">
        <p-divider></p-divider>
        <div class="p-ml-4">
          <!-- Inbox Messages -->
          <div *ngIf="record.subCreations.inboxMessages?.length > 0" class="p-mb-3">
            <h4>Duplicated Inbox Messages: </h4>
            <div *ngFor="let inbox of record.subCreations.inboxMessages" class="p-ml-2">
              <span>
                {{inbox.name}}
                <a [href]="'/inbox-messages/' + inbox.id" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">
                  ({{inbox.id}})
                </a>
              </span>
            </div>
          </div>

          <!-- Announcement Inbox -->
          <div *ngIf="record.subCreations.announcementInbox" class="p-mb-3">
            <h4>Duplicated Announcement Inbox: </h4>
            <div class="p-ml-2">
              <span>
                {{record.subCreations.announcementInbox.name}}
                <a [href]="'/inbox-messages/' + record.subCreations.announcementInbox.id" target="_blank"
                  [ngStyle]="{ color: 'var(--primary-color)' }">
                  ({{record.subCreations.announcementInbox.id}})
                </a>
              </span>
            </div>
          </div>

          <!-- Challenges -->
          <div *ngIf="record.subCreations.challenges?.length > 0" class="p-mb-3">
            <h4>Duplicated Challenges:</h4>
            <div *ngFor="let challenge of record.subCreations.challenges" class="p-ml-2">
              <strong>Challenge: </strong>
              <span>
                {{challenge.name}}
                <a [href]="'/challenges/' + challenge.id" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">
                  ({{challenge.id}})
                </a>
              </span>
              <div *ngIf="challenge.modifiedPrizes?.length > 0" class="p-ml-4 p-mt-2">
                <p class="text-sm font-italic">Modified Item Prizes:</p>
                <div *ngFor="let prize of challenge.modifiedPrizes" class="p-ml-2 text-sm">
                  <strong>Item: </strong>
                  <span>
                    {{prize.name}}
                    <a [href]="'/items/' + prize.id" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">
                      ({{prize.id}})
                    </a>
                    <strong class="p-ml-2"> Re-Release Start: </strong>{{prize.reReleaseStart | officeTime}}
                  </span>
                </div>
              </div>
              <hr>
            </div>
          </div>

          <!-- Loading Screens -->
          <div *ngIf="record.subCreations.loadingScreens?.length > 0" class="p-mb-3">
            <h4>Edited Loading Screens:</h4>
            <div *ngFor="let loadingScreen of record.subCreations.loadingScreens" class="p-ml-2">
              <strong>Loading Screen: </strong>
              <span>
                {{loadingScreen.name}}
                <a [href]="'/loading-screens/' + loadingScreen.id" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">
                  ({{loadingScreen.id}})
                </a>
                <strong class="p-ml-2"> Start: </strong>{{loadingScreen.start | officeTime}}
                <strong class="p-ml-2"> End: </strong>{{loadingScreen.end | officeTime}}
                <strong class="p-ml-2"> Env: </strong> [dev, qa]
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between p-pt-2">
      <button pButton label="Cancel" (click)="onCancel()" class="p-button-danger p-mr-4"
        *ngIf="!isDuplicating && duplicatedRecords.length === 0"></button>

      <button pButton label="Duplicate" (click)="onDuplicate()" [disabled]="isDuplicating" class="p-button-success"
        *ngIf="duplicatedRecords.length === 0"></button>

      <button pButton label="Close" (click)="hide()" class="p-button-primary"
        *ngIf="duplicatedRecords.length > 0"></button>
    </div>
  </ng-template>
</p-dialog>