import { AuthGuard } from "../auth/auth.guard";
import { KeywordsTableAgGridComponent } from "../cms-v2/entities/keywords/keywords-table-ag-grid/keywords-table-ag-grid.component";
import { KeywordsFormV2Component } from "../cms-v2/entities/keywords/keywords-form-v2/keywords-form-v2.component";
import { KeywordsViewV2Component } from "../cms-v2/entities/keywords/keywords-view-v2/keywords-view-v2.component";

export const challengesKeywordsRoutes = {
  prefix: 'challenges-keywords',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: KeywordsTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `challenge-keywords/add`,
        component: KeywordsFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `challenge-keywords/:id`,
        component: KeywordsViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `challenge-keywords/edit/:id`,
        component: KeywordsFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};