import { AuthGuard } from "../auth/auth.guard";
import { ItemFileTypeTableAgGridComponent } from "../cms-v2/entities/item-file-type/components/item-file-type-table-ag-grid/item-file-type-table-ag-grid.component";
import { ItemFileTypeFormV2Component } from "../cms-v2/entities/item-file-type/components/item-file-type-form/item-file-type-form.component";
import { itemfiletypeViewV2Component } from "../cms-v2/entities/item-file-type/components/item-file-type-view-v2/item-file-type-view-v2.component";

export const itemFileTypesRoutes = {
  prefix: 'item-file-types',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ItemFileTypeTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ItemFileTypeFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: itemfiletypeViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ItemFileTypeFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};