import { AuthGuard } from "../auth/auth.guard";
import { PatternTableAgGridComponent } from "../cms-v2/entities/pattern/components/pattern-table-ag-grid/pattern-table-ag-grid.component";
import { PatternViewV2Component } from "../entities/pattern/pattern-view-v2/pattern-view-v2.component";
import { PatternFormV2Component } from "../cms-v2/entities/pattern/components/pattern-form/pattern-form.component";

export const patternsRoutes = {
  prefix: 'patterns',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: PatternTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: PatternFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: PatternViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: PatternFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};