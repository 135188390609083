import { AuthGuard } from "../auth/auth.guard";
import { BatchesAgGridComponent } from "../cms-v2/entities/batch/components/batches-table-ag-grid/batches-table-ag-grid.component";
import { BatchFormV3Component } from "../cms-v2/entities/batch/components/batch-form-v3/batch-form-v3.component";
import { BatchViewV2Component } from "../cms-v2/entities/batch/components/batch-view-v2/batch-view-v2.component";

export const batchesRoutes = {
  prefix: 'batches',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: BatchesAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: BatchFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: BatchViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: BatchFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};