import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-mini-game-form-v2',
  templateUrl: './mini-game-form-v2.component.html',
  styleUrls: ['./mini-game-form-v2.component.sass']
})
export class MiniGameFormV2Component extends BaseDynamicFormComponent implements OnInit {
  miniGameRecord: BaseEntityDto = new BaseEntityDto();
  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  /**
   * MiniGame Form V2 Initial Implementation
   */
  ngOnInit() {
    this.fields =
      [
        {
          title: "MiniGame Data",
          fields:
            [
              {
                name: "Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'name',
                isRequired: true,
                label: 'Name',
                columnWidth: 4
              },
              // {
              //   name: "Move Name",
              //   inputType: DynamicInputType.BaseInputField,
              //   inputTypeFields: [InputFieldType.TextInput],
              //   clearField: true,
              //   key: 'moveName',
              //   isRequired: false,
              //   label: 'Move Name',
              //   disabled: false,
              //   columnWidth: 4
              // },
              {
                name: "Type",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Dropdown],
                clearField: true,
                key: 'type',
                label: 'Type',
                filter: true,
                autoDisplayFirst: false,
                optionLabel: 'name',
                optionValue: 'value',
                isRequired: false,
                options:
                {
                  fieldName: 'type',
                  values:
                    [
                      { name: 'Match the Plant', value: 1 },
                      { name: 'Mole Hunt', value: 2 },
                      { name: 'Sample Game', value: 3 },
                      { name: 'Shuffle', value: 4 },
                      { name: 'Little', value: 5 },
                      { name: 'Dig', value: 6 },
                      { name: 'Board', value: 7 },
                    ]
                },
                filterBy: 'name',
                columnWidth: 3
              },
              {
                name: "Start Date/Time",
                // inputType: DynamicInputType.Calendar,
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'start',
                isRequired: false,
                label: 'Start Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.end) {
                    return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: "End Date/Time",
                // inputType: DynamicInputType.Calendar,
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'end',
                isRequired: false,
                label: 'End Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                calendarMinDate: this.miniGameRecord.start,
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
                  if(record.start && incomingValue){
                    return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
                  } else{
                    return true;
                  }
                }
              },
              {
                name: "Mini Game Asset",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'minigame_ref',
                label: 'Mini Game Asset',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'minigame_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Minigames },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                },
                columnWidth: 6
              },
              {
                name: "Download URL",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'downloadUrl',
                isRequired: false,
                label: 'Download URL',
                disabled: false,
                columnWidth: 6
              },
              {
                name: 'Min. Level Requirement',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Number],
                clearField: false,
                key: 'minLevel',
                isRequired: false,
                label: 'Min. Level Requirement',
                inputNumberMode: 'decimal',
                inputNumberInputId: 'minLevel',
                inputNumberMin: 0,
                columnWidth: 4
              },
              {
                name: 'Reward Loop Multiplier',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Number],
                clearField: false,
                key: 'rewardLoopMultiplier',
                isRequired: false,
                label: 'Reward Loop Multiplier',
                inputNumberMode: 'decimal',
                minFractionDigits: 0,
                maxFractionDigits: 2,
                inputNumberInputId: 'rewardLoopMultiplier',
                inputNumberMin: 0,
                columnWidth: 4
              },
              {
                name: 'Reward Loop Cap',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Number],
                clearField: false,
                key: 'rewardLoopCap',
                isRequired: false,
                label: 'Reward Loop Cap',
                inputNumberInputId: 'rewardLoopCap',
                inputNumberMin: 0,
                columnWidth: 4
              },
              {
                name: 'Min. Moves',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Number],
                clearField: false,
                key: 'minMoves',
                isRequired: false,
                label: 'Min. Moves',
                inputNumberMode: 'decimal',
                inputNumberInputId: 'minLevel',
                inputNumberMin: 0,
                columnWidth: 6
              },
              {
                name: "Mover per Round",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Chip],
                clearField: true,
                key: 'movesPerRound',
                isRequired: false,
                label: 'Mover per Round',
                disabled: false,
                columnWidth: 6
              },
              {
                name: "Welcome Popup Description",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.InputTextArea],
                clearField: true,
                key: 'welcomePopupDescription',
                isRequired: false,
                label: 'Welcome Popup Description',
                columnWidth: 12
              },
              {
                name: "Title Localization Key",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'titleLocalizationKey',
                isRequired: false,
                label: 'Title Localization Key',
                columnWidth: 6
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Imagery",
          fields:
            [
              {
                name: "Banner Image",

                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'banner_ref',
                label: 'Banner Image',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'banner_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Logo Image",

                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'logoImage_ref',
                label: 'Logo Image',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'logoImage_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Welcome Popup Image",

                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'welcomePopupImage_ref',
                label: 'Welcome Popup Image',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'welcomePopupImage_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Welcome Popup Background",

                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'welcomePopupBackground_ref',
                label: 'Welcome Popup Background',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'welcomePopupBackground_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Welcome Popup Header",

                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'welcomePopupHeader_ref',
                label: 'Welcome Popup Header',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'welcomePopupHeader_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              // {
              //   name: "Move Image",
              //   inputType: DynamicInputType.MiscImageRecord,
              //   clearField: true,
              //   key: 'moveImage_ref',
              //   label: 'Move Image',
              //   optionValue:'_id',
              //   filter: true,
              //   filterBy:'name,id,path',
              //   autoDisplayFirst: false,
              //   setMongoId: true,
              //   options: {
              //     fieldName: 'moveImage_ref', apiController: 'miscellaneous-build',
              //     customQuery: { entityType: BuildType.Images },
              //     autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
              //   }
              // },
              {
                name: "Bar Background",

                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'barBackground_ref',
                label: 'Bar Background',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'barBackground_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Bar Overlay",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'barOverlay_ref',
                label: 'Bar Overlay',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'barOverlay_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Game Icon",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'gameIcon_ref',
                label: 'Game Icon',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'gameIcon_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
              {
                name: "Main Background",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'mainBackground_ref',
                label: 'Main Background',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: true,
                scrollHeight: '200px',
                options: {
                  fieldName: 'mainBackground_ref', apiController: 'miscellaneous-build',
                  customQuery: { entityType: BuildType.Images },
                  autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
                }
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Costs & Rewards",
          fields:
            [
              {
                name: 'Cost(s)',
                inputType: DynamicInputType.Price,
                options: { fieldName: '', version: 3 },
                key: 'costs_ref',
                columnWidth: 12
              },
              {
                name: 'Reward(s)',
                inputType: DynamicInputType.Price,
                options: {fieldName:'', version: 3},
                key: 'rewards_ref',
                columnWidth: 12
              },
              {
                name: 'Rewards Track',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Dropdown],
                clearField: true,
                key: 'rewardsTrack_ref',
                isRequired: false,
                label: 'Rewards Track',
                optionLabel: 'name',
                optionValue: "_id",
                filter: true,
                filterBy: "id,name",
                showClear: true,
                setMongoId: true,
                autoDisplayFirst: false,
                style: { 'min-width': '30em' },
                options: { fieldName: 'rewardsTrack_ref', apiController: 'rewards-track', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, customQuery: { type: 8 } }
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Mini Game Metadata",
          fields:
            [
              {
                name: "JSON",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.InputTextArea],
                clearField: false,
                key: 'metadata',
                isRequired: false,
                label: 'JSON',
                disabled: false,
                columnWidth: 12,
                inputTextAreaCols: 40,
                inputTextAreaRows: 20
              },
            ],
          type: DynamicCardType.Card
        },
      ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.miniGameRecord =
    {
      enabled: false
    };
    this.title = "Mini Game";
    this.viewRoute = 'minigames';
    this.isLoading = false;
  }

  /**
   * Validate MiniGame required field.
   *
   * @param miniGame MiniGame record
   */
  validateMiniGame(miniGame: any) {
    let confirmValidationCallback = miniGame && miniGame.name && miniGame.name.length > 0;
    let message = confirmValidationCallback ? 'Valid' : 'Mini Game Name must have a value';

    return { confirmValidationCallback, message }
  }
}
