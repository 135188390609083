import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';

@Component({
  selector: 'app-audio-collection-table-ag-grid',
  templateUrl: './audio-collection-table-ag-grid.component.html',
  styleUrls: ['./audio-collection-table-ag-grid.component.sass']
})
export class AudioCollectionTableAgGridComponent implements OnInit {

  public entity: string = 'audio-collections';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = 'audios_ref';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Audio Assets',
        field: 'audios_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-builds',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.audios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // assetKeys: ['prefab_ref'],
      // assetBuildType: BuildType.Items,
      // renderBuildType: BuildType.Items,
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    const entities = [
      { ref: 'audios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [23] }} },
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }

}
