import { AuthGuard } from "../auth/auth.guard";
import { NurtureCollectionTableAgGridComponent } from "../cms-v2/entities/nurture/nurture-collection/nurture-collection-table-ag-grid/nurture-collection-table-ag-grid.component";
import { NurtureCollectionFormComponent } from "../cms-v2/entities/nurture/nurture-collection/nurture-collection-form/nurture-collection-form.component";
import { NurtureCollectionViewComponent } from "../cms-v2/entities/nurture/nurture-collection/nurture-collection-view/nurture-collection-view.component";
import { ReleaseNurtureCollectionComponent } from "../entities/release/release-nurture-collection/release-nurture-collection/release-nurture-collection.component";

export const nurtureCollectionRoutes = {
  prefix: 'nurture-collection',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: NurtureCollectionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: NurtureCollectionFormComponent,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: NurtureCollectionViewComponent,     
        canActivate: [AuthGuard],
      },
      {
        path: `releases/${this.prefix}/:id`,
        component: ReleaseNurtureCollectionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: NurtureCollectionFormComponent,  
        canActivate: [AuthGuard],
      },
    ]
  }
};