import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import CollectionDto from '../../dtos/CollectionDTO';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataService } from 'src/app/services/data.service';
import { TableCheckbox } from 'primeng/table';
import * as _ from 'lodash';

interface DataItem {
  id: number;
  name: string;
  selected?: boolean;
}
@Component({
  selector: 'collection-custom-field',
  templateUrl: './collection-custom-field.component.html',
  styleUrls: ['./collection-custom-field.component.sass'],
  providers: [ConfirmationService]
})
export class CollectionCustomFieldComponent implements OnInit
{
  @Input() collection: CollectionDto = new CollectionDto();
  @Input() utilitiesService: any;
  @ViewChild('dt') table: any
  @Output() onChange = new EventEmitter<any>();
  itemsRefTableValue: Array<any> = [];
  suggestions: any = [];
  isLoading: boolean = true;
  // bulk edit variables
  isBulkDelete: boolean = false;
  selectedItems: Array<any> = [];
  lastClickedIndex: number;

  constructor
  (
    private validationService: ValidationsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dataService: DataService,
  ) { }

  ngOnInit()
  {
    if(!this.collection.items_ref)
    {
      this.collection.items_ref = [];
    }
    this.itemsRefTableValue = this.collection.items_ref && this.collection.items_ref.length > 0 ? this.collection.items_ref : [];
    this.isLoading = false;
  }

  /**
 * Get suggestions for auto-complete
 *
 * @param event Event coming from the component
 * @param fieldName Name of the field to set suggestions
 * @param model API endpoint route
 */
async getSuggestionsForRef(
  event: any,
  fieldName: string | any,
  model: string
) {
  this.dataService.getAllOfType(model, {
    query: isNaN(event) ? { name: { $regex: event, $options: 'i' } } : { id: event },
    select: '_id name id',
    virtuals: false,
    autopopulate: false,
    sort: { name: 1 },
  })
  .subscribe((result) => {
    // Filter out items that are already added in `itemsRefTableValue`
    const filteredResult = result.filter((item) => 
      !this.itemsRefTableValue.some(existingItem => existingItem._id === item._id)
    );

    // Set filtered suggestions for the field
    this.suggestions[fieldName] = filteredResult;
  });
}


  onSelection(item:any)
  {
    if(this.itemsRefTableValue)
    {
      this.itemsRefTableValue.push(item);
      this.collection.items_ref = this.itemsRefTableValue;
      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Item Added',
        detail: ` ${item.name} (${item.id}) has been added to the table. Click Submit to apply changes.`,
      });
    }
    this.onChange.emit(this.collection.items_ref);
  }

  confirmRemoveItemRef(row:any)
  {
    this.confirmationService.confirm(
    {
      message: `Are you sure that you want to remove ${row.name} (${row.id}) ?`,
      accept: async () =>
      {
        // removing item from table and collection's items_ref
        this.itemsRefTableValue = this.itemsRefTableValue.filter(function( item ) {
          return item.id !== row.id;
        });
        this.collection.items_ref = this.collection.items_ref.filter(function( item ) {
          return item.id !== row.id;
        });
        this.onChange.emit(this.collection.items_ref);
        this.messageService.add({
          sticky: true,
          severity: 'warn',
          summary: 'Item Removed',
          detail: ` ${row.name} (${row.id}) has been removed from the table. Click Submit to apply changes.`,
        });
      },
    });
  }

  // Toggle bulk delete mode
  toggleBulkDelete(event: any) {
    this.isBulkDelete = event.checked;
    if (!this.isBulkDelete) {
      this.selectedItems = [];
      this.itemsRefTableValue.forEach(item => item.selected = false);
    }
  }

  // Confirm and remove selected items
  confirmRemoveSelectedItems() {
    this.selectedItems = [...new Set(this.selectedItems)]
    this.selectedItems = this.selectedItems.filter( value => typeof value == 'object' )
    const itemsList = this.selectedItems.map(item => `${item.name} (${item.id})`).join(', ');
  
    this.confirmationService.confirm({
      message: `Are you sure you want to remove the following items? ${itemsList}`,
      accept: () => {
        const removedItemCount = this.selectedItems.length;  // Store the count of items to be removed
  
        this.selectedItems.forEach(selectedItem => {
          this.itemsRefTableValue = this.itemsRefTableValue.filter(item => item.id !== selectedItem.id);
          this.collection.items_ref = this.collection.items_ref.filter(item => item.id !== selectedItem.id);
        });
        
        this.onChange.emit(this.collection.items_ref);
        this.selectedItems = [];
  
        // Add a message confirming the number of items removed
        this.messageService.add({
          severity: 'warn',
          summary: 'Items Removed',
          detail: `${removedItemCount} item(s) have been removed successfully. Submit form to save changes.`
        });
      }
    });
  }
  

  updateSelectedItems(item: any) {
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      const index = this.selectedItems.findIndex(selectedItem => selectedItem.id === item.id);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    }
  }
  checkboxTable(tableCheckBox: TableCheckbox, event: any) {
    console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }
  /** Handle checkbox toggle envents on a table row
   * @param row Row that has the checkbox toggle
   * @param isChecked Flag that sets whether or not is checked
   */
  onRowCheckboxToggle(row: any, isChecked: boolean) {
    let rowSelection: any[] = [...this.selectedItems];
    if(row) {
      console.log(row, isChecked)
      if (Array.isArray(row)) {
        rowSelection = [...rowSelection, row.reduce((acc, val) => acc.concat(val.id), [])]
        rowSelection = rowSelection.reduce((acc, val) => acc.concat(val), [])
      } else {
        if (isChecked) {
          rowSelection.push(row);
          // this.messageService.add({
          //   severity: 'success',
          //   summary: `Row Selected`,
          //   detail: 'Row selected successfully',
          //   life: this.toasterLife
          // });
        } else {
          let rowIndex: number = rowSelection.findIndex(
            (id: number) => id == row.id
          );
          rowSelection.splice(rowIndex, 1);
        }
    }

    }
    this.selectedItems = _.sortedUniq(_.sortBy(rowSelection));
  }
}
