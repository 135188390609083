import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InputFieldType } from '../../enums/InputFieldType';
import DynamicFormFieldDTO from '../../dtos/DynamicFormFieldDTO';
import OnFieldChangeDTO from '../../dtos/OnFieldChangeDTO';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { DynamicFormV2Component } from '../../dynamic-form-v2.component';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { debounce } from 'lodash';
import { OfficeTimeFormPipe } from 'src/app/common/pipes/officeTimeForm.pipe';

@Component({
  selector: 'app-base-input-field',
  templateUrl: './base-input-field.component.html',
  styleUrls: ['./base-input-field.component.sass'],
  providers: [MessageService],
})
export class BaseInputFieldComponent2 implements OnInit {
  @Input() record: any; // Define a more specific type if possible
  @Input() label: string;
  @Input() onLabel: string;
  @Input() offLabel: string;
  @Input() inputType: string;
  @Input() inputTypeFields: InputFieldType[];
  @Input() field: DynamicFormFieldDTO;
  @Input() options: Array<any>; // Accept options as input
  @Input() fieldValue: any; // Assuming the type is any; specify the correct type
  @Input() entityType: string;

  // @Input() style: any; // Assuming the type is any; specify the correct type

  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() optionDisabled: string;
  @Output() fieldChanged = new EventEmitter<OnFieldChangeDTO>();
  @Input() validate: (value: string) => boolean;
  @Input() isdisplayNameIDPath: boolean = false;
  @Input() isEdit: boolean;
  @Output() isBaseInputValid = new EventEmitter<boolean>();
  @Output() fieldValueChange = new EventEmitter<any>();
  @Input() dynamicForm: DynamicFormV2Component;
  @Input() showQueryDropdown: boolean = false;

  isDialogVisible: boolean = false; 
  selectedItems: any[] = []; 

  private debounceHandleDateInputChange: (field: any, event: any) => void;
  defaultDate: any;

  InputFieldType = InputFieldType;
  specificObject: any;
  suggestions: Array<any> = [];

  @Input() useServiceFunctions: boolean = false;

  fieldTemplates: {
    [key: string]: {
      header?: string[];
      fields: string[];
    };
  } = {
      'Link - Destination': {
        header: ['Link', 'Destination'],
        fields: ['linkText', 'linkDestination'],
      },
      'Target Collection': {
        fields: ['name', 'id'],
      },
    };

  get isValid(): boolean {
    return this.field.validate
      ? this.field.validate(this.fieldValueChange)
      : true;
  }

  constructor(
    private utilitiesService: UtilitiesService,
    private loggerService: LoggerService,
    private validationService: BaseInputValidationServiceService,
    private messageService: MessageService,
    private commonEntityService: CommonEntityService,
    private officeTimePipe: OfficeTimeFormPipe,
  ) {
    this.debounceHandleDateInputChange = debounce(this.handleDateInputChange, 3000).bind(this);
  }

  ngOnInit() {
    //
    if (this.field.showField === undefined) {
      this.field.showField = true;
    }
    //
    if (
      !this.fieldValue &&
      this.inputTypeFields.includes(InputFieldType.MultiSelect)
    ) {
      this.fieldValue = [];
    }
    if (
      this.fieldValue &&
      this.inputTypeFields.includes(InputFieldType.Dropdown)
    ) {
      this.loggerService.log('fieldValue', this.fieldValue);
      // this.fieldValue = this.options.map(item => item._id)
      if (this.fieldValue._id) {
        this.fieldValue = this.fieldValue._id;
      }
    }
    if (
      this.fieldValue &&
      this.inputTypeFields.includes(InputFieldType.Calendar)
    ) {
      this.fieldValue = this.fieldValue ? new Date(this.officeTimePipe.transform(this.fieldValue)) : null
    }
    if (
      !this.fieldValue &&
      this.inputTypeFields.includes(InputFieldType.Calendar)
    ) {
      this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight();
    }

    // trigger value change to let neighboring components know the initial value
    if(this.field.emitOnInit){
      this.emitFieldValueChange(this.fieldValue);
    }
    
    // console.log('isdisplayNameIDPath', this.isdisplayNameIDPath);
  }

  onValueChange(newValue: any) {
    let isValid = true;
    let errorMessage = '';

    // Validate the field if validation function is present
    if (this.field.validate) {
        const validationResult = this.field.validate(newValue, this.record, this.field.key);

        if (Array.isArray(validationResult)) {
            [isValid, errorMessage] = validationResult;
        } else if (typeof validationResult === 'boolean') {
            isValid = validationResult;
        }
    }

    this.field.touched = true;

    // Handle null value (clearing the field)
    if (newValue === null) {
        this.clearFieldValue(newValue);
        return;
    }

    // Update and emit logic
    if (isValid) {
        this.fieldValue = newValue; // Update with the new value
    } else {
        this.fieldValue = null; // Optionally reset the invalid input
        this.showErrorMessage('Invalid Input', errorMessage);
    }

    this.emitFieldValueChange(this.fieldValue);
    this.emitIsBaseInputValid(isValid);
}

// Helper function to clear the field value and emit changes
private clearFieldValue(newValue: any) {
    this.fieldValue = null; // Reset the internal fieldValue
    this.fieldValueChange.emit(newValue); // Emit the change
}

// Helper function to emit field value change
private emitFieldValueChange(value: any) {
  const normalizeValue = (input: any) => {
    if (this.field.inputTypeFields[0] === 'Calendar') {
      return new Date(input).getTime(); // Convert date to Unix timestamp
    }
    return input; // Return as-is for non-date values
  };

  const recordValue = normalizeValue(this.record[this.field.key]);
  const newValue = normalizeValue(value);

  if (recordValue != newValue) {
    console.log('TOUCHING: ', this.field.key);
    this.field.touched = true;
  }

  this.fieldValueChange.emit(value); // Emit the updated fieldValue
}



  // Method to emit a boolean to the parent component to indicate if the input is valid
  emitIsBaseInputValid(isValid: boolean) {
    this.isBaseInputValid.emit(isValid);
  }

  // this function is used to show the error message for validation set within the parent form component and onValueChange function

  showErrorMessage(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
      // life: 3000, // Message duration in milliseconds
    });
  }

  // Additional method if needed for p-multiSelect specific logic
  onMultiSelectChange(event: any) {
    // Update the record value based on the multiSelect change
    this.record[this.field.key] = event.value;
    // Call onValueChange to handle the change
    this.onValueChange(event.value);
  }


  handleDateInputChange(field: any, event: any) {
    const inputValue = event.target.value;
    // Adjust the format to include time (e.g., 'YYYY-MM-DD HH:mm')
    const isValidDateTime = moment(
      inputValue,
      'YYYY-MM-DD HH:mm',
      true
    ).isValid();

    if (isValidDateTime) {
      const parsedDate = moment(inputValue).toDate();
      if (field === 'start') {
        this.record.start = parsedDate;
      } else if (field === 'end') {
        this.record.end = parsedDate;
      }
      // Additional code to handle valid date
    } else {
      this.showErrorMessage('Invalid Date Input', 'Please enter a valid date. A valid format should be YYYY-MM-DD HH:mm.');
    }
  }

  onDateInputChange(field: any, event: any) {
    this.debounceHandleDateInputChange(field, event);
  }

  resetTime() {
    if (this.fieldValue) {
      // Set the time part of the date to 00:00
      this.fieldValue.setHours(0, 0, 0, 0);

      // Update the model to ensure changes are reflected in the view
      this.fieldValue = new Date(this.fieldValue);

      // Call onValueChange or similar method to handle any side effects of changing the date
      this.onValueChange(this.fieldValue);
    }
  }


  clearField(key: string): void {
    this.record[key] = null;
    this.field.touched = true; // Mark the field as touched
    this.onValueChange(null); // Trigger validation and update logic
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  handleItemsRefChange(updatedItemsRef: any) {
    // Strip down to only id, _id, name fields
    const strippedItemsRef = updatedItemsRef.map((item: any) => ({
      id: item.id,
      _id: item._id, 
      name: item.name
    }));

    console.log("Updated items_ref:", strippedItemsRef);

    // Emit the stripped down items_ref
    this.onValueChange(strippedItemsRef);
  }

  /**
     * Autocomplete searching
     *
     * @param value Query value
     * @param fieldName Field name
     * @param model API Controller
     * @param minimal Flag that sets whether or not to return minimal result
     * @param autopopulate Flag that sets whether or not to autopopulate result.
     * @param sort Sort expression
     * @param virtuals Flag that sets whether or not to include virtual props.
     * @param select Select query
     */
  async getSuggestionsForRef(
    value: any,
    fieldName: string | any,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = false,
    sort: any = null,
    virtuals: boolean = false,
    select: string | null = null,
    customQuery: any = null,
    customQueryField: string | null = null
  ) {
    let idValue = parseInt(value);
    let queryValue = customQuery ? customQuery : (customQueryField && customQueryField.length > 0 ? (isNaN(idValue) ? { [customQueryField]: { $regex: value, $options: 'i' } } : { id: idValue }) : (isNaN(idValue) ? { name: { $regex: value, $options: 'i' } } : { id: idValue }));
    let result = await this.commonEntityService.findAllWithQuery(model,
      {
        query: queryValue,
        select: select ? select : '_id name id',
        virtuals: virtuals,
        autopopulate: autopopulate,
        sort: sort ? sort : { name: 1 },
        isOptions: true
      });

    if (minimal) {
      let o: any[] = [];
      for (const option of result) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.suggestions[fieldName] = o;
    } else {
      this.suggestions[fieldName] = result;
    }
  }

  transform(i: any) {
    const obj = this.options.find((item: any) => item._id === i);
    return obj ? obj.name + ' (' + obj.id + ')' : null;
  }

  propagateItems_refChange(value: any){
    this.fieldValue = value;
  }

  showSelectedItems() {
    if (this.options && typeof this.options === 'object' && this.field && typeof this.field.key === 'string' && this.field.optionValue) {
      const selectedOptions = this.options[this.field.key as keyof typeof this.options];
  
      if (Array.isArray(selectedOptions)) {
        this.selectedItems = selectedOptions
          .filter(option => this.fieldValue.includes(option[this.field.optionValue as keyof typeof option]))
          .map(option => ({
            ...option,
            link: `/${this.field.options?.apiController ?? ''}/${option['id'] ?? ''}`
          }));
      } else {
        console.error("Selected options are not in an array format.");
        this.selectedItems = [];
      }
  
      this.isDialogVisible = true; 
    } else {
      console.error("Options or field properties are not defined or improperly typed.");
    }
  }
}
