import { AuthGuard } from "../auth/auth.guard";
import { CurrencyFormV3Component } from "../cms-v2/entities/currency/components/currency-form-v3/currency-form-v3.component";
import { CurrencyViewV2Component } from "../cms-v2/entities/currency/components/currency-view-v2/currency-view-v2.component";
import { CurrencyTableAgGridComponent } from "../cms-v2/entities/currency/components/entity-table-ag-grid/currency-table-ag-grid.component";

export const currenciesRoutes = {
  prefix: 'currencies',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: CurrencyTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: CurrencyFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: CurrencyViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: CurrencyFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};
