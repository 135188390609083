import { AuthGuard } from "../auth/auth.guard";
import { ColorTableAgGridComponent } from "../cms-v2/entities/color/components/color-table-ag-grid/color-table-ag-grid.component";
import { ColorFormV2Component } from "../cms-v2/entities/color/components/color-form-v2/color-form-v2.component";
import { ColorViewV2Component } from "../cms-v2/entities/color/components/color-view-v2/color-view-v2.component";

export const colorsRoutes = {
  prefix: 'colors',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ColorTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ColorFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ColorViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ColorFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};