import { AuthGuard } from "../auth/auth.guard";
import { LoadingScreenFormV3Component } from "../cms-v2/entities/loading-screen/loading-screen-form-v3/loading-screen-form-v3.component";
import { LoadingScreenViewV2Component } from "../cms-v2/entities/loading-screen/loading-screen-view-v2/loading-screen-view-v2.component";
import { LoadingScreenTableAgGridComponent } from "../cms-v2/entities/loading-screen/loading-screen-table-ag-grid/loading-screen-table-ag-grid.component";

export const loadingScreensRoutes = {
  prefix: 'loading-screens',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: LoadingScreenTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: LoadingScreenFormV3Component,  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: LoadingScreenViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: LoadingScreenFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};