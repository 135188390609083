import { AuthGuard } from "../auth/auth.guard";
import { AssetViewV2Component } from "../common/components/assets/asset-view/asset-view-v2.component";
import { AssetFormV3Component } from "../common/components/assets/asset-form/asset-form-v3.component";
import { MiscImageTableAgGridComponent } from "../entities/misc-image/misc-image-table-ag-grid/misc-image-table-ag-grid.component";

export const miscellaneousBuildRoutes = {
  prefix: 'miscellaneous-build',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: MiscImageTableAgGridComponent,       
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: AssetFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: AssetViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: AssetFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};