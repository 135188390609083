<!-- Date type -->
<ng-container [ngSwitch]="rowData.type">
    <span *ngSwitchCase="'date'">{{ rowData.value | officeTime: 'short' }}</span>

    <!-- Multiselect_ref type -->
    <ul *ngSwitchCase="'multiselect_ref'" class="p-0">
        <li *ngFor="let item of rowData.value" class="p-mb-1">
            {{item?.name}}
            <a href="/{{rowData?.controller}}/{{item?.id}}" target="_blank"
                [ngStyle]="{ color: 'var(--primary-color)' }">
                ({{item?.id}})
            </a>
        </li>
    </ul>

    <!-- single_ref type-->
    <div *ngSwitchCase="'single_ref'">
        <ng-container *ngIf="rowData?.value">
            <span>{{ rowData.value.name }}</span>
            <a href="/{{rowData.controller}}/{{rowData.value.id}}" target="_blank"
                [ngStyle]="{ color: 'var(--primary-color)' }">
                ({{rowData.value.id}})
            </a>
        </ng-container>
    </div>

    <!-- Costs type -->
    <div *ngSwitchCase="'costs'">
        <div *ngFor="let reward of rowData.value; let i = index">
            <p-divider *ngIf="i > 0"></p-divider>
            <div *ngIf="reward.lineItemType === 'Item'" class="p-mb-2">
                <div class="p-mb-1">
                    <span class="p-text-bold">Type: </span>
                    <span>{{reward.lineItemType}}</span>
                </div>
                <div class="reward-layout">
                    <img *ngIf="reward.id?.thumbnail_ref?.thumbnails?.length > 4"
                        [src]="reward.id.thumbnail_ref.thumbnails[4].path" alt="Item Thumbnail" class="item-thumbnail">
                    <p-tag severity="info" [value]="reward.c.toString()" class="item-quantity"></p-tag>
                    <span class="item-name">{{ reward.id?.name }}</span>
                    <span>({{ reward.id?.id }})</span>
                </div>
            </div>
            <div *ngIf="reward.lineItemType !== 'Item'" class="p-mb-2">
                <div class="p-mb-1">
                    <span class="p-text-bold">Type: </span>
                    <span>{{reward.lineItemType}}</span>
                </div>
                <div class="p-mb-1">
                    <span class="p-text-bold">Quantity: </span>
                    <p-tag severity="info" [value]="reward.c.toString()"></p-tag>
                </div>
                <div>
                    <span class="p-text-bold">Reward: </span>
                    <span>{{ reward.id?.name }}</span>
                    <span>({{ reward.id?.id }})</span>
                </div>
            </div>
        </div>
    </div>

    <!-- text_arr -->
    <ul *ngSwitchCase="'text_arr'" class="p-0">
        <li *ngFor="let item of rowData.value">
            {{item | json}}
        </li>
    </ul>

    <!-- nameInput -->
    <div *ngSwitchCase="'nameInput'">
        <input *ngIf="rowData.isInput" type="text" pInputText [(ngModel)]="rowData.value" [ngStyle]="{'width': '90%'}"
            (ngModelChange)="onValueChange($event)" [disabled]="disabled" />
        <span *ngIf="!rowData.isInput">{{ rowData.value }}</span>
    </div>

    <!-- dateInput -->
    <div *ngSwitchCase="'dateInput'">
        <span *ngIf="rowData.isInput" class="calendar-input p-float-label">
            <p-calendar [(ngModel)]="rowData.value" [showTime]="true" [yearNavigator]="true" [monthNavigator]="true"
                [showButtonBar]="true" [showClear]="true" [selectOtherMonths]="true" [hideOnDateTimeSelect]="true"
                [showIcon]="false" class="field-item" appendTo="body"
                (ngModelChange)="onValueChange($event)" [disabled]="disabled">
                <ng-template pTemplate="footer">
                    <button type="button" pButton label="Reset Time" class="p-button-text" [style]="{'height': '20px'}"
                        (click)="resetTimeToMidnight(rowData.value, $event)"></button>
                </ng-template>
            </p-calendar>
        </span>
        <span *ngIf="!rowData.isInput">{{ getFormattedDate(rowData.value) }}</span>
    </div>

    <!-- multiselectDups -->
    <div *ngSwitchCase="'multiselectDups'">
        <div *ngFor="let item of rowData.value; let i = index" class="p-mb-2">
            <div class="flex align-items-center gap-2">
                <p-checkbox *ngIf="rowData.isInput" class="p-mr-1" [(ngModel)]="item.selected" [binary]="true"
                    (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled"></p-checkbox>
                <span>{{ item.name }}</span>
                <a href="/{{rowData?.controller}}/{{item?.id || item?._id}}" target="_blank"
                    [ngStyle]="{ color: 'var(--primary-color)' }">
                    ({{item?.id || item?._id}})
                </a>
            </div>
            <div class="p-d-flex p-ai-center p-ml-4 p-my-1">
                <ng-container *ngIf="rowData.showNameInput">
                    <label class="p-mr-2">Name:</label>
                    <input type="text" pInputText [(ngModel)]="item.name" class="p-mr-2" [ngStyle]="{'width': '40%'}"
                        (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled" />
                </ng-container>
                <ng-container *ngIf="rowData.showDateInputs">
                    <ng-container *ngFor="let field of [
                    {label: 'Start:', model: 'start'},
                    {label: 'End:', model: 'end'}
                  ]">
                        <div class="p-d-flex p-ai-center p-mr-3">
                            <label class="p-mr-2">{{field.label}}</label>
                            <p-calendar *ngIf="rowData.isInput" [(ngModel)]="item[field.model]" [showTime]="true"
                                [showButtonBar]="true" appendTo="body" class="field-item"
                                (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled">
                                <ng-template pTemplate="footer">
                                    <button type="button" pButton label="Reset Time" class="p-button-text"
                                        [style]="{'height': '20px'}"
                                        (click)="resetTimeToMidnight(rowData.value, $event, field.model, i)"></button>
                                </ng-template>
                            </p-calendar>
                            <span *ngIf="!rowData.isInput">{{ getFormattedDate(item[field.model]) }}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="p-d-flex p-ai-center p-ml-4 p-my-1">
                <ng-container *ngIf="rowData.showTTLInput">
                    <label class="p-mr-2">TTL:</label>
                    <p-dropdown [options]="TTL_OPTIONS" [(ngModel)]="item.ttl" [style]="{'width': '150px'}"
                        class="p-mr-2" [disabled]="disabled" appendTo="body"
                        (ngModelChange)="onValueChange(rowData.value)" >
                    </p-dropdown>
                </ng-container>
                <ng-container *ngIf="rowData.showProductId">
                    <label class="p-mr-2">Product ID:</label>
                    <input type="text" pInputText [(ngModel)]="item.productId" class="p-mr-2"
                        [ngStyle]="{'width': '40%'}" (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled" />
                </ng-container>
                <ng-container *ngIf="rowData.showEnvInput">
                    <label class="p-mr-2">Env:</label>
                    <p-multiSelect [(ngModel)]="item.env" [options]="ENV_OPTIONS"
                        [style]="{'max-width': '22em', 'width': '100%'}" appendTo="body" [optionValue]="'value'"
                        [optionLabel]="'label'" (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled">
                    </p-multiSelect>
                </ng-container>
            </div>
            <div class="p-d-flex p-ai-center p-ml-4 p-my-1">
                <ng-container *ngIf="rowData.showLinkRef">
                    <label class="p-mr-2">Link Ref:</label>
                    <p-dropdown [(ngModel)]="item.link_ref" [options]="this.apiOptions[this.rowData.key]"
                        [style]="{'width': '100%'}" appendTo="body" [optionValue]="'_id'"
                        [optionLabel]="'linkText'" (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled">
                        <ng-template let-option pTemplate="item">
                           <strong>({{option.id}}) {{option.linkText}}:</strong> {{option.linkDestination}}
                        </ng-template>
                        <ng-template let-option pTemplate="selectedItem">
                            <strong>({{option.id}}) {{option.linkText}}:</strong> {{option.linkDestination}}
                        </ng-template>
                    </p-dropdown>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- singleDup -->
    <div *ngSwitchCase="'singleDup'">
        <div *ngIf="rowData.value" class="p-mb-2">
            <div class="flex align-items-center gap-2">
                <p-checkbox *ngIf="rowData.isInput" class="p-mr-1" [(ngModel)]="rowData.value.selected" [binary]="true"
                    (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled"></p-checkbox>
                <span>{{ rowData.value.name }}</span>
                <a href="/{{rowData?.controller}}/{{rowData.value?.id || rowData.value?._id}}" target="_blank"
                    [ngStyle]="{ color: 'var(--primary-color)' }">
                    ({{rowData.value?.id || rowData.value?._id}})
                </a>
            </div>
            <div class="p-d-flex p-ai-center p-ml-4 p-my-1">
                <ng-container *ngIf="rowData.showNameInput">
                    <label class="p-mr-2">Name:</label>
                    <input type="text" pInputText [(ngModel)]="rowData.value.name" class="p-mr-2"
                        [ngStyle]="{'width': '40%'}" (ngModelChange)="onValueChange(rowData.value)"
                        [disabled]="disabled" />
                </ng-container>
                <ng-container *ngIf="rowData.showDateInputs">
                    <ng-container *ngFor="let field of [
                    {label: 'Start:', model: 'start'},
                    {label: 'End:', model: 'end'}
                    ]">
                        <div class="p-d-flex p-ai-center p-mr-3">
                            <label class="p-mr-2">{{field.label}}</label>
                            <p-calendar *ngIf="rowData.isInput" [(ngModel)]="rowData.value[field.model]"
                                [showTime]="true" [showButtonBar]="true" appendTo="body" class="field-item"
                                (ngModelChange)="onValueChange(rowData.value, {isDate: true})" [disabled]="disabled">
                                <ng-template pTemplate="footer">
                                    <button type="button" pButton label="Reset Time" class="p-button-text"
                                        [style]="{'height': '20px'}"
                                        (click)="resetTimeToMidnight(rowData.value, $event, field.model)"></button>
                                </ng-template>
                            </p-calendar>
                            <span *ngIf="!rowData.isInput">{{ getFormattedDate(rowData.value[field.model]) }}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="p-d-flex p-ai-center p-ml-4 p-my-1">
                <ng-container *ngIf="rowData.showTTLInput">
                    <label class="p-mr-2">TTL:</label>
                    <p-dropdown [options]="TTL_OPTIONS" [(ngModel)]="rowData.value.ttl" [style]="{'width': '150px'}"
                        class="p-mr-2" [disabled]="disabled" appendTo="body" 
                        (ngModelChange)="onValueChange(rowData.value)">
                    </p-dropdown>
                </ng-container>
                <ng-container *ngIf="rowData.showProductId">
                    <label class="p-mr-2">Product ID:</label>
                    <input type="text" pInputText [(ngModel)]="rowData.value.productId" class="p-mr-2"
                        [ngStyle]="{'width': '40%'}" (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled" />
                </ng-container>
                <ng-container *ngIf="rowData.showEnvInput">
                    <label class="p-mr-2">Env:</label>
                    <p-multiSelect [(ngModel)]="rowData.value.env" [options]="ENV_OPTIONS"
                        [style]="{'max-width': '22em', 'width': '100%'}" appendTo="body" [optionValue]="'value'"
                        [optionLabel]="'label'" (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled">
                    </p-multiSelect>
                </ng-container>
            </div>
            <div class="p-d-flex p-ai-center p-ml-4 p-my-1">
                <ng-container *ngIf="rowData.showLinkRef">
                    <label class="p-mr-2">Link Ref:</label>
                    <p-dropdown [(ngModel)]="rowData.value.link_ref" [options]="this.apiOptions[this.rowData.key]"
                        [style]="{'width': '100%'}" appendTo="body" [optionValue]="'_id'"
                        [optionLabel]="'linkText'" (ngModelChange)="onValueChange(rowData.value)" [disabled]="disabled">
                        <ng-template let-option pTemplate="item">
                           <strong>({{option.id}}) {{option.linkText}}:</strong> {{option.linkDestination}}
                        </ng-template>
                        <ng-template let-option pTemplate="selectedItem">
                            <strong>({{option.id}}) {{option.linkText}}:</strong> {{option.linkDestination}}
                        </ng-template>
                    </p-dropdown>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- multiselectEnv -->
    <div *ngSwitchCase="'multiselectEnv'">
        <ng-container *ngIf="rowData.isInput">
            <p-multiSelect [(ngModel)]="rowData.value" [options]="ENV_OPTIONS"
                [style]="{'max-width': '22em', 'width': '100%'}" appendTo="body" [optionValue]="'value'"
                [optionLabel]="'label'" (ngModelChange)="onValueChange($event)" [disabled]="disabled">
            </p-multiSelect>
        </ng-container>
        <ng-container *ngIf="!rowData.isInput">
            {{rowData.value.join(', ')}}
        </ng-container>
    </div>

    <!-- colors -->
    <div *ngSwitchCase="'colors'">
        <div class="color-container">
            <div *ngFor="let color of rowData.value" class="p-flex p-flex-column p-align-items-center">
                <div class="color-circle mb-2"
                    [ngStyle]="{'background-color': color, 'width': '24px', 'height': '24px', 'border-radius': '50%', 'border': '1px solid #333'}">
                </div>
                <span style="font-size: 0.9em">{{color}}</span>
            </div>
        </div>
    </div>

    <!-- simpleDropdown -->
    <div *ngSwitchCase="'simpleDropdown'">
        <ng-container *ngIf="rowData.isInput">
            <div class="p-d-flex p-ai-center">
                <p-dropdown [options]="rowData.options" [(ngModel)]="rowData.value" [style]="{'width': '150px'}"
                    class="p-mr-2" [disabled]="disabled" appendTo="body" (ngModelChange)="onValueChange($event)">
                </p-dropdown>
            </div>
        </ng-container>
        <ng-container *ngIf="!rowData.isInput">
            {{rowData.value}}
        </ng-container>
    </div>

    <!-- booleanInput -->
    <div *ngSwitchCase="'booleanInput'">
        <p-toggleButton [(ngModel)]="rowData.value" [onLabel]="rowData.onLabel" [offLabel]="rowData.offLabel"
            [style]="{'width': '25em'}" (onChange)="onValueChange($event.checked)" [disabled]="disabled"></p-toggleButton>
    </div>

    <!-- Default case -->
    <span *ngSwitchDefault>{{ rowData.value }}</span>
</ng-container>