import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html',
  styleUrls: ['./dropdown-multiselect.component.sass']
})
export class DropdownMultiselectComponent implements OnInit
{
  @Input() selectedOptions: Array<any> = [];
  @Input() optionValues: Array<any> = [];
  @Input() labelName: string;
  @Input() optionLabel: string;
  @Input() optionValue: string;
  @Input() fieldName: string;
  @Input() apiControllerRoute: string;
  @Input() autopopulate: boolean = true;
  @Input() queryLimitValue: number;
  @Input() useSetOptionsFromRef: boolean = true;
  @Input() isArrayOfStringIds: boolean = true;
  @Output() onSelectedOptionsChange = new EventEmitter<any>();
  @Input() options: Array<any> = [];
  isLoading: boolean = true;
  currentTimeout: any;

  constructor
  (
    private utilitiesService: UtilitiesService,
    private dataService: DataService,
  ) { }

  /**
   * Dropdown Multiselect Component Initialization
   */
  async ngOnInit()
  {
    // This will handle cases where it is an array of objects, and the _id of the entities must be obtained.
    // Normally this is when the reference is auto-populated.
    if(!this.isArrayOfStringIds && this.selectedOptions && this.selectedOptions.length > 0)
    {
      let options: Array<any> = [];
      this.selectedOptions.forEach(element =>
      {
        options.push(element._id);
      });
      this.selectedOptions = options;
    }

    if(this.options.length==0){
      if(this.useSetOptionsFromRef)
      {
        await this.setOptions();
      }
      else
      {
        await this.getAllRecordsForType();
      }
    } else {
      console.log('skipping set options, passed down by dynamic form.');
    }


    this.isLoading = false;
  }

  /**
   * Retrieves all records for an entity type
   */
  async getAllRecordsForType()
  {
    this.dataService.getAllOfType(this.apiControllerRoute,
    {
      query: { },
      select: '_id name id',
      virtuals: false,
      autopopulate: false,
      sort: { name: 1 },
      limit: this.queryLimitValue ? this.queryLimitValue: null
    })
    .subscribe((result) =>
    {
      this.options = result;
    });
  }

  /**
   * Set options for dropdowns and auto-completes
   */
  async setOptions()
  {
    this.options = await this.utilitiesService.getOptionsFromRef(this.optionValues, this.fieldName, this.apiControllerRoute, false, this.autopopulate);
  }

  /**
   * Retrives Suggestions for entity reference
   *
   * @param event Event comming from the search input
   */
  async getSuggestionsForRef(event: any)
  {
    if (this.currentTimeout)
    {
      clearTimeout(this.currentTimeout);
    }
    this.currentTimeout = setTimeout(async () =>
    {
      this.dataService.getAllOfType(this.apiControllerRoute,
      {
        query: isNaN(event.filter) ? (event.filter.length > 0 ? { name: { $regex: event.filter, $options: 'i' }} : {}): (event.filter != undefined && event.filter.length > 0 ? { id: event.filter } : {}),
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 },
        limit: this.queryLimitValue ? this.queryLimitValue: null,
        isOptions: true
      })
      .subscribe((result) =>
      {
        this.options = result;
      });
      this.currentTimeout = null;
    }, 500);
  }

  /**
   * Handle selected options change
   */
  onChange()
  {
    this.onSelectedOptionsChange.emit(this.selectedOptions);
  }
}
