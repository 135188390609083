import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BulkEditInputComponent } from './bulk-edit-input/bulk-edit-input.component';
import { AgGridEditService } from '../services/ag-grid-edit.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { MessageService } from 'primeng/api';
import { ItemService } from 'src/app/cms-v2/entities/item/services/item.service';
import TableBulkBuild from '../../components/dynamic-table/dtos/TableBulkBuild';
import { BuildType } from 'src/app/enums/build-type';
import { UtilitiesService } from '../../services/utilities.service';
import { DataService } from 'src/app/services/data.service';
import TableBulkPromotion from '../../components/dynamic-table/dtos/TableBulkPromotion';
import { PromotionService } from 'src/app/services/promotion.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment.service';
import { TableCommunicationService } from '../services/table-communication.service';
import { AgGridToolsService } from '../services/ag-grid-tools.service';
import { ItemSetService } from 'src/app/cms-v2/entities/item-set/item-set-service/item-set.service';
import {v4 as uuidv4} from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChallengeService } from 'src/app/entities/challenge/services/challenge.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-ag-grid-actions',
  templateUrl: './ag-grid-actions.component.html',
  styleUrls: ['./ag-grid-actions.component.sass'],
  providers: [MessageService],
})
export class AgTableActionsComponent implements OnInit {
  @ViewChild('bulkEditInput', { static: false }) bulkEditInput: BulkEditInputComponent; // Adjust the type to your actual component class name

  constructor(
    private sanitizer: DomSanitizer,
    private agGridEditService: AgGridEditService,
    private spinnerService: SpinnerService,
    private commonEntityService: CommonEntityService,
    private messageService: MessageService,
    private itemService: ItemService,
    private utilitiesService: UtilitiesService,
    private dataService: DataService,
    private promotionService: PromotionService,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private tableCommunicationService: TableCommunicationService,
    private agGridToolsService: AgGridToolsService,
    private itemSetService: ItemSetService,
    private spinner: NgxSpinnerService,
    private challengeService: ChallengeService,
    public authService: AuthService,

  ) {
    this.setEnvironmentName();
    this.setEnvironmentsArray();
  }

  display: boolean = false;

  @Input() bulkUpdateColDefs: any[] = [];
  @Input() gridApi: any;
  @Input() columnApi: any;
  @Input() entity: string;
  @Input() mongoQuery: any;
  @Input() mongoSort: any;
  @Input() rowsSelected: any[];
  @Input() areRowsSelected: boolean;
  @Input() selectedRowsSet: Set<any>;
  @Input() isSelectAll: boolean;
  @Input() totalRecords: number;
  @Input() loadedRowCount: number;
  @Input() changedRows: Map<number, any>;
  @Input() buildParams: any;
  @Input() tableParams: any;
  @Input() userParams: any;
  @Input() populateFields: any;
  //
  @Input() fetchAllRowsFromBackend: Function;
  //

  environmentName: string;
  bulkEditState: 'select-column' | 'select-edit-type' | 'edit-values' | 'verify-changes' = 'select-column';
  bulkEditDialogVisible = false;
  selectedBulkEditColumns: any[] = [];
  editType: any;
  submitInProgress: boolean = false;
  isLoading: boolean = false;
  progress: number = 0;

  //  add to collection
  addToCollectionDialogVisible = false;
  createCollectionDialogVisible = false;
  collections: any[] = [];
  selectedCollection: any;
  createCollection: any = {
    name: '',
    items_ref: []
  }
  //  add to item set
  addToItemSetDialogVisible = false;
  createItemSetDialogVisible = false;
  itemSets: any[] = [];
  selectedItemSet: any;
  createItemSet: any = {
    name: '',
    items_ref: []
  };


  // BUILD AND RENDER
  buildAndRender: boolean = false;
  bulkBuild: TableBulkBuild = new TableBulkBuild();
  showBulkBuild: boolean = false;
  showBuildProgress: boolean = false;
  bulkBuildProgress: number = 0;
  disableBuildButton: boolean = false;
  showBulkBuildFailed: boolean = false;

  // BULK PROMOTE
  bulkPromote: TableBulkPromotion = new TableBulkPromotion();
  showBulkPromote: boolean = false;
  showBulkPromoteFailed: boolean = false;
  isAssetPromotion: boolean = false;
  disablePromoteButton: boolean = false;
  showPromoteProgress: boolean = false;
  bulkPromoteProgress: number;
  environments: string[] = [];
  rows: any[] = []; // Ensure this is initialized

  differencesModal: boolean = false;
  differences: { entity: any, records: any };
  differencesLoaded: boolean = false;

  // percentageChange
  tmpInputValue: any;

  // Render DIFF
  displayRenderDiffDialog: boolean = false;
  diffCheckType: 'simple' | 'user-submission' = 'simple';
  diffCheckRef: string = '';


  allEditTypes: any[] = [
    {
      value: 'overwrite',
      label: 'Overwrite',
      icon: 'fa-clipboard-check',
      description: 'Replaces the existing value with a new one.',
      allowedTypes: ['text', 'long-text', 'rich-text', 'dropdown', 'multiselect', 'date', 'number', 'boolean', 'line-items']
    },
    {
      value: 'line-item-resource',
      label: 'Replace Line Item (Prize, Cost) Resource',
      icon: 'fa-r',
      description: 'Replaces line items Resource with a another resource and maintains original count value.',
      allowedTypes: ['line-items']
    },
    {
      value: 'line-item-count',
      label: 'Replace Line Item (Prize, Cost) Count Value',
      icon: 'fa-plus-minus',
      description: 'Replaces line items Count value and maintains the original type.',
      allowedTypes: ['line-items']
    },
    {
      value: 'clear',
      label: 'Clear',
      icon: 'fa-eraser',
      description: 'Clears the existing value.',
      allowedTypes: ['text', 'long-text', 'rich-text', 'dropdown', 'multiselect', 'date', 'number', 'line-items']
    },
    {
      value: 'find-and-replace',
      label: 'Find and Replace',
      icon: 'fa-right-left',
      description: 'Finds a specific string and replaces it with another. (Leave replacement empty to remove a value)',
      allowedTypes: ['text', 'long-text', 'rich-text']
    },
    {
      value: 'append',
      label: 'Append',
      icon: 'fa-right-to-bracket',
      description: 'Appends a specific value to the end of a field\'s existing value.',
      allowedTypes: ['text', 'long-text', 'rich-text']
    },
    {
      value: 'add',
      label: 'Add',
      icon: 'fa-right-to-bracket',
      description: 'Add a specific value to the end of a field\'s existing value.',
      allowedTypes: ['multiselect']
    },
    {
      value: 'prepend',
      label: 'Prepend',
      icon: 'fa-right-to-bracket fa-rotate-180',
      description: 'Appends a specific value to the beginning of a field\'s existing value.',
      allowedTypes: ['text', 'long-text', 'rich-text']
    },
    ,
    {
      value: 'percentageChange',
      label: 'Cost Percentage Change',
      icon: 'fa-percent',
      description: 'Increases or Decreases Costs by a Percentage',
      allowedTypes: ['line-items']
    }
  ];

  // STORE
  private submitAddToCart: Subscription;

  get editTypes() {
    if (!this.selectedBulkEditColumns || this.selectedBulkEditColumns.length === 0) {
      return [];
    }
    // If multiple columns are selected, we'll use the type of the first one as a reference
    const selectedColumnType = this.selectedBulkEditColumns[0].type;

    return this.allEditTypes.filter(type => type.allowedTypes.includes(selectedColumnType));
  }

  get selectedRows() {
    return this.rowsSelected.map(rowNode => {
      return rowNode.data;
    });
  }

  /**
   * Asynchronously fetches the selected rows based on the current state of row selection.
   *
   * This function checks the `isSelectAll` property and the total number of records to decide
   * whether it should fetch all rows from the backend or just use the currently loaded rows.
   *
   * @param {string} select - Specifies which fields to include in the results, defaults to '_id id name'.
   *                          It is a string with space-separated field names.
   *
   * @param {any} smartPopulate - An object containing advanced options for populating related fields,
   *                              defaults to an empty object.
   *
   * @returns {Promise<any[]>} A Promise that resolves with an array of the selected records.
   *
   * @async
   * @example
   *
   * const selectedData = await getSelectedRows('_id name age', { populate: 'address' });
   */
  async getSelectedRows(select: string = '_id id name', populate: any = null) {
    // console.log('Is Select All: ', this.isSelectAll, ' | Loaded Rows: ', this.loadedRowCount, '| Total Records: ', this.totalRecords);

    if (this.isSelectAll && this.loadedRowCount !== this.totalRecords) {
      // If 'Select All' is checked and not all rows are loaded,
      // fetch all rows from the backend.
      return await this.fetchAllRowsFromBackend(select, null, {populate: this.populateFields}, this.isSelectAll);
    } else {
      // Otherwise, return the selected rows from the loaded data
        console.log('>>> discrepancy between selectedRowCount and rowsSelected <<<');
        console.log(this.selectedRowsSet.size, this.rowsSelected.length);
        return await this.fetchAllRowsFromBackend(select, this.selectedRowsSet, {populate: this.populateFields});
    }
  }

  public changesPreview: {
    [id: string]: {
      selected: unknown; original: any, new: any, selectedColumn: string
    }
  } = {};

  ngOnInit(): void {
    this.rowsSelected = [];

    this.submitAddToCart = this.tableCommunicationService.addToCart$.subscribe((type: string) => {
      this.addToCart(type);
    });
  }

  setEnvironmentName(): void {
    this.environmentName = EnvironmentService.getEnvironmentName();
  }

  setEnvironmentsArray(): void {
    switch (this.environmentName) {
      case 'production':
        this.environments = ['dev', 'qa', 'prod'];
        break;
      case 'test':
        this.environments = ['test'];
        break;
      case 'development':
        this.environments = ['dev', 'test', 'qa', 'prod'];
        break;
      default:
        this.environments = ['dev', 'test', 'qa', 'prod'];
        break;
    }
  }

  show(): void {
    this.display = true;
  }

  hide(): void {
    this.display = false;
  }

  exportToCSV(type: string) {
    this.ngZone.runOutsideAngular(() => {
      this.spinnerService.loadSpinner();
    });

    let columnsToExport = [];
    const allColumns = this.columnApi.getAllGridColumns(); // Fetch all column definitions
    const getColumnDetails = (colId: any) => {
      const column = allColumns.find((col: any) => col.getColId() === colId);
      return column ? {
        colId: column.getColId(),
        colName: column.getUserProvidedColDef().headerName
      } : null;
    };

    switch (type) {
      case 'current':
        // Exporting only currently selected columns
        let currentDisplayedColumns = this.columnApi.getAllDisplayedColumns();
        columnsToExport = currentDisplayedColumns.map((col: any) => ({
          colId: col.getColId(),
          colName: col.getUserProvidedColDef().headerName
        })).filter((colObj: any) => colObj.colId !== 'actions');
        break;
      case 'organic':
        const organicColumns = [
          'id', 'thumbnail', 'fileName', 'name', 'cultivar', 'batch_ref', 'climates_ref',
          'category_ref', 'start', 'vendorStatus', 'itemStatus', 'costs_ref',
          'enabled', 'buildStatus', 'imageBuildStatus',
        ];
        columnsToExport = organicColumns.map(getColumnDetails).filter(colObj => colObj);
        break;
      case 'hard-surface':
        const hardSurfaceColumns = [
          'id', 'thumbnail', 'fileName', 'name', 'category_ref', 'start', 'vendorStatus', 'itemStatus', 'costs_ref',
          'enabled', 'buildStatus', 'imageBuildStatus', 'contentHold_ref', 'styles_ref', 'colors_ref',
        ];
        columnsToExport = hardSurfaceColumns.map(getColumnDetails).filter(colObj => colObj);
        break;
      default:
        const defaultColumns = ['id', 'name', 'enabled', 'start', 'end', 'env'];
        columnsToExport = defaultColumns.map(getColumnDetails).filter(colObj => colObj);
        break;
    }

    // Construct the request payload
    const payload = {
      query: this.mongoQuery,
      sort: this.mongoSort,
      columns: columnsToExport
    };

    // Make an HTTP POST request
    this.httpClient.post(`api/${this.entity}/csv`, payload, { responseType: 'text' })
      .subscribe({
        next: (csv) => {
          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          }).replace(/\//g, '-');

          // Construct the file name
          const fileName = `${this.entity}-${formattedDate}.csv`;

          // Trigger file download
          const blob = new Blob([csv], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          this.spinnerService.endSpinner();
        },
        error: (error) => {
          this.spinnerService.endSpinner();

          if (error.status === 403) {
            let errorMessage = error?.error?.error;

            if (typeof error?.error === 'string') {
              try {
                const errorJson = JSON.parse(error?.error);
                errorMessage = errorJson.error;
              } catch (_) {}
            }

            this.messageService.add({
              sticky: false,
              severity: 'error',
              summary: `ERROR exporting`,
              detail: errorMessage || error.statusText,
            });
          } else {
            alert('Error downloading CSV');
          }
          console.error('Error downloading CSV:', error);
        }
        // Optionally, you can add a complete callback if needed
      });

  }

  async toggleAddItemsToCollection(type: string) {
    await this.getAllCollections();
    this.addToCollectionDialogVisible = true;
  }

  async toggleAddItemsToItemSet() {
    await this.getAllItemSets();
    this.addToItemSetDialogVisible = true;
  }


  renderPreview() {
    // Logic for rendering a preview
    console.log('Rendering preview');
  }

  buildProject() {
    // Logic for building a project
    console.log('Building project');
  }

  promoteNow(type: string) {
    // Logic for promotion
    console.log('Promoting');
  }

  // BULK EDITING
  toggleBulkEdit() {
    this.bulkEditDialogVisible = true;
  }

  goNext() {
    switch (this.bulkEditState) {
      case 'select-column':
        // If no column is selected, alert the user
        if (!this.selectedBulkEditColumns || this.selectedBulkEditColumns.length === 0) {
          alert('Please select a column to bulk edit.');
          return;
        }
        // Move to the 'select-edit-type' state
        this.bulkEditState = 'select-edit-type';
        break;

      case 'select-edit-type':
        if (!this.editType) {
          alert('Please select an edit type to continue.');
          return;
        }
        this.bulkEditState = 'edit-values';
        break;

      case 'edit-values':
        this.createChangesPreview();
        this.bulkEditState = 'verify-changes';
        break;

      default:
        // You can optionally handle any unexpected cases here
        console.error('Unexpected bulkEditState:', this.bulkEditState);
        break;
    }
  }

  goBack() {
    switch (this.bulkEditState) {
      case 'select-column':
        alert('Cannot go back');
        break;
      case 'select-edit-type':
        this.bulkEditState = 'select-column';
        break;
      case 'edit-values':
        this.bulkEditState = 'select-edit-type';
        break;
      case 'verify-changes':
        this.bulkEditState = 'edit-values';
        break;
      default:
        this.bulkEditState = 'select-column';
        break;
    }
  }

  cancelBulkEdit() {
    this.bulkEditDialogVisible = false;
    this.bulkEditState = 'select-column';
    this.selectedBulkEditColumns = [];
    this.editType = null;
  }

  getSelectedColumn(): any {
    return this.selectedBulkEditColumns[0];
  }

  getSelectedColumnDef(): any {
    let col = this.getSelectedColumn();
    return this.getColumnDefinitionById(col.id);
  }

  getColumnDefinitionById(columnId: string): any {
    const column = this.columnApi.getColumn(columnId);
    return column ? column.getColDef() : null;
  }

  updateSelectedRows(): void {
    this.rowsSelected = Object.keys(this.changesPreview)
      .filter(key => this.changesPreview[key].selected)
      .map(key => key);
    this.areRowsSelected = this.rowsSelected.length > 0;
  }


  removeSelectedRows(): void {
    this.rowsSelected.forEach(selectedId => {
      if (this.changesPreview[selectedId]) {
        delete this.changesPreview[selectedId];
      }
    });
    this.updateSelectedRows();
  }



  getOriginalValue(value: any, columnType: string): any {

    console.log('value: ', value);
    console.log('columnType: ', columnType);

    if (value !== null && value !== undefined) {
      return value;
    }

    switch (columnType) {
      case 'text':
      case 'long-text':
      case 'rich-text':
        return '';
      case 'dropdown':
      case 'date':
        return null;
      case 'multiselect':
        return [];
      default:
        return value;
    }
  }

  async createChangesPreview() {
    this.isLoading = true;
    this.spinner.show();

    try {
    this.changesPreview = {};  // Clear any previous data
    const inputValue = this.bulkEditInput.getValue();
    this.tmpInputValue = inputValue;
    let selectedColumn = this.getSelectedColumn();

    // console.log('selectedColumn: ', selectedColumn);
    // console.log('inputValue: ', inputValue);
    // console.log('editType: ', this.editType);
    // console.log('changedRows: ', this.changedRows);
    // console.log('entity: ', this.entity);
    // console.log('gridApi: ', this.gridApi);


    let selectionParams = this.getSelectionParams(selectedColumn);

    // Get the selected rows using the new function
    const selectedRows = await this.getSelectedRows(selectionParams.select);

    selectedRows.forEach((row: any) => {
      const rowData = row;
      const id = row.id;
      let originalValue = this.getOriginalValue(rowData[selectedColumn.id], selectedColumn.type);
      let newValue;

      switch (this.editType.value) {
        case 'overwrite':
          if ('date' == selectedColumn.type) {
            newValue = new Date(inputValue).toISOString();
          } else if ('line-items' == selectedColumn.type) {
            // for now no conversions needed..
            newValue = inputValue;
          } else {
            newValue = inputValue;
          }
          break;

        case 'clear':
          newValue = Array.isArray(originalValue) ? [] : '';
          break;

        case 'find-and-replace':
          if (['text', 'long-text', 'rich-text'].includes(selectedColumn.type) && inputValue.find && inputValue.replace) {
            newValue = originalValue.replace(inputValue.find, inputValue.replace);
          } else {
            newValue = originalValue; // defaulting to original if no appropriate find/replace values are provided or if the originalValue is not a string
          }
          break;

        case 'append':
          if (['text', 'long-text', 'rich-text'].includes(selectedColumn.type)) {
            newValue = originalValue + inputValue;
          } else if (Array.isArray(originalValue)) {
            newValue = [...originalValue, inputValue];
          } else {
            newValue = originalValue;
          }
          break;
        case 'add':

          if (['multiselect'].includes(selectedColumn.type) && Array.isArray(originalValue) && Array.isArray(inputValue)) {
            console.log('Adding to array with unique items');

            // Combine the arrays
            let combinedArray = [...originalValue, ...inputValue];

            // Create a new array for the unique items
            let uniqueItems = [];

            // Use a Set for unique primitives and a Map for unique objects
            let uniquePrimitives = new Set();
            let uniqueObjects = new Map();

            // Loop through the combined array to populate the Set and Map
            combinedArray.forEach(item => {
              if (typeof item === 'object' && item !== null && '_id' in item) {
                // For objects, use a map keyed by _id to ensure uniqueness
                uniqueObjects.set(item._id, item);
              } else {
                // For primitives, use a Set to ensure uniqueness
                uniquePrimitives.add(item);
              }
            });

            // Combine the unique primitives and the unique objects into the uniqueItems array
            uniqueItems = Array.from(uniquePrimitives);
            uniqueItems.push(...Array.from(uniqueObjects.values()));

            newValue = uniqueItems;
            console.log('After unique newValue: ', newValue);
          } else {
            newValue = originalValue; // Fallback if not multiselect type or if values are not arrays
          }

          break;

        case 'prepend':
          if (['text', 'long-text', 'rich-text'].includes(selectedColumn.type)) {
            newValue = inputValue + originalValue;
          } else if (Array.isArray(originalValue)) {
            newValue = [inputValue, ...originalValue];
          } else {
            newValue = originalValue;
          }
          break;

        case 'line-item-resource':
          newValue = originalValue.map((item: any) => {
            return {
              id: inputValue.id ? inputValue.id : null,
              t: inputValue.t ? inputValue.t : null,
              lineItemType: inputValue.lineItemType,
              c: item.c
            };
          });
          break;

        case 'line-item-count':
          // Use map to create a new array (newValue) with the updated field
          newValue = originalValue.map((item: any) => {
            // Update the 'count' field
            const updatedCount = inputValue;
            // Return the object with the updated 'count' field
            return {
              ...item, // Spread the existing properties of the object
              c: updatedCount, // Update the 'count' field
            };
          });
          break;

        case 'percentageChange':

          // Use map to create a new array (newValue) with the updated field
          newValue = originalValue.map((item: any) => {
            // Calculate the percentage change
            const percentageChange = item.c * (inputValue.value / 100);

            // Update the 'count' field based on the direction
            let updatedCount = inputValue.direction === 'increase'
              ? item.c + percentageChange
              : item.c - percentageChange;

            // Apply custom rounding
            updatedCount = this.customRound(updatedCount);

            // Return the object with the updated 'count' field
            return {
              ...item, // Spread the existing properties of the object
              c: updatedCount, // Update the 'count' field
            };
          });


          break;

        default:
          newValue = originalValue;
          break;
      }

      this.changesPreview[id] = {
        original: originalValue,
        new: newValue,
        selectedColumn: selectedColumn.id,
        selected: false,
      };
    });

  } catch (error) {
    console.error('Error loading changes preview:', error);
  } finally {
    this.isLoading = false;
    this.spinner.hide();
  }
    // console.log('changesPreview: ', this.changesPreview);
  }

  customRound(value: number) {
    // Find the nearest 10th
    const nearestTenth = Math.floor(value / 10) * 10;

    // Calculate the remainder
    const remainder = value % 10;

    if (remainder < 5) {
      // If the remainder is less than 5, round down to the nearest lower 10th
      return nearestTenth;
    } else {
      // If the remainder is 5 or more, round up to the nearest upper 10th
      return nearestTenth + 10;
    }
  }

  getSelectionParams(selectedColumn: any) {
    const defaultFields = ['_id', 'id', 'name'];
    let select: string;
    let smartPopulate: any = {};

    // Remove duplicates by converting the array to a Set and back to an array
    const uniqueDefaultFields = [...new Set([...defaultFields, selectedColumn.id])];

    // Create the select string
    select = uniqueDefaultFields.join(' ');

    // Check if smartPopulate should be enabled
    if (selectedColumn.smartPopulate) {
      // Create an initial Set with default fields for unique entry
      const smartFieldsSet = new Set(defaultFields);

      // Add optionLabel and optionValue if they are defined
      if (selectedColumn.optionLabel) {
        smartFieldsSet.add(selectedColumn.optionLabel);
      }
      if (selectedColumn.optionValue) {
        smartFieldsSet.add(selectedColumn.optionValue);
      }

      // Convert Set back to array and join it to form a string
      const smartFieldsArray = Array.from(smartFieldsSet);
      smartPopulate[selectedColumn.id] = smartFieldsArray.join(' ');
    }

    return { select, smartPopulate };
  }

  get changeKeys() {
    return this.changesPreview ? Object.keys(this.changesPreview) : [];
  }


    async submitBulkEdit() {
        const selectedColumn = this.getSelectedColumn();
        const changesPreview = this.changesPreview;
        this.submitInProgress = true;
        this.progress = 0; // Initialize progress

        try {
            const batchSize = 100; // Smaller batch size for smoother progress
            const maxRetries = 3;
            const keys = Object.keys(changesPreview); // Get all the keys
            const totalBatches = Math.ceil(keys.length / batchSize); // Total number of batches

            for (let i = 0; i < totalBatches; i++) {
                const batchKeys = keys.slice(i * batchSize, (i + 1) * batchSize);
                const batch = this.getBatchFromKeys(changesPreview, batchKeys);
                await this.retrySubmit(batch, selectedColumn, maxRetries);
                this.updateProgressBar((i + 1) / totalBatches * 100); // Update progress with even numbers
            }

            this.cancelBulkEdit();
            this.display = false;
        } catch (error) {
            console.log(error);
        } finally {
            this.submitInProgress = false;
            this.progress = 0; // Reset progress
        }
    }

    private getBatchFromKeys(changesPreview: any, keys: string[]): any {
        const batch: {[key: string]: any} = {};
        for (const key of keys) {
            batch[key] = changesPreview[key];
        }
        return batch;
    }

    private async retrySubmit(batch: any, selectedColumn: any, retries: number): Promise<void> {
        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                await this.agGridEditService.submitBulkEdit(this.gridApi, batch, selectedColumn, this.changedRows, this.entity);
                return;
            } catch (error) {
                if (attempt === retries) throw error;
                console.log(`Retry ${attempt} failed, retrying...`);
            }
        }
    }

    private updateProgressBar(value: number) {
        // Round the progress to the nearest even number for better display
        this.progress = Math.round(value / 2) * 2;
    }

  getSafeHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  formatValue(value: any): any {
    let selectedColumn = this.getSelectedColumn();
    switch (selectedColumn.type) {
      case 'date':
        if (!value) return '';
        let date = new Date(value);
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      case 'boolean':
        return value ? selectedColumn.onLabel : selectedColumn.offLabel;
      case 'dropdown':
        if (selectedColumn.isFlatList) {
          return value && value.label ? value.label : value;
        } else {
          return value && value.name && value.id ? `${value.name} (${value.id})` : value;
        }
      default:
        return value;
    }
  }
  async getAllItemSets() {
    if (this.itemSets.length > 1) {
      return;
    } else {
      let result = await this.commonEntityService.findAllWithQuery('item-sets', { query: {}, select: 'name id _id', autopopulate: false, virtuals: false, sort: { id: -1 } });
      if (result) {
        this.itemSets = result;
      }
    }
  }


  // Add To Collections Functionality
  async getAllCollections() {
    let result;
    if (this.collections.length > 1) {
      return;
    } else {
      if(this.entity == 'challenges') {
        result = await this.commonEntityService.findAllWithQuery('challenge-collections', { query: {}, select: 'name id _id', autopopulate: false, virtuals: false, sort: { id: -1 } });
      } else {
        result = await this.commonEntityService.findAllWithQuery('collections', { query: {}, select: 'name id _id', autopopulate: false, virtuals: false, sort: { id: -1 } });
      }

      if (result) {
        this.collections = result;
      }
    }
  }

  /**
   * Upload Sourcing Items to collection
   */
  async addToCollection(type: string) {
    this.spinnerService.loadSpinner();

    try {
      const itemsToAdd = this.rowsSelected.map(rowNode => ({
        id: rowNode.data.id,
        _id: rowNode.data._id,
      }));

      let result;
      // console.log('Collection: ', this.selectedCollection);
      // console.log('Items: ', itemsToAdd);
      if (this.entity == 'challenges') {
        result = await this.challengeService.uploadToCollection({ collectionId: this.selectedCollection, sourcingItems: itemsToAdd, existingItem: false });
      } else {
        result = await this.itemService.uploadToCollection({ collectionId: this.selectedCollection, sourcingItems: itemsToAdd, existingItem: false });
      }

      if (result) {
        if (result.Success) {
          this.addToCollectionDialogVisible = true;
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: `Uploaded ${this.entity} were successfully uploaded to collection.`,
          });
        } else {
          this.messageService.add ({
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: 'No Collection Selected.',
          });
        }
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        });
      }
    } catch (error: any) {
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error.error || error);
      this.showMessage('error', 'API Error', errorMessage);
    }

    this.spinnerService.endSpinner();
    this.addToCollectionDialogVisible = false
  }

  /**
   * Upload Sourcing Items to collection
   */
  async createAndAddToCollection() {
    try {
      this.spinnerService.loadSpinner();
      const itemsToAdd = this.rowsSelected.map(rowNode => ({
        id: rowNode.data.id,
        _id: rowNode.data._id,
      }));

      let result;

      if (this.entity == 'challenges') {
        this.createCollection.challenges_ref = itemsToAdd;
        result = await this.challengeService.createCollection({ data: this.createCollection });
      } else {
        this.createCollection.items_ref = itemsToAdd;
        result = await this.itemService.createCollection({ data: this.createCollection });
      }

      if (result) {
        if (result.success) {
          this.addToCollectionDialogVisible = false;
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created a collection and added the items.',
          });
        } else {
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: 'No Collection Created.',
          });
        }
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        });
      }
    } catch (error: any) {
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error.error || error);
      this.showMessage('error', 'Conflict Error', errorMessage);
      console.error('Error creating collection:', error);
    } finally {
      this.spinnerService.endSpinner();
      this.createCollectionDialogVisible = false;
      this.addToCollectionDialogVisible = false;
    }
  }

  showMessage(severity: string, summary: string, detail: string) {
    this.messageService.add({
      sticky: true,
      severity,
      summary,
      detail
    });
  }

  // BULK BUILD
  /**
   * Pre load need data for bulk build
   *
   * @param array List fo entities to build
   * @param type Entity Type
   * @param assetType Asset type
   */
  async onStartBulkBuild(isAssetBuild: boolean) {
    this.bulkBuildProgress = 0;

    let isSelf = false;
    let smartPopulate = {};
    let select = '_id id name';

    if (!this.buildParams) {
      return;
    }

    if (isAssetBuild && this.buildParams.assetKeys.includes('SELF')) {
      isSelf = true;
      select = 'id _id path entityId entityType assetType buildData assetBuildOutput';
    } else if (!isAssetBuild && this.buildParams.imageKeys.includes('SELF')) {
      isSelf = true;
      select = 'id _id path entityId entityType assetType buildData assetBuildOutput imageBuildOutput';
    }

    let populate;
    if (isAssetBuild && this.buildParams.assetPopulate) {
      populate = [this.buildParams.assetPopulate];
    } else if (!isAssetBuild && this.buildParams.imagePopulate) {
      populate = [this.buildParams.imagePopulate];
    }

    // Get the selected rows using the new function
    const selectedRows = await this.getSelectedRows(select, populate);

    const recordsToBuild = selectedRows.map((row: any) => {
      // Initialize an empty array to hold the build job data
      let buildJobData: any[] = [];

      // Decide which keys to use based on the value of isAssetBuild
      const keys = isAssetBuild ? this.buildParams.assetKeys : this.buildParams.imageKeys;

      if (isSelf) {
        let assetData = row;
        if (assetData && assetData.path) { // Check if obj and obj.path exist
          buildJobData.push({
            path: assetData.path,
            record: assetData
          });
        }
      } else {
        // For each key, extract the path attribute from the object it points to
        keys.forEach((key: any) => {
          let assetData = row[key];
          if (assetData && assetData.path) { // Check if obj and obj.path exist
            buildJobData.push({
              path: assetData.path,
              record: assetData
            });
          }
        });
      }

      return {
        id: row.id,
        _id: row._id,
        name: row.name,
        buildJobData: buildJobData,
        parentRecord: row
      };
    });

    this.bulkBuild.rows = recordsToBuild;
    this.showBulkBuild = true;
  }

  async buildMiscAsset(newHash: boolean = false) {
    try {
      console.log(this.buildParams)
      console.log('will build: ', this.bulkBuild);
      console.log('New Hash: ', newHash);
  
      //
      this.disableBuildButton = true;
      this.bulkBuild.successCount = 0;
      this.bulkBuild.failedPayloads = [];
      this.showBuildProgress = true;
      //
      let bulkBuildId = this.utilitiesService.createGuid();
      this.bulkBuildProgress = 0;
      let count = 0;
      //
      await this.dataService.bulkNotification(this.userParams.user.email, bulkBuildId);
      //
  
      /**
      * SENDING JOBS TO QUEUE
      * LOOPING THROUGH buildJobRow.buildJobData since some entities have multiple build keys (assetKeys, imageKeys)
      * Example: currencies, loading screens, garden pass, endless offers.. anything with multiple misc.asset refs
      * */
      for (const buildJobRow of this.bulkBuild.rows) {
        if (buildJobRow) {
          for (const buildJobDataItem of buildJobRow.buildJobData) {
            await this.sentToBuildQueue(
              buildJobDataItem.record,
              buildJobDataItem.path,
              buildJobDataItem.record.entityType,
              bulkBuildId,
              buildJobRow.parentRecord,
              newHash
            );
          }
        } else {
          // Assuming you want to add each failed payload for each 'buildJobData'
          // You might need to adjust this part according to your specific requirements
          for (const buildJobDataItem of buildJobRow.buildJobData) {
            this.bulkBuild.failedPayloads.push({
              entityId: buildJobRow.id,
              path: buildJobDataItem.path,
              user: this.userParams.user
            });
          }
        }
  
        count += 1;
        this.bulkBuildProgress = Number(
          ((count / this.bulkBuild.rows.length) * 100).toFixed(2)
        );
      }
  
      //
  
      if (this.bulkBuild.failedPayloads.length == 0) {
        this.messageService.add({
          severity: 'success',
          summary: 'All Builds Queued',
          detail: `${this.bulkBuild.successCount} items sent to build queue.`,
          life: 100
        });
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'warn',
          summary: 'Error Queuing Builds',
          detail: `${this.bulkBuild.successCount} items sent to build queue & ${this.bulkBuild.failedPayloads.length} errors.`,
        });
      }
      this.showBulkBuild = false;
      this.disableBuildButton = false;
      this.showBuildProgress = false;
    } catch (error: any) {
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error);
      this.showMessage('error', 'API Error', errorMessage);
      this.showBulkBuild = false;
      this.disableBuildButton = false;
      this.showBuildProgress = false;
    }
  }

  async requestBulkAssetStatusCheck(type: 'render-diff' | 'promotionStatus') {
    let select = '_id id name';
    let jobId = uuidv4();
    if (this.entity === 'items') {
      select += ' prefab_ref';
    } else if (this.entity === 'challenges') {
      select += ' scene_ref';
    }
    const selectedRows = await this.getSelectedRows(select);
    console.log('selectedRows: ', selectedRows);
    try {
      this.spinnerService.loadSpinner();
      for (const row of selectedRows) {
        let payload = {
          entity: {
            id: row.id,
            _id: row._id,
            name: row.name,
            miscAsset_ref: this.entity === 'items' ? row.prefab_ref._id : this.entity === 'challenges' ? row.scene_ref._id : null,
          },
          buildType: this.entity === 'items' ? BuildType.Items : this.entity === 'challenges' ? BuildType.Levels : null,
          slackUserId: null,
          jobType: type,
          jobId: jobId,
          diffType: type == 'render-diff' ? this.diffCheckType : null,
          diffRef: type == 'render-diff' ? this.diffCheckRef : null,
        };
        await this.dataService.insertRenderDiffJob(payload);
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Render Diff Job Queued',
        detail: `${selectedRows.length} items sent to render diff queue.`,
        life: 1400
      });
      this.spinnerService.endSpinner();
    } catch (error: any) {
      console.error('Error occurred while inserting render diff job:', error);
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : 'An error occurred while inserting render diff job.';
      this.messageService.add({
        severity: 'error',
        summary: 'Error Queuing Render Diff Job',
        detail: errorMessage,
        life: 1400
      });
      this.spinnerService.endSpinner();
    }

    this.displayRenderDiffDialog = false;
    this.diffCheckRef = '';
  }

  /**
   * Send the build job request to build queue
   */
  async sentToBuildQueue
    (
      entity: any,
      path: string,
      buildQueueType: BuildType,
      bulkBuildId: any,
      parentRecord: any = null,
      newHash: boolean = false,
    ) {
    interface userData {
      [key: string]: any;
    }
    // populating userPayload with current users's data
    let userPayload: userData = {};
    userPayload.email = this.userParams.user.email;
    userPayload.name = this.userParams.user.name;
    userPayload.id = this.userParams.user.id;

    var payload =
    {
      path: path,
      recursive: true,
      buildType: buildQueueType,
      entityType: entity.entityType,
      assetType: entity.assetType,
      entityId: entity.id,
      user: userPayload,
      isPriority: null,
      bulkBuildId: bulkBuildId,
      isFirstBuild: (entity.buildData && entity.buildData.length > 0) || (entity.assetBuildOutput && Object.keys(entity.assetBuildOutput).length > 0) ? false : true,
      renderAsset: this.buildAndRender,
      parentRecordId: parentRecord ? parentRecord.id : null,
      entity: entity,
      newHash: newHash
    };

    // console.log('payload from build button', payload);
    if (
      payload.path &&
      payload.path.length > 0 &&
      // payload.user &&
      payload.entityId
    ) {
      let response = await this.dataService.sendToBuildQueue(
        buildQueueType,
        payload,
        false
      );
      if (response.Success) {
        this.bulkBuild.successCount++;
      } else {
        this.bulkBuild.failedPayloads.push(payload);
      }
    } else {
      console.log(payload.path);
      this.bulkBuild.failedPayloads.push(payload);
    }

    return true;
  }

  /**
  * Display failed payloads
  */
  onShowFailedPayloads() {
    this.showBulkBuildFailed = true;
    this.display = false;
    console.log(this.bulkBuild.failedPayloads);
  }

  // BULK PROMOTION

  /**
   * Show the Confirmation Bulk Promotion Dialog,
   * and sets the selected rows.
   *
   * @param selectedRows Rows selected by the user.
   * @param isAssetPromotion Flag that sets whether or not is an asset promotion.
   */
  async startBulkPromotion(isAssetPromotion: boolean) {
    let isSelf = false;

    if (isAssetPromotion && this.buildParams.assetKeys.includes('SELF')) {
      isSelf = true;
    } else if (!isAssetPromotion && this.buildParams.imageKeys.includes('SELF')) {
      isSelf = true;
    }

    let smartPopulate = {};
    let select = '_id id name itemStatus vendorStatus start end flagged';
    if (isSelf) {
      select = 'id _id path entityId entityType assetType buildData assetBuildOutput';
    } else if (isAssetPromotion && this.buildParams.assetSmartPopulate) {
      smartPopulate = this.buildParams.assetSmartPopulate;
    } else if (!isAssetPromotion && this.buildParams.imageSmartPopulate) {
      smartPopulate = this.buildParams.imageSmartPopulate;
    }

    // Get the selected rows using the new function
    const selectedRows = await this.getSelectedRows(select);

    const rowsToPromote = selectedRows.map((row: any) => {
      // Initialize an empty array to hold the build job data
      let buildJobData: any[] = [];

      if (isSelf) {
        const assetData = row;
        if (assetData && assetData.path) { // Check if obj and obj.path exist
          buildJobData.push({
            path: assetData.path,
            record: assetData
          });
        }
      } else {
        // Decide which keys to use based on the value of isAssetBuild
        const keys = isAssetPromotion ? this.buildParams.assetKeys : this.buildParams.imageKeys;

        // For each key, extract the path attribute from the object it points to
        keys.forEach((key: any) => {
          const assetData = row[key];
          if (assetData && assetData.path) { // Check if obj and obj.path exist
            buildJobData.push({
              path: assetData.path,
              record: assetData
            });
          }
        });
      }

      return {
        id: row.id,
        _id: row._id,
        name: row.name,
        buildJobData: buildJobData,
        parentRecord: row
      };

    });

    this.bulkPromote.rows = rowsToPromote;
    this.showBulkPromote = true;
    this.isAssetPromotion = isAssetPromotion;
    console.log(this.bulkPromote)

  }

  /**
   * Handles the bulk promotion of assets or images.
   *
   * @param {boolean} isAssetPromotion - Flag to indicate if the promotion is for assets (true) or images (false).
   * @param {boolean} newHash - Flag to indicate whether to create a new hash during promotion.
   * @async
   */
  async onBulkPromotion(isAssetPromotion: boolean = false, newHash: boolean = false) {
    try {
      // Initialize variables
      this.initializeBulkPromotionState();
  
      let count = 0;
  
      // Loop through each selected row to be promoted
      for (const buildJobRow of this.bulkPromote.rows) {
        let isValid = this.validateAssetPromotion(buildJobRow.parentRecord);
        // Check validation
        if (isValid) {
          // Process each build job data item for the row
          await this.processBuildJobData(buildJobRow.buildJobData, isAssetPromotion, newHash);
        } else {
          this.addFailedPayload('Safety gating validation failed.');
        }
        // Update progress
        this.updateProgress(++count, this.bulkPromote.rows.length);
      }
  
      // Show promotion results
      this.showPromotionResults(isAssetPromotion);
    } catch (error: any) {
      this.showBulkPromote = false;
      this.disablePromoteButton = false;
      this.showPromoteProgress = false;
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error);
      this.messageService.add({ severity: 'error', summary: 'Error promoting!', detail: errorMessage });
    }
  }

  // Initialize the state variables for bulk promotion
  private initializeBulkPromotionState() {
    this.bulkPromoteProgress = 0;
    this.bulkPromote.successCount = 0;
    this.bulkPromote.failedPayloads = [];
    this.disablePromoteButton = true;
    this.showPromoteProgress = true;
  }

  // Process build job data items
  private async processBuildJobData(buildJobDataItems: any[], isAssetPromotion: boolean, newHash: boolean) {
    for (const buildJobDataItem of buildJobDataItems) {
      const buildType = isAssetPromotion ? buildJobDataItem.record.entityType : BuildType.Images;

      const response = await this.promotionService.onPromoteRecord(
        buildType,
        {
          from: 'dev',
          to: 'prod',
          entity: buildJobDataItem.record,
          buildType: buildType,
          newHash: newHash
        },
        false,
        this.entity
      );

      // Update success or failure counts based on the response
      if (response && response.Success) {
        this.bulkPromote.successCount += 1;
      } else {
        const errorMessage = response && response.AssetResponse && response.AssetResponse.Message
          ? response.AssetResponse.Message
          : 'Error promoting asset.';
        this.addFailedPayload(errorMessage);
      }
    }
  }

  // Add a failed payload with a given error message
  private addFailedPayload(errorMessage: string) {
    const assetRecord = { promotionError: errorMessage };
    this.bulkPromote.failedPayloads.push(assetRecord);
  }

  // Update the bulk promotion progress
  private updateProgress(current: number, total: number) {
    this.bulkPromoteProgress = Number(((current / total) * 100).toFixed(2));
  }

  // Show the results of the bulk promotion
  private showPromotionResults(isAssetPromotion: boolean) {
    const entityType = isAssetPromotion ? 'assets' : 'images';

    if (this.bulkPromote.successCount > 0) {
      this.messageService.add({
        severity: 'success',
        summary: 'Promotion Successful',
        detail: `${this.bulkPromote.successCount} ${entityType} successfully promoted.`,
        life: 100
      });
    }

    if (this.bulkPromote.failedPayloads && this.bulkPromote.failedPayloads.length > 0) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Promotion Error',
        detail: `${this.bulkPromote.failedPayloads.length} ${entityType} not promoted.`
      });
    }

    this.showBulkPromote = false;
    this.showBulkPromoteFailed = this.bulkPromote.failedPayloads && this.bulkPromote.failedPayloads.length > 0;
    this.disablePromoteButton = false;
    this.showPromoteProgress = false;
  }

  /**
   * Check safety gating logic.
   *
   * @param assetType Asset Type value
   * @param entity Entity data
   */
  validateAssetPromotion(entity: any) {
    let isValid = true;
    switch (this.entity) {
      case 'items':
        if
          (
          entity.itemStatus != 'Approved' ||
          entity.vendorStatus != 'Approved' ||
          entity.start == null ||
          entity.start == 0
        ) {
          isValid = false;
        }
        // Do not promote if "hold" flag is set
        if (entity.flagged && entity.flagged.toLowerCase() == 'hold') {
          isValid = false;
        }
        break;
    }

    return isValid;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  parseValue(value: string): string {
    try {
      const obj = JSON.parse(value);
      return obj.name + ' (' + obj.id + ')';
    } catch (e) {
      return value; // or handle the error as you see fit
    }
  }


  async syncStoreV2AndPricePoints(env: 'dev' | 'test' | 'qa' | 'prod', delayMs: number = 0): Promise<void> {
    const syncEntity = async (entity: 'store-listings-v2' | 'price-points') => {
        console.log(`sync ${entity}`);
        try {
            this.spinnerService.loadSpinner();
            const response: any = await firstValueFrom(this.httpClient.post(`/api/store/sync/${entity}?env=${env}`, {}));
            const entityType = entity === 'store-listings-v2' ? 'Store Listings' : 'Price Points';
            console.log('response:', response);
            this.gridApi.refreshServerSide();
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `${entityType} synced successfully! Created: ${response?.created || 0}, Updated: ${response?.updated || 0}`
            });
        } catch (error: any) {
            console.error(`Error syncing ${entity}:`, error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: `Error syncing ${entity}! Error: ${error.message}`
            });
            throw error;
        } finally {
            this.spinnerService.endSpinner();
        }
    };

    await syncEntity('store-listings-v2');
    if (delayMs > 0) await new Promise(resolve => setTimeout(resolve, delayMs));
    await syncEntity('price-points');
}


  async addToCart(selected: any, individualID: number | null = null) {
    let rows: any[] = await this.getSelectedRows();
    // Map through each row to extract the id and assign it to result
    let result = rows.map((item: any) => item.id);
    this.agGridToolsService.addToCart(selected, result);
  }


  async viewDifferences(selected?: string) {
    var result = this.selectedRows.map((item: any) => {
      return item.id
    });

    this.differences = await firstValueFrom(this.httpClient.get<any>('/api/store/diffAll?entity=' + selected + '&ids=' + result.join()));


    this.differencesModal = true;
    this.differencesLoaded = true;
  }

  findFirstString(obj: any): string | undefined {
    if (typeof obj === 'string') {
      return obj
    } else {
      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          return obj[key];
        }
        if (typeof obj[key] === 'object') {
          const result = this.findFirstString(obj[key]);
          if (result) {
            return result;
          }
        }
      }
      return undefined;
    }

  }

  navigateToDetails(id: any, entity: any) {
    window.open('/' + entity + '/' + id)
  }

  async bulkLocalize(collectionName: string): Promise<void> {
    const selectedRows = await this.getSelectedRows('id');
    var selectedIds = selectedRows.map((item: any) => {
      return item.id
    });
    const body = {
      ids: selectedIds,
      collection: collectionName
    };

    try {
      const response = await firstValueFrom(this.httpClient.post<any>('/api/localization/worker/add', body));

      if (response.success) {
        // Show success toast
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Sent Records for localization to Gridly.' });
      } else {
        // Show error toast
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send Records for localization to Gridly.' });
      }
    } catch (error: any) {
      console.error('Error sending Records for localization:', error);
      // Show error toast
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error);
      this.messageService.add({ severity: 'error', summary: 'Error sending Records for localization!', detail: errorMessage });
      throw error;
    }
  }

  async addToItemSet() {
    this.spinnerService.loadSpinner();
    const itemsToAdd = this.rowsSelected.map(rowNode => ({
        id: rowNode.data.id,
        _id: rowNode.data._id,
    }));

    const payload = {
        itemSetsId: this.selectedItemSet,
        sourcingItems: itemsToAdd,
        existingItem: false
    };

    console.log('Payload:', payload);

    try {
        let result = await this.itemSetService.addToItemSet(payload);
        console.log('Result:', result);

        if (result && result.success) {
            this.addToItemSetDialogVisible = true;
            this.messageService.add({
                sticky: true,
                severity: 'success',
                summary: 'Success',
                detail: 'Uploaded Items were successfully uploaded to collection.',
            });
        } else {
            this.messageService.add({
                sticky: true,
                severity: 'error',
                summary: 'Error',
                detail: 'No Collection Selected or API Error.',
            });
        }
    } catch (error: any) {
        console.error('Error in addToItemSet:', error);
        const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error);
        this.showMessage('error', 'API Error', errorMessage);
    }

    this.spinnerService.endSpinner();
    this.addToItemSetDialogVisible = false;
}


  async createAndAddToItemSet() {
    try {
      this.spinnerService.loadSpinner();
      const itemsToAdd = this.rowsSelected.map(rowNode => ({
        id: rowNode.data.id,
        _id: rowNode.data._id,
      }));
      this.createItemSet.items_ref = itemsToAdd;

      let result = await this.itemSetService.createItemSet({ data: this.createItemSet });

      if (result) {
        if (result.success) {
          this.createItemSetDialogVisible = false;
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created an item set and added the items.',
          });
        } else {
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: 'No Item Set Created.',
          });
        }
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        });
      }
    } catch (error: any) {
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : JSON.stringify(error.error || error);
      this.showMessage('error', 'Conflict Error', errorMessage);
      console.error('Error creating item set:', error);
    } finally {
      this.spinnerService.endSpinner();
      this.createItemSetDialogVisible = false;
      this.addToItemSetDialogVisible = false;

    }
  }

  showRenderDiffDialog(){
    this.displayRenderDiffDialog = true;
  }

  toggleDuplicate(){
    this.tableCommunicationService.duplicateRow({
      id: this.rowsSelected.map((rowNode: any) => rowNode.data.id),
      _id: this.rowsSelected.map((rowNode: any) => rowNode.data._id),  
      isBulk: true,
      qty: this.rowsSelected.length
    });
  }
}
