import { Component, OnInit, Injector } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

@Component({
  selector: 'app-resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.sass']
})
export class ResourceFormV2Component extends BaseDynamicFormComponent implements OnInit
{
  resourceRecord: BaseEntityDto = new BaseEntityDto();
  constructor(injector: Injector) 
  {
    super(injector);
  }

  /**
   * Shape Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Resource Data",
        fields: 
        [
          { 
          name: "Name", 
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput], 
          clearField: true, 
          key: 'name', 
          isRequired: true, 
          label: 'Name', 
          disabled: false 
        },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Resource";
    this.viewRoute = 'resources';
    this.isLoading = false;
  } 
}
    
  

