import { AuthGuard } from "../auth/auth.guard";
import { MaterialViewV2Component } from "../entities/material/material-view-v2/material-view-v2.component";
import { MaterialFormV2Component } from "../cms-v2/entities/material/components/material-form-v2/material-form-v2.component";
import { MaterialTableAgGridComponent } from "../cms-v2/entities/material/components/material-table-ag-grid/material-table-ag-grid.component";

export const materialsRoutes = {
  prefix: 'materials',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: MaterialTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: MaterialFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: MaterialViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: MaterialFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};