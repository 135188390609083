import { AuthGuard } from "../auth/auth.guard";
import { RestrictionTableAgGridComponent } from "../cms-v2/entities/restriction/components/entity-table-ag-grid/restriction-table-ag-grid.component";
import { RestrictionViewV2Component } from "../cms-v2/entities/restriction/components/restriction-view-v2/restriction-view-v2.component";
import { RestrictionFormV3Component } from "../cms-v2/entities/restriction/components/restriction-form-v3/restriction-form-v3.component";

export const restrictionsRoutes = {
  prefix: 'restrictions',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: RestrictionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: RestrictionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: RestrictionViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: RestrictionFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};