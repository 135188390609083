import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';

@Component({
  selector: 'app-refs-cell-renderer',
  templateUrl: './refs.component.html',
  styleUrls: ['./refs.component.sass']
})
export class RefsCellRendererComponent implements ICellRendererAngularComp {
  constructor(
    private tableCommunicationService: TableCommunicationService
  ) {}

  public params: any;
  timestamp = new Date().getTime();

  agInit(params: any): void {
    this.params = params;
    this.params.key = this.params.key ? this.params.key : 'name'
  }

  refresh(params: any): boolean {
    return false;
  }

  getLink(id: number | string) {
    // Check if this.params.data and this.params.data.sourcingGroup_ref are defined
    if (this.params?.isSourcingItem && this.params?.data && this.params?.data?.sourcingGroup_ref) {
      return `sourcing-groups/${this.params.data.sourcingGroup_ref.id}?global=${id}`;
    } else if (this.params?.isGuaranteedRarity && this.params?.data && this.params?.data?.guaranteedRarity_ref) {
      return `nurture-rarity/${this.params.value.id}`;
    } else if (typeof this.params?.linkFormatter === 'function') {
      return this.params.linkFormatter(this.params);
    }

    return `${this.params.entity}/${id}`;
  }

  handleClick(event: Event, value: any) {
    event.preventDefault();
    this.params.onClick(value);
  }
}
