import { Component, OnInit } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { ActionsCellRendererComponent } from "../common/ag-grid-table/cell-renderers/render-components/actions/actions.component";
import { AgGridToolsService } from "../common/ag-grid-table/services/ag-grid-tools.service";
import { TABLE_ACTIONS } from "../common/ag-grid-table/constants";
import { OptionsParams, UtilitiesService } from "../common/services/utilities.service";
import { RefsCellRendererComponent } from "../common/ag-grid-table/cell-renderers/render-components/refs/refs.component";
import { MultiselectEditorComponent } from "../common/ag-grid-table/cell-editors/multiselect/multiselect.component";
import { TextCellRendererComponent } from "../common/ag-grid-table/cell-renderers/render-components/text/text.component";
import { BooleanEditorComponent } from "../common/ag-grid-table/cell-editors/boolean/boolean.component";
import { UserService } from "./service/user.service";
import { TableCommunicationService } from "../common/ag-grid-table/services/table-communication.service";

@Component({
  selector: 'app-users',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass'],
})
export class UserComponent implements OnInit {
  constructor(
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
    private userService: UserService,
    private tableCommunicationService: TableCommunicationService,
  ) {}

  public entity: string = 'users';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public isLoading: boolean = true;
  private options: any = {};

  async ngOnInit(): Promise<void> {
    await this.mount();

    // This subscription is to update all filters when the roles module is updated with a new role
    this.tableCommunicationService.event$.subscribe((event: any) => {
      if (event === 'reloadDataSource') {
        this.columnSelection = '';
        this.columnDefs = [];
        setTimeout(() => {
          this.mount();
        }, 500);
      }
    });
  }

  async mount() {
    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right',
        width: 120,
        resizable: true,
      },
      {
        headerName: 'ID',
        field: 'id',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Name',
        field: 'name',
        width: 350,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        width: 350,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Is Active',
        field: 'isActive',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Is a Test User',
        field: 'isTest',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          undefinedLabel: '-',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Role(s)',
        field: 'roles',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          onClick: (value: any) => this.userService.setUser(value),
          entity: 'roles',
          isArray: true,
          noID: true,
        },
        sortable: false,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.rolesOptions,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: false,
          populate: true,
          select: 'permissions',
          nestedPop: true
        },
        floatingFilter: true,
        width: 400,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
    ];

    this.bulkUpdateColDefs = [];

    this.agGridToolsService.disableTableActions([
      TABLE_ACTIONS.ACTIONS,
      TABLE_ACTIONS.NEW,
      TABLE_ACTIONS.TABLE_SEARCH,
      TABLE_ACTIONS.COLUMNS_PRESETS,
      TABLE_ACTIONS.SAVED_FILTERS,
      TABLE_ACTIONS.GRID_VIEW_SETTINGS,
      TABLE_ACTIONS.ROW_SELECTED,
      TABLE_ACTIONS.FULL_EDIT_MODE,
    ]);

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  async setOptions() {
    const entities = [
      { ref: 'rolesOptions', entity: 'roles', query: {}, populate: ['permissions'], autopopulate: true },
    ];

    for (const { ref, entity, query, populate, autopopulate } of entities) {
      const params = new OptionsParams({
        entity, query, populate, autopopulate
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }
}