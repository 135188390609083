import { Component, Input, DoCheck, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-rewards-track-input',
    templateUrl: './rewards-track-input.component.html',
    styleUrls: ['./rewards-track-input.component.css']
})
export class RewardsTrackInputComponent implements DoCheck, OnInit {
    constructor() { }

    @Input() field: any;
    @Input() record: any;
    @Output() onRecordChange = new EventEmitter<any>();
    private previousType: number | undefined;

    trackKeys: string[] = [];
    trackKeyLabels: string[] = [];
    lineItemInputVersion: number = 3;
    milestoneLabel: string = 'Target';
    readonly trackKeyType: { [key: string]: string } = {
        dailyRewardDay1: 'singleStage',
        dailyRewardDay2: 'singleStage',
        dailyRewardDay3: 'singleStage',
        dailyRewardDay4: 'singleStage',
        dailyRewardDay5: 'singleStage',
        dailyRewardDay6: 'singleStage',
        dailyRewardDay7: 'singleStage',
        dailyRewardDay30: 'singleStage',
        free: 'multiStage',
        paid: 'multiStage',
        friends: 'multiStage',
        EOrewards: 'eoTrack',
        EObundles: 'eoTrack',
        coop: 'multiStage',
        miniGamesRewards: 'multiStage',
        seasonalPrizes: 'multiStage'
    };

    ngOnInit() {
        console.log('record', this.record);  
        if (this.record?.type !== undefined) {
            this.buildTrackKeys(this.record.type);
            this.previousType = this.record.type;
        }
        if (this.field.options && this.field.options.lineItemInputVersion) {
            this.lineItemInputVersion = this.field.options.lineItemInputVersion;
        }
    }

    ngDoCheck() {
        if (this.record?.type !== undefined && this.record.type !== this.previousType) {
            this.previousType = this.record.type;
            this.buildTrackKeys(this.record.type);
        }
    }

    private buildTrackKeys(type: number) {
        switch (type) {
            case 1: // Daily Login Rewards - 7 days
                this.trackKeys = ['dailyRewardDay1', 'dailyRewardDay2', 'dailyRewardDay3',
                    'dailyRewardDay4', 'dailyRewardDay5', 'dailyRewardDay6', 'dailyRewardDay7'];
                this.trackKeyLabels = ['Day 1 Rewards', 'Day 2 Rewards', 'Day 3 Rewards', 'Day 4 Rewards', 'Day 5 Rewards', 'Day 6 Rewards', 'Day 7 Rewards'];
                break;
            case 2: // Daily Login Rewards - Day 30
                this.trackKeys = ['dailyRewardDay30'];
                this.trackKeyLabels = ['Day 30 Rewards'];
                break;
            case 3: // Season Pass - Free track
                this.trackKeys = ['free'];
                this.trackKeyLabels = ['Free Stage'];
                this.milestoneLabel = 'Target';
                break;
            case 4: // Season Pass - Paid track
                this.trackKeys = ['paid'];
                this.trackKeyLabels = ['Paid Stage'];
                this.milestoneLabel = 'Target';
                break;
            case 5: // Friends Invited
                this.trackKeys = ['friends'];
                this.trackKeyLabels = ['Friends Invited'];
                this.milestoneLabel = 'Friends Invited';
                break;
            case 6: // Endless Offer
                this.trackKeys = ['EOrewards'];
                this.trackKeyLabels = ['Endless Offer Rewards'];
                break;
            case 7: // Co-Op
                this.trackKeys = ['coop'];
                this.trackKeyLabels = ['Co-Op Stage'];
                this.milestoneLabel = 'Reward Target';
                break;
            case 8: // Mini Games
                this.trackKeys = ['miniGamesRewards'];
                this.trackKeyLabels = ['Mini Games Rewards'];
                break;
            case 9: // Seasonal Prizes
                this.trackKeys = ['seasonalPrizes'];
                this.trackKeyLabels = ['Seasonal Prizes'];
                break;
            default:
                this.trackKeys = ['error'];
                this.trackKeyLabels = ['Error: Could not match track type'];
        }
    }

    onChange(key: string, event: any, stageIndex: number | null = null) {
        console.log('key', key);
        console.log('event', event);

        this.field.customFields =
            [{
                key: key,
                touched: true
            }];

        if (key === 'stageMilestone' && stageIndex !== null) {
            this.record.stageMilestone[stageIndex] = event.value;
            this.onRecordChange.emit({ event: { field: key, value: this.record.stageMilestone }, field: this.field });
            return;
        }

        if(key === 'EOrewards') {
            // emit change for both EOrewards and EObundles
            this.record.EOrewards = event.record.EOrewards;
            this.record.EObundles = event.record.EObundles;
            this.onRecordChange.emit({ event: { field: 'EOrewards', value: this.record.EOrewards }, field: 'EOrewards' });
            this.onRecordChange.emit({ event: { field: 'EObundles', value: this.record.EObundles }, field: 'EObundles' });
            return;
        }

        if (stageIndex !== undefined && stageIndex !== null) {
            this.record[key][stageIndex] = event;
        } else {
            this.record[key] = event;
        }

        this.onRecordChange.emit({ event: { field: key, value: event }, field: this.field });
        this.onRecordChange.emit({ event: { field: 'stageMilestone', value: this.record.stageMilestone }, field: 'stageMilestone' });
    }

    addStage(key: string) {
        this.record[key] = [...this.record[key], [{ t: null, c: null, id: null, lineItemType: null }]];

        if (this.record.stageMilestone) {
            this.record.stageMilestone.push(0);
        } else {
            this.record.stageMilestone = [];
        }
        this.onChange(key, this.record[key]);

        setTimeout(() => {
            const orderList = document.getElementById('orderList-' + key);
            if (orderList) {
                const listContent = orderList.querySelector('.p-orderlist-list');
                if (listContent) {
                    listContent.scrollTo({
                        top: listContent.scrollHeight,
                        behavior: 'smooth'
                    });
                }
            }
        }, 100);
    }

    removeStage(key: string, index: number): void {
        this.record[key].splice(index, 1);
        this.record.stageMilestone.splice(index, 1);
        this.onChange(key, this.record[key]);
    }

}
