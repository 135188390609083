import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import DynamicFormFieldDTO from '../dynamic-form-v2/dtos/DynamicFormFieldDTO';
import { HttpClient } from '@angular/common/http';
interface ColorObject {
  primaryColor: string;
  secondaryColor: string;
}

@Component({
  selector: 'app-nested-input',
  templateUrl: './nested-input.component.html',
  styleUrls: ['./nested-input.component.sass']
})
export class NestedInputComponent implements OnInit {

  @Input() record: any = {};
  @Input() field: DynamicFormFieldDTO = new DynamicFormFieldDTO();
  @Output() onChange = new EventEmitter<any>();
  @Input() fields: DynamicFormFieldDTO[];


  headerTitle: string;
  addButtonText: string;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.record = { ...this.record };
    const key = this.field.key as keyof typeof this.record;
    if (!this.record[key]) {
      this.record[key] = [];
    }
    if (key === 'team_colors' && Array.isArray(this.record[key])) {
      this.record[key] = this.record[key].map((color: any) => {
        if (Array.isArray(color)) {
          return { primaryColor: color[0], secondaryColor: color[1] };
        } else {
          return color;
        }
      });
    } else if (this.field.options?.flatValue && this.field.options?.subFields?.[0]?.key && this.record[key]) {
      this.record[key] = this.record[key].map((item: any) => ({ [this.field.options!.subFields![0].key]: item }));
    }

    this.headerTitle = this.field.options?.headerTitle || "Default Title";
    this.addButtonText = this.field.options?.addButtonText || "Add";
  }

  addNestedField(): void {
    let newRow = {...this.field?.options?.newRecordTemplate || {}};
    this.record[this.field.key].push(newRow);
    this.onRecordChange(this.record[this.field.key]);
  }

  onFieldValueChange(subFieldKey: string, fieldIndex: number, subFieldValue: any): void {
    if (fieldIndex >= this.record[this.field.key].length) {
      console.error(`onFieldValueChange: Index ${fieldIndex} out of range for field ${this.field.key}.`);
      return;
    }

    console.debug(`onFieldValueChange: Received subFieldValue = ${subFieldValue}, subFieldKey = ${subFieldKey}`);

    if (this.field.key === 'team_colors') {
      const isValidColor = typeof subFieldValue === 'string' && /^#[0-9A-F]{6}$/i.test(subFieldValue);
      const isEmpty = subFieldValue === null || subFieldValue === '';
      this.record[this.field.key][fieldIndex] = this.record[this.field.key][fieldIndex] || { primaryColor: '', secondaryColor: '' };

      if (isValidColor || isEmpty) {
        this.record[this.field.key][fieldIndex][subFieldKey as keyof ColorObject] = subFieldValue;
        let emittedValue = this.record[this.field.key].map((colorObj: ColorObject) => {
          return [colorObj.primaryColor, colorObj.secondaryColor];
        });
        this.onChange.emit(emittedValue);
      } else {
        console.error('subFieldValue is not a hexadecimal color:', subFieldValue);
        return;
      }
    } else if (this.field.options?.flatValue) {
      console.log('Flat value is true');
      const flatArray = this.record[this.field.key].map((item: any) => item[subFieldKey]);
      console.log(flatArray);
      this.onChange.emit(flatArray);
    } else {
      this.record[this.field.key][fieldIndex][subFieldKey] = subFieldValue;
      console.log(`onFieldValueChange: Change in field ${subFieldKey}, index ${fieldIndex}, new value:`, subFieldValue);
      this.onChange.emit(this.record[this.field.key]);
    }
  }

  removeField(index: number): void {
    this.record[this.field.key].splice(index, 1);
    this.onRecordChange(this.record[this.field.key]);
  }

  reOrderLists(event: any): void {
    console.log('Handling reorder operation:', event);
    if (this.field.key === 'team_colors') {
      const transformedColors = this.record[this.field.key].map((colorObj: ColorObject) => {
        return [colorObj.primaryColor, colorObj.secondaryColor];
      });
      console.log('Emitting reordered and transformed color change:', transformedColors);
      this.onChange.emit(transformedColors);
    } else if (this.field.options?.flatValue) {
      const flatArray = this.record[this.field.key].map((item: any) => item[this.field.options!.subFields![0].key]);
      console.log('Emitting reordered and transformed change:', flatArray);
      this.onChange.emit(flatArray);
    } else {
      console.log(`Reorder operation for field ${this.field.key} not specifically handled.`);
      this.onChange.emit(this.record[this.field.key]);
    }
  }


  onRecordChange(event: any): void {
    if (this.field.key === 'team_colors') {
      console.log('Received color change:', event);
      let eventEmissionObj: any = {};
      eventEmissionObj[this.field.key] = { ...event };
      if (Array.isArray(eventEmissionObj[this.field.key])) {
        eventEmissionObj[this.field.key] = eventEmissionObj[this.field.key].map((colorObj: ColorObject) => {
          return [colorObj.primaryColor, colorObj.secondaryColor];
        });
        this.onChange.emit(this.record[this.field.key]);
      } else {
        console.error('Expected an array for teamColors:', this.record[this.field.key]);
      }
    } else if (this.field.options?.flatValue) {
      const flatArray = this.record[this.field.key].map((item: any) => item[this.field.options!.subFields![0].key]);
      console.log('Emitting change:', flatArray);
      this.onChange.emit(flatArray);
    } else {
      this.onChange.emit(event);
    }
  }

  prepareSubmitData(): any {
    const dataToSubmit = { ...this.record };

    if (this.field.key === 'colors') {
      dataToSubmit[this.field.key] = this.record[this.field.key].map((colorObj: ColorObject) => {
        return colorObj.primaryColor;
      });
    }

    return dataToSubmit;
  }

  handleBaseInputValidEvent(isValid: boolean): void {
    console.log('Input validity status:', isValid);
  }
}
