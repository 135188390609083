import { AuthGuard } from "../auth/auth.guard";
import { EndlessOfferTableAgGridComponent } from "../cms-v2/entities/endless-offer/components/table/endless-offer-table-ag-grid.component";
import { EndlessOfferFormComponent } from "../cms-v2/entities/endless-offer/components/form/endless-offer-form-v3.component";
import { EndlessOfferViewComponent } from "../cms-v2/entities/endless-offer/components/view/endless-offer-view.component";
import { ReleaseEndlessOfferViewV2Component } from "../entities/release/release-endless-offer/release-endless-offer-view-v2/release-endless-offer-view-v2.component";

export const endlessOfferRoutes = {
  prefix: 'endless-offer',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: EndlessOfferTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: EndlessOfferFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: EndlessOfferViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `releases/${this.prefix}/:id`,
        component: ReleaseEndlessOfferViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: EndlessOfferFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};