import { AuthGuard } from "../auth/auth.guard";
import { RewardsTrackFormV3Component } from "../cms-v2/entities/rewards-track/components/rewards-track-form-v3/rewards-track-form-v3.component";
import { RewardsTrackTableAgGridComponent } from "../cms-v2/entities/rewards-track/components/rewards-track-table-ag-grid/rewards-track-table-ag-grid.component";
import { RewardsTrackViewV2Component } from "../cms-v2/entities/rewards-track/components/rewards-track-view-v2/rewards-track-view-v2.component";

export const rewardsTrackRoutes = {
  prefix: 'rewards-track',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: RewardsTrackTableAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: RewardsTrackFormV3Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: RewardsTrackViewV2Component,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: RewardsTrackFormV3Component,  
        canActivate: [AuthGuard],
      },
    ]
  }
};