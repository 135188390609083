import { AuthGuard } from "../auth/auth.guard";
import { CoOpTableAgGridComponent } from "../cms-v2/entities/co-op/components/co-op-table-ag-grid/co-op-table-ag-grid.component";
import { CoOpFormV3Component } from "../cms-v2/entities/co-op/components/co-op-form-v3/co-op-form-v3.component";
import { CoOpViewComponent } from "../cms-v2/entities/co-op/components/co-op-view/co-op-view.component";

export const coOpRoutes = {
  prefix: 'co-op',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: CoOpTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: CoOpFormV3Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: CoOpViewComponent,     
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: CoOpFormV3Component,  
        canActivate: [AuthGuard],
      },
    ]
  }
};