<app-game-data-logs-popup (dataUpdated)="onDataUpdated()"></app-game-data-logs-popup>
<app-ag-grid-table
  #agGrid  
  *ngIf="columnSelection"
  [entity]="entity"
  [columnDefs]="columnDefs"
  [bulkUpdateColDefs]="bulkUpdateColDefs"
  [autopopulateSelect]="autopopulateSelect"
  [columnSelection]="columnSelection"
  [buildParams]="buildParams"
  [rowModelType]="'serverSide'"
>
</app-ag-grid-table>

  