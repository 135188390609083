import { Component, OnInit } from "@angular/core";
import { ColDef } from "ag-grid-community";
import { ActionsCellRendererComponent } from "../common/ag-grid-table/cell-renderers/render-components/actions/actions.component";
import { AgGridToolsService } from "../common/ag-grid-table/services/ag-grid-tools.service";
import { TABLE_ACTIONS } from "../common/ag-grid-table/constants";
import { OptionsParams, UtilitiesService } from "../common/services/utilities.service";
import { RefsCellRendererComponent } from "../common/ag-grid-table/cell-renderers/render-components/refs/refs.component";
import { MultiselectEditorComponent } from "../common/ag-grid-table/cell-editors/multiselect/multiselect.component";
import { RoleService } from "./service/role.service";
import { TableCommunicationService } from "../common/ag-grid-table/services/table-communication.service";

@Component({
  selector: 'app-roles',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.sass'],
})
export class RoleComponent implements OnInit {
  constructor(
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
    private roleService: RoleService,
    private tableCommunicationService: TableCommunicationService,
  ) {}

  public entity: string = 'roles';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public isLoading: boolean = true;
  private options: any = {};

  async ngOnInit(): Promise<void> {
    await this.mount();

    // This subscription is to update all filters when the permissions module is updated with a new permission
    this.tableCommunicationService.event$.subscribe((event: any) => {
      if (event === 'reloadDataSource') {
        this.columnSelection = '';
        this.columnDefs = [];
        setTimeout(() => {
          this.mount();
        }, 500);
      }
    });
  }

  async mount() {
    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right',
        width: 120,
        resizable: true,
      },
      {
        headerName: 'ID',
        field: 'id',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Description',
        field: 'description',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Permission(s)',
        field: 'permissions',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          onClick: (value: any) => this.roleService.setRole(value),
          entity: 'permissions',
          isArray: true,
          noID: true,
        },
        sortable: false,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.permissionsOptions,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: false,
          populate: true,
          select: '_id id name abilities entity'
        },
        floatingFilter: true,
        width: 400,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
    ];

    this.bulkUpdateColDefs = [];

    this.agGridToolsService.disableTableActions([
      TABLE_ACTIONS.ACTIONS,
      TABLE_ACTIONS.NEW,
      TABLE_ACTIONS.TABLE_SEARCH,
      TABLE_ACTIONS.COLUMNS_PRESETS,
      TABLE_ACTIONS.SAVED_FILTERS,
      TABLE_ACTIONS.GRID_VIEW_SETTINGS,
      TABLE_ACTIONS.ROW_SELECTED,
      TABLE_ACTIONS.FULL_EDIT_MODE,
    ]);

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  async setOptions() {
    const entities = [
      { ref: 'permissionsOptions', entity: 'permissions', query: {} },
    ];

    for (const { ref, entity, query } of entities) {
      const params = new OptionsParams({
        entity, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }
}