import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonEntityService } from '../../services/common-entity.service';

@Component({
  selector: 'app-dependent-input',
  templateUrl: './dependent-input.component.html',
  styleUrls: ['./dependent-input.component.sass']
})
export class DependentInputComponent implements OnInit {

 @Input() field: any;
 @Input() options: any;
 @Input() record: any;
 suggestions: any = {}
 controller: string;

 @Output() onRecordChange = new EventEmitter<any>();

  constructor(
    private readonly commonEntityService: CommonEntityService
  ) { }

  ngOnInit(): void {
    console.log(this.field)
    if(this.record[this.field.key]) {
      this.controller = this.record[this.field.key]
    } 
    
  }

  onFieldValueChange(field: string, value: any) {

    console.log(field, value)
    if(field == this.field.key) {
      this.controller = value;
    } else {
      if(value?.id){
        value = value.id
      }
      this.record[this.field.keyDependent] = value;
    }

    this.onRecordChange.emit({field: field, value: value});
  }

   /**
   * Autocomplete searching
   *
   * @param value Query value
   * @param fieldName Field name
   * @param model API Controller
   * @param minimal Flag that sets whether or not to return minimal result
   * @param autopopulate Flag that sets whether or not to autopopulate result.
   * @param sort Sort expression
   * @param virtuals Flag that sets whether or not to include virtual props.
   * @param select Select query
   */
   async getSuggestionsForRef(
    value: any,
    fieldName: string | any,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = false,
    sort: any = null,
    virtuals: boolean = false,
    select: string | null = null,
    customQuery: any = null,
    customQueryField: string | null = null
  ) {
    let idValue = parseInt(value);
    let queryValue = customQuery ? customQuery : (customQueryField && customQueryField.length > 0 ? (isNaN(idValue) ? { [customQueryField]: { $regex: value, $options: 'i' } } : { id: idValue }) : (isNaN(idValue) ? { name: { $regex: value, $options: 'i' } } : { id: idValue }));
    let result = await this.commonEntityService.findAllWithQuery(model,
      {
        query: queryValue,
        select: select ? select : '_id name id',
        virtuals: virtuals,
        autopopulate: autopopulate,
        sort: sort ? sort : { name: 1 },
        isOptions: true,
      });

    if (minimal) {
      let o: any[] = [];
      for (const option of result) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.suggestions[fieldName] = o;
    } else {
      this.suggestions[fieldName] = result;
    }
  }

}
