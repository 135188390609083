import { AuthGuard } from "../auth/auth.guard";
import { InboxMessageTableAgGridComponent } from "../cms-v2/entities/inbox-message/components/inbox-message-table-ag-grid/inbox-message-table-ag-grid.component";
import { InboxMessageFormV3Component } from "../cms-v2/entities/inbox-message/components/inbox-message-form-v3/inbox-message-form-v3.component";
import { InboxMessageViewV2Component } from "../cms-v2/entities/inbox-message/components/inbox-message-view-v2/inbox-message-view-v2.component";

export const inboxMessagesRoutes = {
  prefix: 'inbox-messages',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: InboxMessageTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: InboxMessageFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: InboxMessageViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: InboxMessageFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};