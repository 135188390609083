import { LoggerService } from 'src/app/common/services/logger.service';
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import AssetDTO from '../dtos/AssetDTO';
import { GettersService } from 'src/app/common/services/getters.service';
import { InputFieldType } from '../../dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-asset-form-v3',
  templateUrl: './asset-form-v3.component.html',
  styleUrls: ['./asset-form-v3.component.sass']
})
export class AssetFormV3Component extends BaseDynamicFormComponent implements OnInit {
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  assetRecord: AssetDTO = new AssetDTO();
  entityTypes: any[];
  bundleTypes: any[];
  constructor(
    private gettersService: GettersService,
    injector: Injector
  ) {
    super(injector);
  }

  /**
   * Asset Form V3 Initialization
   */
  async ngOnInit() {
    this.entityTypes = await this.gettersService.getEntityTypeSettings();
    this.bundleTypes = await this.gettersService.getBundleTypeSettings();

    this.fields =
      [
        {
          title: "Asset Data",
          fields:
            [
              {
                name: "Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'name',
                isRequired: true,
                label: 'Name',
                disabled: false,
                columnWidth: 12
              },
              {
                name: "Entity Type",
                inputType: DynamicInputType.DropdownDependent,
                clearField: true,
                key: 'entityType',
                label: 'Entity Type',
                options: {
                  fieldName: 'entityType',
                  values: this.entityTypes
                },
                nameDependent: 'Image Type',
                keyDependent: 'assetType',
                labelDependent: 'Image Type',
                optionsDependent: {
                  fieldName: 'assetType',
                  values: this.bundleTypes,
                },
                columnWidth: 6
              },
              {
                name: "Path",
                inputType: DynamicInputType.PlasticAutoComplete,
                clearField: true,
                key: 'path',
                isRequired: true,
                label: 'Path',
                disabled: false
              },
              {
                name: "Localized",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.InputSwitch],
                clearField: false,
                key: 'localized',
                isRequired: false,
                columnWidth: 12
              },

            ],
          type: DynamicCardType.Card
        },
        {
          title: "Bundle Images and Assets",
          fields:
            [
              {
                name: "Bundle Images (IOS)",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.InputSwitch],
                clearField: false,
                key: 'bundleImage',
                isRequired: false,
              },
              {
                name: "Bundle Images (AND)",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.InputSwitch],
                clearField: false,
                key: 'bundleImageAnd',
                isRequired: false,
              },
            ],
          type: DynamicCardType.Card,
          columns: 2
        }
      ]

    this.title = "Asset";
    this.viewRoute = 'miscellaneous-build';
    this.isLoading = false;

  }


  /**
   *
   */
  UpdateDependentOptions(event: any) {
    console.log('event', event)

    if (event.value && event.field && event.field == 'entityType') {
      let entityType = this.entityTypes.filter(obj => obj.value === event.value);
      if (entityType && entityType[0].name) {
        this.dynamicFormComponent.options['assetType'] = this.bundleTypes.filter(obj => obj.entityType === entityType[0].name);
      } else {
        this.dynamicFormComponent.options['assetType'] = this.bundleTypes;
      }
    }
  }

  /**
   * Preload Asset Record
   * @param record
   *
   * @description
   * This method is used to preload the asset record
   * when the user clicks on the edit button
   * in the asset table or on asset view page
   *
   * */
  preLoad(record: any) {
    this.assetRecord = record;
    this.UpdateDependentOptions({
      value: record.entityType,
      field: 'entityType'
    })
  }
}
