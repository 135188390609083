import { AuthGuard } from "../auth/auth.guard";
import { ItemTypeFormV3Component } from "../cms-v2/entities/item-type/components/item-type-form-v3/item-type-form-v3.component";
import { ItemTypeViewV2Component } from "../cms-v2/entities/item-type/components/item-type-view-v2/item-type-view-v2.component";
import { ItemTypeAgGridComponent } from "../cms-v2/entities/item-type/components/item-type-table-ag-grid/item-type-table-ag-grid.component";

export const itemTypesRoutes = {
  prefix: 'item-types',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ItemTypeAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ItemTypeFormV3Component,  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ItemTypeViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ItemTypeFormV3Component, 
        canActivate: [AuthGuard],
      },
    ]
  }
};