import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import InboxMessageDto from '../../dtos/InboxMessageDTO';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-inbox-message-form-v3',
  templateUrl: './inbox-message-form-v3.component.html',
  styleUrls: ['./inbox-message-form-v3.component.sass']
})
export class InboxMessageFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  defaultDate: any;
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  inboxMessageRecord: InboxMessageDto = new InboxMessageDto();
  maxUserLevel: number;
  entityTypes: any[];
  ids: any[];


  constructor
  (
    private commonEntityService: CommonEntityService,
    protected utilitiesService: UtilitiesService,
    private validation: BaseInputValidationServiceService,
    private dataService: DataService,
  )
  {
    super();
  }

  /**
   * Inbox Message Form V3 Component Initialization
   */
  async ngOnInit()
  {
    this.maxUserLevel = await this.setMaxUserLevel();
    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight();
    this.fields = [
      {
        title: 'Inbox Message Settings',
        fields: [
          {
            name: 'Name',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
          },
          {
            name: 'Start Date/Time',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarDefaultDate: this.defaultDate,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: 'End Date/Time',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.inboxMessageRecord.start,
            calendarDefaultDate: this.defaultDate,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
          {
            name: 'Type',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'type',
            label: 'Type',
            options: {
              fieldName: 'type',
              values: [{ name: 'General' }, { name: 'Gift' }, { name: 'Sale' }],
            },
            optionLabel: 'name',
            optionValue: 'name',
            clearField: false,
            autoDisplayFirst: false,
            filter: true,
            filterBy: 'name',
            style: { 'min-width': '12.5em' },
            showClear: true,
            isRequired: true,
          },
          {
            name: 'Layout',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'layout',
            label: 'Layout',
            options: { fieldName: 'layout', values: [{name: 'MessageLayout'}] },
            optionLabel: 'name',
            optionValue: 'name',
            clearField: false,
            autoDisplayFirst: false,
            filter: true,
            style: { 'min-width': '12.5em' },
            showClear: true,
            isRequired: true,
          },
          {
            name: 'Product ID',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'productId',
            isRequired: false,
            label: 'Product ID',
          },
          {
            name: 'Time to live',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'timeToLive',
            label: 'Time to live',
            options: {
              fieldName: 'timeToLive',
              values: [
                {
                  label: 'Hours',
                  value: 'Hours',
                  items: [
                    {
                      label: '1 hour',
                      value: 1,
                    },
                    {
                      label: '2 hours',
                      value: 2,
                    },
                    {
                      label: '3 hours',
                      value: 3,
                    },
                    {
                      label: '4 hours',
                      value: 4,
                    },
                    {
                      label: '5 hours',
                      value: 5,
                    },
                    {
                      label: '6 hours',
                      value: 6,
                    },
                    {
                      label: '7 hours',
                      value: 7,
                    },
                    {
                      label: '8 hours',
                      value: 8,
                    },
                    {
                      label: '9 hours',
                      value: 9,
                    },
                    {
                      label: '10 hours',
                      value: 10,
                    },
                    {
                      label: '11 hours',
                      value: 11,
                    },
                    {
                      label: '12 hours',
                      value: 12,
                    },
                    {
                      label: '13 hours',
                      value: 13,
                    },
                    {
                      label: '14 hours',
                      value: 14,
                    },
                    {
                      label: '15 hours',
                      value: 15,
                    },
                    {
                      label: '16 hours',
                      value: 16,
                    },
                    {
                      label: '17 hours',
                      value: 17,
                    },
                    {
                      label: '18 hours',
                      value: 18,
                    },
                    {
                      label: '19 hours',
                      value: 19,
                    },
                    {
                      label: '20 hours',
                      value: 20,
                    },
                    {
                      label: '21 hours',
                      value: 21,
                    },
                    {
                      label: '22 hours',
                      value: 22,
                    },
                    {
                      label: '23 hours',
                      value: 23,
                    },
                  ],
                },
                {
                  label: 'Days',
                  value: 'Days',
                  items: [
                    {
                      label: '1 day / 24 hours',
                      value: 24,
                    },
                    {
                      label: '2 days / 48 hours',
                      value: 48,
                    },
                    {
                      label: '3 days / 72 hours',
                      value: 72,
                    },
                    {
                      label: '4 days / 96 hours',
                      value: 96,
                    },
                    {
                      label: '5 days / 120 hours',
                      value: 120,
                    },
                    {
                      label: '6 days / 144 hours',
                      value: 144,
                    },
                    {
                      label: '7 days / 168 hours',
                      value: 168,
                    },
                    {
                      label: '8 days / 192 hours',
                      value: 192,
                    },
                    {
                      label: '9 days / 216 hours',
                      value: 216,
                    },
                    {
                      label: '10 days / 240 hours',
                      value: 240,
                    },
                    {
                      label: '11 days / 264 hours',
                      value: 264,
                    },
                    {
                      label: '12 days / 288 hours',
                      value: 288,
                    },
                    {
                      label: '13 days / 312 hours',
                      value: 312,
                    },
                    {
                      label: '14 days / 336 hours',
                      value: 336,
                    },
                    {
                      label: '15 days / 360 hours',
                      value: 360,
                    },
                    {
                      label: '16 days / 384 hours',
                      value: 384,
                    },
                    {
                      label: '17 days / 408 hours',
                      value: 408,
                    },
                    {
                      label: '18 days / 432 hours',
                      value: 432,
                    },
                    {
                      label: '19 days / 456 hours',
                      value: 456,
                    },
                    {
                      label: '20 days / 480 hours',
                      value: 480,
                    },
                    {
                      label: '21 days / 504 hours',
                      value: 504,
                    },
                  ],
                },
              ],
            },
            clearField: false,
            autoDisplayFirst: false,
            filter: false,
            style: { 'min-width': '12.5em' },
            showClear: true,
            isRequired: true,
            dropdownGroup: true,
            scrollHeight: '350px',
          },
          {
            name: 'Minimum User Level',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'minimumUserLevel',
            isRequired: false,
            label: 'Minimum User Level',
            inputNumberMode: 'decimal',
            inputNumberShowButtons: true,
            inputNumberInputId: 'minimumUserLevel',
            inputNumberMin: 3,
            inputNumberMax: this.maxUserLevel,
            columnWidth: 6,
          },
          {
            name: "Entity Reference",
            inputType: DynamicInputType.DropdownDependent,
            clearField: true,
            key: 'entity_ref',
            label: 'Localization Ref. Entity Type',
            options: {
              fieldName: 'entity_ref',
              values: [
                {name: 'Co-op', value: 'CoOp'},
                {name: 'Endless Offers', value: 'EndlessOffer'},
                {name: 'Store Listings', value: 'StoreListing'},
                {name: 'Currencies', value: 'Currency'},
                {name: 'Seed Pack Boxes', value: 'SeedPackBox'},
                {name: 'Nurture Item', value: 'Nurture'},
                {name: 'Series', value: 'Series'},
              ]
            },
            nameDependent: 'Entity Reference ID',
            keyDependent: 'ref_id',
            labelDependent: 'Entity Reference ID',
            autoDisplayFirst: false,
            autocomplete: true,
            suggestions: {
              fieldName: 'ref_id',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id',
            },
            optionsDependent: {
              fieldName: 'ref_id',
              values: this.ids,
              controllerDictionary: {
                'EndlessOffer': 'endless-offer',
                'StoreListing': 'store-listings-v2',
                'Currency': 'currencies',
                'CoOp': 'co-op',
                'SeedPackBox': 'seed-pack-box',
                'Nurture': 'nurture',
                'Series': 'series',
                'Items': 'items'
              }
            },
            columnWidth: 12
          },
          {
            name: 'Use Segment',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'useSegment',
            isRequired: false,
            label: 'Use Segment?',
            inputId: 'useSegment',
            checkboxBinary: true,
            columnWidth: 6,
          },
          {
            name: 'Buy CTA',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'showBuyCta',
            isRequired: false,
            label: 'Buy CTA',
            inputId: 'showBuyCta',
            checkboxBinary: true,
            columnWidth: 6,
          },
          {
            name: 'Reward(s)',
            inputType: DynamicInputType.Price,
            key: 'rewards_ref',
            options: { fieldName: '', version: 2 },
            priceExcludeResourceTypes: ['Titles'],
          },
        ],
        type: DynamicCardType.Card,
      },
      {
        title: 'Content',
        fields: [
          {
            name: 'Headline',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'headline',
            isRequired: true,
            label: 'Headline',
          },
          {
            name: 'Title',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'title',
            isRequired: true,
            label: 'Title',
          },
          {
            name: 'Body',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: true,
            key: 'body',
            isRequired: true,
            label: 'Body',
            inputTextAreaCols: 40,
            inputTextAreaRows: 10,
          },
          {
            name: 'Misc. Image Record Ref.',

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Misc. Image Record Ref.',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'image_ref',
              apiController: 'miscellaneous-build',
              customQuery: { entityType: 3 },
              autopopulate: false,
              virtuals: false,
              sort: { name: 1 },
              select: 'name id path _id',
              isdisplayNameIDPath: true,
            },
          },
          {
            name: 'Link - Destination',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'link_ref',
            isRequired: false,
            label: 'Link - Destination',
            optionLabel: 'linkText',
            optionValue: '_id',
            filter: true,
            filterBy: 'id,linkText,linkDestination',
            showClear: true,
            autoDisplayFirst: false,
            setMongoId: true,
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'link_ref',
              apiController: 'link-destination',
              minimal: false,
              autopopulate: true,
              virtuals: false,
              sort: { createdAt: 1 },
            },
          },
        ],
        type: DynamicCardType.Card,
      },
      // {
      //   title: "Send Ready",
      //   fields:
      //   [
      //     {
      //       name: 'Layout',
      //       inputType: DynamicInputType.MultiSelect,
      //       key: 'sendReady',
      //       label: 'Select Environment',
      //       options: { fieldName: 'sendReady', values: ['Dev', 'Prod', 'QA'] },
      //       clearField: true,
      //       autoDisplayFirst: false,
      //       filter: true,
      //       style: { 'width': '100%' },
      //       showClear: true,
      //       multiSelectDisplayType: 'chip'
      //     }
      //   ],
      //   type: DynamicCardType.Card
      // }
    ];

    this.sidebarFields = [
      {
       title: "Inbox Message Preview",
       fields:
       [
        {
          name: "Inbox Preview",
          inputType: DynamicInputType.InGamePreview,
          key: '',
          label: 'Inbox Preview',
        },
       ],
       type: DynamicCardType.Card
      }
     ]

    this.inboxMessageRecord.type = 'General';
    this.inboxMessageRecord.layout = 'MessageLayout';
    this.inboxMessageRecord.timeToLive = 168;
    this.inboxMessageRecord.minimumUserLevel = 5;
    this.title = "Inbox Message";
    this.viewRoute = 'inbox-messages';
    this.isLoading = false;
  }

  /**
   * Set the max user level for min user level field.
   */
  async setMaxUserLevel()
  {
    let maxUserLevel = 20;
    let result = await this.commonEntityService.findAllWithQuery
    (
      'progression-levels',
      { query: {}, sort: { level: -1 } }
    );

    if (result && result.length > 0)
    {
      maxUserLevel = result[0] ? result[0].level : 20;
    }

    return maxUserLevel;
  }

  async UpdateDependentOptions(event: any) {
    console.log('event', event)

    let entityDictionary: any = {
      'EndlessOffer': 'endless-offer',
      'CoOp': 'co-op',
      'StoreListing': 'store-listings-v2',
      'Currency': 'currencies',
      'SeedPackBox': 'seed-pack-box',
      'Nurture': 'nurture',
      'Series': 'series'
    }

    if (event.value && event.field && event.field == 'entity_ref') {
      // Fetch the data asynchronously
      this.ids = await this.dataService.getAllOfTypeAsync(entityDictionary[event.value], {}, false);

      // Map the result into the required format: [{name: name_example, value: 1000}, ...]
      this.dynamicFormComponent.options['ref_id'] = this.ids.map(item => ({
        name: item.name,  // Assuming the field name is 'name'
        value: item.id   // Assuming the field value is '_id'
      }));
    }

  }

  /**
   * Preload Asset Record
   * @param record
   *
   * @description
   * This method is used to preload the asset record
   * when the user clicks on the edit button
   * in the asset table or on asset view page
   *
   * */
  preLoad(record: any) {
    console.log('onPreload', record)
    this.inboxMessageRecord = record;
    this.UpdateDependentOptions({
      value: record.entity_ref,
      field: 'entity_ref'
    })
  }
}
