import { Title } from '@angular/platform-browser';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { challengeConstants, pathconfigValues } from '../data/constants';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { SetDefaultTimeService } from 'src/app/common/services/set-default-time.service';
import { ChallengeValidationService } from '../services/challenge-validation.service';
import { ColorPicker } from 'primeng/colorpicker';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { OfficeTimeFormPipe } from 'src/app/common/pipes/officeTimeForm.pipe';
import * as _ from 'lodash';
import challengeDTO from '../data/challengeDTO';


@Component({
  selector: 'app-challenge-form',
  templateUrl: './challenge-form.component.html',
  styleUrls: ['./challenge-form.component.sass'],
  providers: [ConfirmationService],
})
export class ChallengeFormComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // Group related properties
  private readonly CONFIG = {
    s3Cdn: 'https://d3tfb94dc03jqa.cloudfront.net/',
    previewImage: 'https://d3tfb94dc03jqa.cloudfront.net/images/asset_missing/need_thumb/need_thumb_1024.png'
  };

  private messageDebounceTimer: any;

  // Form-related properties
  challengeForm: UntypedFormGroup;
  fields: challengeDTO = new challengeDTO;

  // State management
  loading: boolean = true;
  isEditMode: boolean = false;

  // variables for path generation
  challengeConstants = challengeConstants;
  sceneTypePath: string = '';
  challengeType: string = '';
  levelNamePath: string = '';
  feedImageNamePath: string = '';
  defaultYearPath: string = pathconfigValues.year;
  defaultDate: any;
  //
  currentUserData = this.authService.getSocialUser();
  type: string = 'challenges';
  key: string = 'challenges';
  id: number;

  submitUrl: string;
  payLoad = '';
  action = 'add';
  options: any = [];
  existingDoc: any = {};
  keywords_ref: any[] = [];
  collection_ref: any[] = [];
  simplePathScene: string = '';
  simplePathImage: string = '';
  advancedPath: boolean = false;
  suggestions: any = [];

  //
  gameConfig: any;
  entryPayoutWater: any;
  entryPayoutCoins: any;
  showCoinConfig: boolean = false
  showWaterConfig: boolean = false
  //
  voteStartDate: Date;
  voteEndDate: Date;
  //
  typeColor: string = '';
  automationLockedFields: string[] = [];
  originalValues: any;
  //
  filteredObjects: any;
  restrictions: any;
  spawnerNames: any;

  fileName: any;
  selectedTypeRef: any;

  display: boolean = false;
  prizeRefWarningDialog: boolean = false;

  prizeReward: any = {
    prizes: [],
    originalPrizes: null,
    rewards: [],
    originalRewards: null
  }

  preview_RenderImg: any;
  preview_FeedImg: any;

  showOk: boolean = false;
  hideBypass: boolean = false;
  selectedItem: any;

  checkTimeZone: boolean;
  restrictedRewards: any[] = [];
  staticRestrictedRewards: any[] = [];

  originalRecord: any = {};
  startDateErrorMessage: string = "";
  endDateErrorMessage: string = "";
  envRules = {
    'dev': {
      rules: ['First Succesful build'],
      status: true,
    },
    'qa': {
      rules: ['Start and End dates have values'],
      status: true,
    },
    'prod': {
      rules: ['Challenge has both 4.0 and 5.0 Prizes', "Has a valid 'qa' environment flag"],
      status: true,
    },
  }

  bundleControls = [
    { name: 'Bundle Asset (IOS)', control: 'bundleAsset' },
    { name: 'Bundle Image (IOS)', control: 'bundleImage' },
    { name: 'Bundle Asset (AND)', control: 'bundleAssetAnd' },
    { name: 'Bundle Image (AND)', control: 'bundleImageAnd' }
  ]

  confirmationDialogShown: boolean = false;

  dateFields = [{
    name: 'start',
    label: 'Start Date/Time',
    onSelect: 'challengeValidationService.onStartDateSelect($event, challengeForm)',
  }, {
    name: 'end',
    label: 'End Date/Time',
    onSelect: 'updateVoteDates($event)',
  }];

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private challengeValidationService: ChallengeValidationService,
    private messageService: MessageService,
    private authService: AuthService,
    private dataService: DataService,
    private confirmationService: ConfirmationService,
    private setDefaultTimeService: SetDefaultTimeService,
    private cdr: ChangeDetectorRef,
    public utilitiesService: UtilitiesService,
    private titleService: Title,
    private officeTimeFormPipe: OfficeTimeFormPipe,
    private validationService: ValidationsService,
  ) { }

  async ngOnInit() {
    // url to submit the form to.
    this.submitUrl = `${this.key}/${this.action}`;
    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight();
    let result = localStorage.getItem('gameConfig')
    this.gameConfig = JSON.parse(result ? result : '');

    /**
     * form fields / defaults
     */
    // console.log(this.fields)

    // check for ID for edit mode.
    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));
    if (this.id) {
      this.isEditMode = true;
      this.simplePathImage = String(this.id)
      this.submitUrl = `${this.key}/update/${this.id}`;
      this.existingDoc = await this.getExistingDoc();

      if (this.existingDoc) {
        await this.setExistingValues();
        await this.initForm();
        if (this.existingDoc?.rewards_ref && this.existingDoc.rewards_ref.length <= 0) {
          await this.setDefaultEntryPayout(this.existingDoc.type_ref ? this.existingDoc.type_ref.name : '');
        }

      } else {
        throwError(() => new Error('Error getting existing document'));
      }
    } else {
      // initialize form
      await this.initForm();

    }
    // Temporary placeholder for File Name
    if (this.fields.scene != null) {
      var splittedScene = this.fields.scene.split('/');
      this.fileName = splittedScene[3];
    }

    /**
     * Setting Preview Image for Feed images
     *
     * This block watches for changes from the form field image which is Feed Image Path
     * * this.preview_img is being used to set the img src on the template
     * We slowly slice the url up and return a src url to where the image is being stored
     * s3Cdn is defined on line 52
     */

    this.challengeForm.get('image')?.valueChanges!.subscribe((x) => {
      if (x != null) {
        var n = x.lastIndexOf('/');
        var result = x.substring(n + 1);
        const fileN = result + '_1024.jpg';

        if (fileN) {
          this.preview_FeedImg = this.CONFIG.s3Cdn + x + '/' + fileN;
        }
      }
    });

    /**
     * Setting up Level Render Image Preview
     *
     */

    this.challengeForm.get('scene')?.valueChanges!.subscribe((x) => {
      if (x != null) {
        var n = x.lastIndexOf('/');
        var result = x.substring(n + 1);
        console.log('result', result);
        let removeLevelStringPos = x.lastIndexOf('s/');
        let removeLevelString = x.substring(removeLevelStringPos + 1);
        console.log('checking removeLevelString', removeLevelString);

        const fileN = 'images/bg_renders' + removeLevelString + '_bg_1024.png';
        console.log('fileN', fileN);

        let addBg = fileN.split('/');
        addBg[3] = `${addBg[3]}_bg`;
        let joinAddBg = addBg.join('/');

        if (fileN) {
          this.preview_RenderImg = this.CONFIG.s3Cdn + joinAddBg;
          console.log('this fields scene', this.preview_RenderImg);
        }
      }
    });

    if (this.isEditMode) {
      this.titleService.setTitle(`Edit ${this.existingDoc.name}(${this.id})`)
    } else {
      this.titleService.setTitle(`Add New Challenge`)
    }

    if (!this.prizeReward.rewards || this.prizeReward.rewards.length === 0) {
      // Set challengeType if not already set
      if (!this.challengeType && this.existingDoc && this.existingDoc.type_ref) {
        this.challengeType = this.existingDoc.type_ref.name;
      }

      // Call setDefaultEntryPayout based on the challenge type
      if (!this.prizeReward.rewards || this.prizeReward.rewards.length === 0) {
        await this.setDefaultEntryPayout(this.challengeType);
      }
    }
    // Initialize staticRestrictedRewards with the initial restricted rewards
    this.staticRestrictedRewards = _.cloneDeep(this.restrictedRewards);
    this.originalRecord = _.cloneDeep(this.existingDoc);
    this.loading = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  get prizes_ref() {
    return this.challengeForm.get('prizes_ref') as UntypedFormArray;
  }

  get rewards_ref() {
    return this.challengeForm.get('rewards_ref') as UntypedFormArray;
  }

  /**
   *
   * @param index - indicates which prize is being editted
   * This functions targets the autocomplete field of prize refs and simply resets it back to null after being rejected if the prize item has a start and end date.
   *
   */

  updatePrizeRefsItem(index: number) {
    this.prizeReward.prizes[index].id = null;
  }

  /**
   * Get existing document from the DB.
   * @returns
   */
  async getExistingDoc() {
    return await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
  }

  setVoteDates(endDate: any) {
    const voteStart = new Date(endDate);
    voteStart.setDate(voteStart.getDate());

    const voteEnd = new Date(voteStart);
    voteEnd.setDate(voteEnd.getDate() + 1);

    // Update the form control values
    this.challengeForm.get('voteStart')?.setValue(voteStart);
    this.challengeForm.get('voteEnd')?.setValue(voteEnd);

  }

  async setExistingValues() {
    /**
     * Copies reference IDs from the specified source property of `this.existingDoc` to the corresponding target property of `this.fields`.
     * If the source property exists and is not null, the target property will be assigned an array containing the `_id` values from the source.
     *
     * @param sourceProp - The property name in `this.existingDoc` from which to copy the reference IDs.
     * @param targetProp - The property name in `this.fields` to which the reference IDs will be assigned.
     *
     * Example Usage:
     * copyRefs('spawnAudios_ref', 'spawnAudios_ref');
    */
    const copyRefs = (sourceProp: keyof typeof this.existingDoc, targetProp: keyof typeof this.fields, fields: string[] = []) => {
      if (this.existingDoc[sourceProp]) {
        this.fields[targetProp] = this.existingDoc[sourceProp].map((record: any) => {
          if (fields.length === 0) {
            return record._id;
          }
          const result: any = {};
          fields.forEach(field => {
            result[field] = record[field];
          });
          return result;
        });
      }
    };

    let keys = Object.keys(this.existingDoc);
    keys.forEach((key: any) => {
      if (this.existingDoc[key] &&
        ['sceneType', 'progressionLevel_ref', 'type_ref', 'climate_ref', 'sponsor_ref', 'keywords_ref', 'artist_ref', 'tester_ref'].includes(key)) {
        this.fields[key as keyof challengeDTO] = this.existingDoc[key]._id;
      } else if (this.existingDoc[key]) {
        this.fields[key as keyof challengeDTO] = this.existingDoc[key]
      }
    });

    if (this.existingDoc.start) {
      this.fields.start = new Date(this.officeTimeFormPipe.transform(this.existingDoc.start));
    }
    if (this.existingDoc.end) {
      this.fields.end = new Date(this.officeTimeFormPipe.transform(this.existingDoc.end));

      this.fields.voteStart = new Date(this.fields.end)
      this.fields.voteStart.setDate(this.fields.voteStart.getDate());
      this.fields.voteEnd = new Date(this.fields.voteStart);
      this.fields.voteEnd.setDate(this.fields.voteEnd.getDate() + 1);
    }

    if (this.existingDoc.prizes_ref) {
      this.setupReferenceArray(this.existingDoc.prizes_ref, 'prizes');
    }
    if (this.existingDoc.rewards_ref) {
      this.setupReferenceArray(this.existingDoc.rewards_ref, 'rewards');
    }

    this.fields.typeColor = this.existingDoc?.type_ref?.color;

    copyRefs('keywords_ref', 'keywords_ref', ['id', 'name', '_id']);
    this.keywords_ref = this.fields.keywords_ref;

    copyRefs('collection_ref', 'collection_ref', ['id', 'name']);
    this.collection_ref = this.fields.collection_ref;

    copyRefs('restrictions_ref', 'restrictions_ref', ['id', 'name', '_id']);
    copyRefs('spawnAudios_ref', 'spawnAudios_ref');
    copyRefs('loopAudios_ref', 'loopAudios_ref');
    copyRefs('spawnAudioCollections_ref', 'spawnAudioCollections_ref');
    copyRefs('loopAudioCollections_ref', 'loopAudioCollections_ref');
    copyRefs('contentHold_ref', 'contentHold_ref');

    const getLastPathSegment = (path: string | undefined) => path?.length ? path.split('/').pop() || '' : '';
    this.simplePathScene = getLastPathSegment(this.existingDoc.scene);
    this.simplePathImage = getLastPathSegment(this.existingDoc.image);
  }

  clearField(fieldKey: string) {
    this.challengeForm.get(fieldKey)!.reset();
    this.challengeForm.controls[fieldKey].markAsTouched();


    this.messageService.add({
      severity: 'success',
      summary: 'Field Cleared',
      detail: `Field ${fieldKey} has been cleared.`
    });

  }



  /**
   * Initialize the form.
   */
  async initForm() {

    this.originalValues = _.cloneDeep(this.fields);

    // Define the form control configurations
    const formControls: { [key: string]: any } = {
      // Special array fields
      prizes_ref: this.fb.array(this.fields.prizes_ref),
      rewards_ref: this.fb.array(this.fields.rewards_ref),
    };

    // Add all other fields from this.fields
    const standardFields = [
      'name', 'start', 'end', 'voteStart', 'voteEnd', 'enabled',
      'bypassChecked', 'bundleAsset', 'bundleImage', 'bundleAssetAnd',
      'bundleImageAnd', 'collection_ref', 'scene', 'image', 'fileName',
      'climate_ref', 'sponsor_ref', 'year', 'keywords_ref', 'feedImageStatus',
      'feedImage', 'type_ref', 'typeColor', 'description', 'descriptionShort',
      'internalNotes', 'productionNotes', 'location', 'progressionLevel_ref',
      'sceneType', 'tester_ref', 'artist_ref', 'restrictions_ref', 'env',
      'fileType', 'parent_challenge', 'child_challenge', 'contentHold_ref',
      'spawnAudios_ref', 'loopAudios_ref', 'spawnAudioCollections_ref',
      'loopAudioCollections_ref', 'isInfiniteChallengeExcluded'
    ];

    standardFields.forEach(field => {
      formControls[field] = [this.fields[field]];
    });

    // Create the form group with the controls
    this.challengeForm = this.fb.group(formControls);


    /**
     * set all field options for the form.
     */
    await this.challengeValidationService.setOptions(this.options);
    this.checkTimeZone = this.utilitiesService.isUserLocalTimeZonePST();
  }

  setOriginal(fieldName: string) {
    if (this.originalValues && this.originalValues.hasOwnProperty(fieldName)) {
      this.challengeForm.get(fieldName)?.setValue(this.originalValues[fieldName]);
    }
  }

  /**
 * This function builds a suggested path for scene and feed image values based on the user's input.
 * @param
 * uses this variables to store the path's state:
 *  fileTypePath: string = '';
    fileTypeCode: string = '';
    fileNamePath: string = '';
    defaultYearPath: string = pathconfigValues.year;
  * ---------------------------------------------------------------------------------------------
  * scene desired output: levels / [year] / [levelname] / [levelname]
  * feed image desired output: images / challenge_feed / [year] / [feed_image_name]
  * challenge bg desired output: images / bg_renders / [year] / [level_name_bg]
  */
  async onPathComponentValueChange(inputName: string, event: any) {

    if (this.isEditMode) {
      return;
    }

    this.cdr.detectChanges();
    //
    let originalFields: { [key: string]: any } = {
      levelNamePath: this.levelNamePath,
      feedImageNamePath: this.feedImageNamePath,
      defaultYearPath: this.defaultYearPath,
      scene: this.challengeForm.controls['scene'].value,
      image: this.challengeForm.controls['image'].value,
      fileName: this.challengeForm.controls['fileName'].value
    };
    //
    let option: any;
    switch (inputName) {
      case 'sceneType':
        // filtered array
        option = this.options['scene_type_ref'].filter((obj: any) => obj._id == event.value);
        console.log(`Selected scene type:`, option[0]);
        // adding file-type pathname
        this.sceneTypePath = option[0].pathName;
        var response = await this.challengeValidationService.constructLevelName(
          this.sceneTypePath,
          this.challengeType == pathconfigValues.grand,
          this.fields,
        );
        this.levelNamePath = response[0];
        this.feedImageNamePath = response[1];
        break;
      case 'challengeType':
        option = this.options[
          'type_ref'
        ].filter((obj: any) => obj._id == event.value);
        console.log(`Selected challenge type:`, option[0]);
        this.challengeType = option[0].name;
        this.setDefaultEntryPayout(this.challengeType, true)

        var response = await this.challengeValidationService.constructLevelName(
          this.sceneTypePath,
          this.challengeType == pathconfigValues.grand,
          this.fields,
          this.challengeType == 'Bonus'
        );
        this.levelNamePath = response[0];
        this.feedImageNamePath = response[1];

        // handle start date on challenge type
        let date = this.challengeForm.get('start')?.value ? this.challengeForm.get('start')?.value : null;
        console.log('date', date)
        if (date != undefined) {
          this.challengeValidationService.onStartDateSelect(date, this.challengeForm);
        }

        // this.defaultYearPath = event.value;
        break;
      case 'year':
        console.log('event:', event);
        this.defaultYearPath = event.value;
        break;
      case 'fileName':
        event.value = this.validationService.removeSpaces(event.value);
        this.challengeForm.controls['fileName'].setValue(event.value);
        this.levelNamePath = event.value;
        break;
      case 'sourcedBy':
        console.log('event:', event);
        var response = await this.challengeValidationService.constructLevelName(
          this.sceneTypePath,
          this.challengeType == pathconfigValues.grand,
          this.fields
        );
        this.levelNamePath = response[0];
        this.feedImageNamePath = response[1];
        break;
      default:
        break;
    }

    if (!this.automationLockedFields.includes('scene')) {
      this.setValueAndMarkAsTouched('year', this.defaultYearPath);
      this.fields.scene = `${pathconfigValues.type}/${this.defaultYearPath}/${this.levelNamePath}/${this.levelNamePath}`;
      let scenePath = _.cloneDeep(this.fields.scene);
      this.simplePathScene = scenePath.split("/")[scenePath.split("/").length - 1];
      this.setValueAndMarkAsTouched('scene', this.fields.scene);
    }
    if (!this.automationLockedFields.includes('image')) {
      this.fields.image = `${pathconfigValues.itemsThumbnailPathPrefix}/${this.defaultYearPath}/${this.feedImageNamePath}`;
      let imagePath = _.cloneDeep(this.fields.image);
      this.simplePathImage = imagePath.split("/")[this.fields.image.split("/").length - 1];
      this.setValueAndMarkAsTouched('image', this.fields.image);
    }
    this.fields.fileName = this.levelNamePath;
    this.setValueAndMarkAsTouched('fileName', this.fields.fileName);


    // Check if the original values of specific fields have changed
    const fieldsChanged = Object.keys(originalFields).filter(
      (field: keyof typeof originalFields) => originalFields[field] !== this.fields[field]
    );

    // Modify the notification logic in onPathComponentValueChange
    if (fieldsChanged.length > 0) {
      // Clear any existing timer
      if (this.messageDebounceTimer) {
        clearTimeout(this.messageDebounceTimer);
      }

      // Set new timer
      this.messageDebounceTimer = setTimeout(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Fields Updated',
          detail: `File path and feed image path updated based on ${inputName} value`
        });
      }, 500);
    }
  }

  async onChangeTypeRef(event: any) {
    // event.value will contain the new _id selected in the dropdown
    let selectedId = event.value;

    // Filter your options array to find the object with the _id
    let selectedType = this.options['type_ref'].filter((type: any) => type._id === selectedId);

    // If there is a match, update your typeColor form control value
    if (selectedType.length > 0) {
      this.challengeForm.get('typeColor')?.setValue(selectedType[0].color);
    }
    if (this.challengeForm.get('rewards_ref')?.value.length <= 0) {
      await this.setDefaultEntryPayout(selectedType[0].name);
    }
  }

  // Before submit validations
  async beforeSubmit() {
    // ----
    this.challengeValidationService.envsVerificationConfirmed = false;
    this.challengeValidationService.dateVerificationConfirmed = false;
    // ----

    // ----
    let { value } = this.challengeForm;

    let hasColorChanged = this.challengeValidationService.hasColorChanged(value.type_ref, this.options['type_ref'], value.typeColor);
    if (hasColorChanged) {
      this.challengeForm.controls['type_ref'].markAsTouched();
      this.challengeForm.controls['typeColor'].markAsTouched();
    }
    let spawnersOK;
    this.spawnerNames = this.existingDoc.scene_ref ? this.existingDoc.scene_ref.spawners.map((obj: any) => obj.name) : null
    if (this.challengeForm.controls['restrictions_ref']?.value && this.challengeForm.controls['restrictions_ref']?.value.length > 0) {
      spawnersOK = this.spawnerNames ? await this.challengeValidationService.validateSpawners(this.challengeForm.controls['restrictions_ref']?.value, this.spawnerNames) : false;
    } else {
      spawnersOK = true
    }


    let firstValidationMet = value.start && value.contentHold_ref && this.contentHoldDoNotUse(value.contentHold_ref);
    let secondValidationMet = value.type_ref &&
      this.options['type_ref'].some((obj: any) => obj._id == value.type_ref && obj.name == "Daily") &&
      value.restrictions_ref && value.restrictions_ref.length > 0;
    //
    //
    // Filter out the restricted rewards
    this.prizeReward.rewards = this.prizeReward.rewards.filter((reward: { t: { name: any; id: any; _id: any; }; id: { _id: any; name: any; id: any; }; c: any; lineItemType: any; }) => !this.isRestrictedReward(reward));

    if (firstValidationMet && secondValidationMet && spawnersOK) {
      try {
        await this.confirmValidation(`
          Current challenge Content Hold Flag contains "Do not use", and the challenge form has a Start Date value<br>
          Do you wish to continue submitting the form?
        `);
        await this.confirmValidation(`
          The current challenge type is "Daily", and the challenge contains <strong>${value.restrictions_ref.length}</strong> restrictions. <br>
          Do you wish to continue submitting the form?
        `);
        this.challengeValidationService.onSubmitV2('', this.challengeForm, this.keywords_ref, this.isEditMode, this.key, this.id, this.type, null, this.prizeReward);
      } catch (error) {
        this.rejectionMessage();
      }
    } else if (firstValidationMet && spawnersOK) {
      try {
        await this.confirmValidation(`
          Current challenge Content Hold Flag contains "Do not use", and the challenge form has a Start Date value<br>
          Do you wish to continue submitting the form?
        `);
        this.challengeValidationService.onSubmitV2('', this.challengeForm, this.keywords_ref, this.isEditMode, this.key, this.id, this.type, this.spawnerNames, this.prizeReward);
      } catch (error) {
        this.rejectionMessage();
      }
    } else if (secondValidationMet && spawnersOK) {
      try {
        await this.confirmValidation(`
          The current challenge type is "Daily", and the challenge contains <strong>${value.restrictions_ref.length}</strong> restrictions. <br>
          Do you wish to continue submitting the form?
        `);
        this.challengeValidationService.onSubmitV2('', this.challengeForm, this.keywords_ref, this.isEditMode, this.key, this.id, this.type, this.spawnerNames, this.prizeReward);
      } catch (error) {
        this.rejectionMessage();
      }
    } else if (!spawnersOK) {
      try {
        await this.confirmValidation(`
        The current challenge contains impossible validations due to missing spawners and the currently selected restrictions <br>
        Do you wish to continue submitting the form?`);
        this.challengeValidationService.onSubmitV2('', this.challengeForm, this.keywords_ref, this.isEditMode, this.key, this.id, this.type, this.spawnerNames, this.prizeReward);
      } catch (error) {
        this.rejectionMessage('Impossible Restriction', 'This challenge does not contain a spawner that matches the restrictions');
      }

    } else {
      this.challengeValidationService.onSubmitV2('', this.challengeForm, this.keywords_ref, this.isEditMode, this.key, this.id, this.type, this.spawnerNames, this.prizeReward);
    }
  }

  async confirmValidation(message: string) {
    this.hideBypass = true;
    return new Promise((resolve, reject) => {
      this.confirmationService.confirm({
        message: message,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.hideBypass = false;
          resolve('confirmed');
        },
        reject: () => {
          this.hideBypass = false;
          reject('rejected');
        }
      });
    });
  }

  rejectionMessage(summary: string = 'Submit Cancelled', detail: string = `changes have not been saved`) {
    this.messageService.add({
      sticky: true,
      severity: 'warn',
      summary: summary,
      detail: detail,
    });
  }


  /**
   *
   * @param event item data
   * This function checks if the item possess date to alert the user whether to remove the item or not.
   * Apart of the Prize
   */

  onPrizeSelect(event: any) {
    console.log('onPrizeSelect', event)
    if (!event || this.fields.bypassChecked) {
      this.handleBypassCase(event);
      return;
    }

    const checkChallengeStartDate = this.challengeForm.get('start')?.value;
    const checkChallengeEndDate = this.challengeForm.get('end')?.value;
    const checkChallengePrize = this.challengeForm.get('prizes_ref')?.value;

    console.log('checkChallengePrize', checkChallengePrize)

    if (!checkChallengeStartDate) {
      this.handleNoChallengeStartDate(event);
      return;
    }

    if (!checkChallengeEndDate) {
      this.handleNoChallengeEndDate(event);
      return;
    }

    const hasStart = !!event.start;
    const hasEnd = !!event.end;
    const hasReReleaseStart = !!event.reReleaseStart;
    const hasReReleaseEnd = !!event.reReleaseEnd;

    if (hasStart && hasEnd && hasReReleaseStart && !hasReReleaseEnd) {
      this.handleOriginalStartEndReReleaseStart(event, checkChallengeEndDate);
    } else if (hasStart && hasEnd && !hasReReleaseStart && !hasReReleaseEnd) {
      this.handleOriginalStartEnd(event, checkChallengeEndDate);
    } else if (hasStart && hasEnd && hasReReleaseStart && hasReReleaseEnd) {
      this.handleAllDateFields(event, checkChallengeEndDate);
    } else if (!hasStart) {
      this.handleNoOriginalStart(event, checkChallengeEndDate);
    }
  }

  handleBypassCase(event: any) {
    const message = `Bypass set to true. The Prize Automator will not update the Start Date. Would you like to Proceed ?`;
    const detail = event ? ` ${event.name} ${event.id}...` : '';
    this.acceptPrizeAutomator(message, detail, true, false, false, false);
  }

  handleNoChallengeStartDate(event: any) {
    console.log('handleNoChallengeStartDate', event)
    const message = `The Challenge has no Start date. The prize will be cleared.`;
    const detail = event && event.id ? ` ${event.name} ${event.id} has been removed from prize field` : 'Prize has been removed';
    this.rejectPrizeAutomator(message, detail, true, true, true);
  }

  handleNoChallengeEndDate(event: any) {
    const message = `The Challenge has no End date. Please add a challenge end in order to avoid errors. The prize will be cleared.`;
    const detail = event ? ` ${event.name} ${event.id} has been removed from prize field` : '';
    this.rejectPrizeAutomator(message, detail, true, true, true);
  }

  handleOriginalStartEndReReleaseStart(event: any, checkChallengeEndDate: any) {
    const newPrizeDate = new Date(checkChallengeEndDate);
    newPrizeDate.setDate(newPrizeDate.getDate() + 1);

    const currentPrizeRereleaseStartDate = new Date(event.reReleaseStart);
    let message


    message = `
      <div>
        <h3 class="p-mt-2">Original Start End Re-Release Start Date Values</h3>
      </div>
      <div class="p-grid p-mt-0">
        <div class="p-col">
          <h5>Original Start:</h5><p>${this.challengeValidationService.formatDate(event.start)}</p>
        </div>
        <div class="p-col">
          <h5>Original End:</h5><p>${this.challengeValidationService.formatDate(event.end)}</p>
        </div>
        <div class="p-col">
          <h5>Original Re-Release Start:</h5><p>${this.challengeValidationService.formatDate(event.reReleaseStart)}</p>
        </div>
        <div class="p-col">
          <h5>Original Re-Release End:</h5><p>Empty</p>
        </div>
      </div>
      <div>
      </div>
        </div>
        <div>
          <h3 class="p-m-0">New Start End Re-Release Start Values</h3>
        </div>
        <div class="p-grid">

        <div class="p-col">
          <h5> New Start:</h5><p>${this.challengeValidationService.formatDate(currentPrizeRereleaseStartDate)}</p>
        </div>

        <div class="p-col">
        <h5> New End:</h5><p>${this.challengeValidationService.formatDate(newPrizeDate)}</p>
        </div>

        <div class="p-col">
            <h5> New Re-Release Start:</h5><p>${this.challengeValidationService.formatDate(newPrizeDate)}</p>
        </div>
          <div class="p-col">
            <h5>New Re-Release End:</h5><p>Empty</p>
          </div>
        </div>
      <div>
      </div>`;



    const detail = ` ${event.name} ${event.id} has been added to prize field`;

    this.acceptPrizeAutomator(message, detail, true, false, false, false);
  }


  handleOriginalStartEnd(event: any, checkChallengeEndDate: any) {
    const newPrizeDate = new Date(checkChallengeEndDate);
    newPrizeDate.setDate(newPrizeDate.getDate() + 1);

    const currentPrizeStartDate = new Date(event.start);
    const currentPrizeEndDate = new Date(event.end);

    const message = `
    <div>
      <h3 class="p-mt-2">Original Start and End Date Values</h3>
      <p>Item's Store Start and End Dates will not be changed.</p>
    </div>
    <div class="p-grid p-mt-0">
      <div class="p-col">
          <h5>Original Start:</h5><p>${this.challengeValidationService.formatDate(currentPrizeStartDate)}</p>
      </div>
      <div class="p-col">
        <h5>Original End:</h5><p>${this.challengeValidationService.formatDate(currentPrizeEndDate)}</p>
      </div>
    </div>
    <p-divider></p-divider>
      <div>
        <h3 class="p-m-0">New Re-Release Start Date Value</h3>
      </div>
      <div class="p-grid">
        <div class="p-col">
          <h5> New Re-Release Start:</h5><p>${this.challengeValidationService.formatDate(newPrizeDate)}</p>
        </div>
        <div class="p-col">
          <h5>New Re-Release End:</h5><p>Empty</p>
        </div>
      </div>
    <p-divider></p-divider>
    <div>
    </div>`;
    const detail = ` ${event.name} ${event.id} has been added to prize field`;

    this.acceptPrizeAutomator(message, detail, true, false, false, false);
  }

  handleAllDateFields(event: any, checkChallengeEndDate: any) {
    const newPrizeDate = new Date(checkChallengeEndDate);
    newPrizeDate.setDate(newPrizeDate.getDate() + 1);

    const currentPrizeStartDate = new Date(event.start);
    const currentPrizeEndDate = new Date(event.end);
    const currentPrizeRereleaseStartDate = new Date(event.reReleaseStart);
    const currentPrizeRereleaseEndDate = new Date(event.reReleaseEnd);

    const message = `
    <div>
      <h3 class="p-mt-2">Original Start and End Date Values</h3>
      <p>Item's Store Start and End Dates will not be changed.</p>
    </div>
    <div class="p-grid p-mt-0">
      <div class="p-col">
          <h5>Original Start:</h5><p>${this.challengeValidationService.formatDate(currentPrizeStartDate)}</p>
      </div>
      <div class="p-col">
        <h5>Original End:</h5><p>${this.challengeValidationService.formatDate(currentPrizeEndDate)}</p>
      </div>
      <div class="p-col">
        <h5>Original Re-Release Start:</h5><p>${this.challengeValidationService.formatDate(currentPrizeRereleaseStartDate)}</p>
      </div>
      <div class="p-col">
        <h5>Original Re-Release End:</h5><p>${this.challengeValidationService.formatDate(currentPrizeRereleaseEndDate)}</p>
      </div>
    </div>
    <p-divider></p-divider>
      <div>
        <h3 class="p-m-0">New Re-Release Start Date Value</h3>
      </div>
      <div class="p-grid">
        <div class="p-col">
          <h5> New Start:</h5><p>${this.challengeValidationService.formatDate(currentPrizeRereleaseStartDate)}</p>
        </div>
        <div class="p-col">
          <h5> New End:</h5><p>${this.challengeValidationService.formatDate(currentPrizeRereleaseEndDate)}</p>
        </div>
        <div class="p-col">
          <h5> New Re-Release Start:</h5><p>${this.challengeValidationService.formatDate(newPrizeDate)}</p>
        </div>
        <div class="p-col">
          <h5>New Re-Release End:</h5><p></p>
        </div>
      </div>
    <p-divider></p-divider>
    <div>
    </div>`;
    const detail = ` ${event.name} ${event.id} has been added to prize field`;

    this.acceptPrizeAutomator(message, detail, true, false, false, false);
  }

  handleNoOriginalStart(event: any, checkChallengeEndDate: any) {
    const newPrizeDate = new Date(checkChallengeEndDate);
    newPrizeDate.setDate(newPrizeDate.getDate() + 1);

    const message = `
    <div style="margin-bottom: 20px;">
    <h3 class="p-m-0">Original Start and End Date Values</h3>
    </div>
    <div class="p-grid p-mt-0" style="margin-bottom: 20px;">
      <div class="p-col">
        <h5>Original Start:</h5>
        <p>Empty</p>
      </div>
      <div class="p-col" style="margin-left: 10px;">
        <h5>End:</h5>
        <p>Empty</p>
      </div>
    </div>
    <p-divider style="margin-bottom: 20px;"></p-divider>
    <div style="margin-bottom: 20px;">
      <h3 class="p-m-0">New Start and End date values</h3>
    </div>
    <div class="p-grid" style="margin-bottom: 20px;">
      <div class="p-col">
        <h5>New Start:</h5>
        <p>${this.challengeValidationService.formatDate(newPrizeDate)}</p>
      </div>
      <div class="p-col">
        <h5>New End:</h5>
        <p>Empty</p>
      </div>
    </div>
    <p-divider style="margin-bottom: 20px;"></p-divider>
    <div style="margin-bottom: 20px;">
    </div>`;
    const detail = ` ${event.name} ${event.id} has been added to prize field`;

    this.acceptPrizeAutomator(message, detail, true, false, false, false);
  }


  // Prize Automator Accept and Reject Functions

  // Accept Function

  async acceptPrizeAutomator(message: any, detail: any, showReject: boolean = false, showOk: boolean = false, hideBypass: boolean = false, displayPrizeRefDialog: boolean = false) {

    this.showOk = showOk;
    this.hideBypass = hideBypass;
    this.displayPrizeRefDialog(displayPrizeRefDialog);

    if (showReject) {
      this.confirmationService.confirm({
        message: message,
        accept: async () => {
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: detail,
            life: 3000,
          });
        },
        reject: async () => {
          this.updatePrizeRefsItem(0);
        }
      });
    } else {
      this.confirmationService.confirm({
        message: message,
        accept: async () => {
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: detail,
            life: 3000,
          });
        },
      })
    }
  }

  // Reject Function

  async rejectPrizeAutomator(message: any, detail: any, showOk: boolean = false, hideBypass: boolean = false, displayPrizeRefDialog: boolean = false) {

    this.showOk = showOk;
    this.hideBypass = hideBypass;
    this.displayPrizeRefDialog(displayPrizeRefDialog);


    this.confirmationService.confirm({
      message: message,
      reject: async () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: this.fields.bypassChecked ? 'Bypass enabled. Prize Start date will not be altered' : detail,
          life: 3000,
        });
        if (this.fields.bypassChecked) {
          console.log('check bypass', this.fields.bypassChecked);
        } else {
          this.updatePrizeRefsItem(0);
        }
        this.displayPrizeRefDialog(false);
      },
    });

  }


  // function to move the confirmation service single

  /**
     * Sets current date at midnight
     * @param patchValue checks if the value needs to be patched or not. This will be determined via if the form is using FormGroups or not.
     */
  setDefaultTime(prop: any) {
    this.setDefaultTimeService.setDefaultTime(true, this.challengeForm, prop);
  }

  revertField(field: string) {
    if (field == 'start') {
      this.challengeForm.controls[field]?.setValue(this.fields['start'])
    } else {
      this.challengeForm.controls[field]?.setValue(this.fields['end'])
    }

    this.challengeForm.controls[field].markAsTouched();

    this.messageService.add({
      severity: 'success',
      summary: 'Field Reverted',
      detail: 'The field has been reverted to its original value',
    });
  }

  /**
   * Handle the date change event when it is copy and pasted
   */
  handleDateInputChange(field: any, event: any) {
    const inputValue = event.target.value;
    const parsedDate = new Date(inputValue);

    if (field == 'start') {
      this.challengeValidationService.onStartDateSelect(event.target.value, this.challengeForm);
    }
    if (inputValue && !inputValue.includes('0:00')) {
      this.challengeForm.controls[field].setValue(parsedDate);
      this.challengeForm.controls[field].markAsUntouched();
    }
  }

  updateVoteDates(date: any) {
    this.setVoteDates(date);
  }

  /**
   *
   * @param show controls if the dialog is hidden or shown
   * This function controls whether or not the warning dialog for prize ref will be shown or not.
   */

  displayPrizeRefDialog(show: boolean) {
    return show == true
      ? (this.prizeRefWarningDialog = show)
      : (this.prizeRefWarningDialog = show);
  }

  newRestriction() {
    window.open('/restrictions/add', '_blank');
  }

  public onColorChanged(color: ColorPicker): void {
    // update form value manually
    color.writeValue(color.inputBgColor);
    color.onModelChange(color.inputBgColor);
  }


  onChallengeSelect(event: any, type: number) {
    console.log(type, event)
    if (type == 1) {
      this.challengeForm.controls['parent_challenge'].setValue(event.id)
    } else if (type == 2) {
      this.challengeForm.controls['child_challenge'].setValue(event.id)
    }
  }

  contentHoldDoNotUse(contentHoldValue: any[]): boolean {
    let values = this.options['contentHold_ref'];
    return values.some((val: any) => {
      let name = val.name.toLowerCase();
      return name === 'do not use' && contentHoldValue.includes(val._id);
    });
  }

  async setDefaultEntryPayout(type: any, edit: boolean = false) {
    const id: { [key: string]: any } = {};

    // Ensure options structure
    if (!this.options['rewards_ref'] || !Array.isArray(this.options['rewards_ref'])) {
      console.error("rewards_ref is not defined or not an array");
      return;
    }

    // Adding id to the dictionary with error handling
    id["2"] = this.options['rewards_ref'].find((element: any) => element._id == '6181ebcd7ce55a60f7ed6f88');
    id["7"] = this.options['rewards_ref'].find((element: any) => element._id == '63e61d4a20bd481c2ae19ef4');

    if (type == 'Daily') {
      let daily = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'submit_challenge_rewards_daily').Value);
      let daily_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value);
      this.entryPayoutWater = daily_water['DailyWaterReward'];
      this.entryPayoutCoins = daily[Object.keys(daily)[0]];
      this.showCoinConfig = true;
      this.showWaterConfig = true;
    } else if (type == 'Classic') {
      let classic = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'submit_challenge_rewards_classic').Value);
      let classic_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value);
      this.entryPayoutWater = classic_water['ClassicWaterReward'];
      this.entryPayoutCoins = classic[Object.keys(classic)[0]];
      this.showCoinConfig = true;
      this.showWaterConfig = true;
    } else if (type == 'Series') {
      let series_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value);
      this.entryPayoutWater = series_water['SeriesWaterReward'];
      this.showWaterConfig = true;
      this.showCoinConfig = false;
    } else if (type == 'Grand') {
      let grand_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value);
      this.entryPayoutWater = grand_water['EliteWaterReward'];
      this.showWaterConfig = true;
      this.showCoinConfig = false;
    }

    this.prizeReward.rewards = [];
    if (id["2"]) {
      this.prizeReward.rewards.push({
        "t": {
          "name": "Currency",
          "id": 1,
          "_id": "617b62958e2f0e4a67f86c76"
        },
        "id": {
          "_id": "6181ebcd7ce55a60f7ed6f88",
          "name": "Coin",
          "id": 2
        },
        "c": this.entryPayoutCoins,
        "lineItemType": "Currency"
      });
    }
    if (id["7"]) {
      this.prizeReward.rewards.push({
        "t": {
          "name": "Currency",
          "id": 1,
          "_id": "617b62958e2f0e4a67f86c76"
        },
        "id": {
          "_id": "63e61d4a20bd481c2ae19ef4",
          "name": "Water",
          "id": 7
        },
        "c": this.entryPayoutWater,
        "lineItemType": "Currency"
      });
    }
    // Set restricted rewards
    this.setRestrictedRewards(this.prizeReward.rewards);
  }

  setRestrictedRewards(rewards: any) {
    this.restrictedRewards = rewards.map((reward: any) => ({
      "t": {
        "name": reward.t.name,
        "id": reward.t.id,
        "_id": reward.t._id
      },
      "id": {
        "_id": reward.id._id,
        "name": reward.id.name,
        "id": reward.id.id
      },
      "c": reward.c,
      "lineItemType": reward.lineItemType
    }));
  }

  updateSimplePath(field: string) {
    if (!this.automationLockedFields.includes('scene') && field == 'scene') {
      if (this.fields.scene) {
        let path = this.fields.scene.split("/")
        path[path.length - 1] = this.simplePathScene;
        this.fields.scene = path.join("/");

        this.challengeForm.controls['scene'].setValue(path.join("/"));
        this.challengeForm.controls['scene'].markAsTouched();

        this.messageService.add({
          severity: 'success',
          summary: 'Scene Updated',
          detail: 'Please see Preview Images for updated scene image.',
        });
      };

    }
    if (!this.automationLockedFields.includes('image') && field == 'image') {
      if (this.fields.image) {
        let path = this.fields.image.split("/")
        path[path.length - 1] = this.simplePathImage;
        this.fields.image = path.join("/");

        this.challengeForm.controls['image'].setValue(path.join("/"));
        this.challengeForm.controls['image'].markAsTouched();

        this.messageService.add({
          severity: 'success',
          summary: 'Feed Image Updated',
          detail: 'Please see Preview Images for updated feed image.',
        });
      };
    }
  }

  isRestrictedReward(reward: { t: { name: any; id: any; _id: any; }; id: { _id: any; name: any; id: any; }; c: any; lineItemType: any; }) {
    return this.restrictedRewards.some((restricted: any) =>
      reward.t.name === restricted.t.name &&
      reward.t.id === restricted.t.id &&
      reward.t._id === restricted.t._id &&
      reward.id._id === restricted.id._id &&
      reward.id.name === restricted.id.name &&
      reward.id.id === restricted.id.id &&
      reward.c === restricted.c &&
      reward.lineItemType === restricted.lineItemType
    );
  }
  // Method to show confirmation dialog
  showConfirmationDialog(message: string, onConfirm: () => void, onReject: () => void) {
    this.hideBypass = true;
    this.confirmationService.confirm({
      message: message,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: onConfirm,
      reject: onReject
    });
  }

  // Method to handle rewards_ref changes
  onRewardsRefChange(event: any) {
    const originalRewards = this.prizeReward.rewards.slice(); // Clone the original rewards
    let changesMade = !_.isEqual(event, this.staticRestrictedRewards);

    const applyChanges = () => {
      // Logic to update rewards_ref
      this.prizeReward.rewards = event;
    };


    if (changesMade && !this.confirmationDialogShown) {
      // Show confirmation dialog
      this.showConfirmationDialog(
        'Changing Entry Payout will override Game Config Values. Are you sure you want to update the rewards?',
        () => {
          applyChanges(); // On Confirm
          changesMade = false;
          this.confirmationDialogShown = true; // Mark the confirmation dialog as shown
        },
        () => {
          // Revert changes if rejected
          this.prizeReward.rewards = _.cloneDeep(this.staticRestrictedRewards);
          changesMade = false;
        }
      );
    } else {
      applyChanges();
    }
  }

  handleDateSelect(fieldName: string, event: any) {
    if (fieldName === 'start') {
      this.challengeValidationService.onStartDateSelect(event, this.challengeForm);
    } else if (fieldName === 'end') {
      this.updateVoteDates(event);
    }
  }

  /**
   * Marks a form control as touched by its field name
   * @param fieldName - The name of the form control to mark as touched
   */
  markFieldAsTouched(fieldName: string): void {
    const control = this.challengeForm.get(fieldName);
    if (control) {
      control.markAsTouched();
    }
  }

  /**
 * Sets up reference arrays for prizes or rewards
 * @param sourceArray - The source array from existingDoc (prizes_ref or rewards_ref)
 * @param targetField - The field name to update ('prizes' or 'rewards')
 */
  private setupReferenceArray(sourceArray: any[], targetField: 'prizes' | 'rewards') {
    this.fields[`${targetField}_ref`] = [];
    this.prizeReward[targetField] = sourceArray;
    this.prizeReward[`original${targetField.charAt(0).toUpperCase() + targetField.slice(1)}`] = _.cloneDeep(sourceArray);

    for (const cost of sourceArray) {
      this.fields[`${targetField}_ref`].push(
        this.fb.group({
          t: cost.t,
          id: cost.id ? {
            name: cost.id.name,
            reReleaseEnd: cost.reReleaseEnd,
            reReleaseStart: cost.reReleaseStart,
            _id: cost.id._id,
            id: cost.id.id,
            start: cost.id.start,
            end: cost.id.end
          } : cost.id,
          c: cost.c,
          lineItemType: cost.lineItemType,
        })
      );
    }
  }

  setValueAndMarkAsTouched(fieldName: string, value: any) {
    this.challengeForm.controls[fieldName].setValue(value);
    this.challengeForm.controls[fieldName].markAsTouched();
  }
}
