<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="series"
    [viewName]="title"
    [record]="seriesRecord"
    [fields]="fields"
    [showLocalizedValuesButton]="true"
    [allowDuplicate]="true"
    [viewRoute]="'series'"
>
</dynamic-view-v2>
