import { AuthGuard } from "../auth/auth.guard";
import { SeedPackTableAgGridComponent } from "../cms-v2/entities/nurture/seed-pack/seed-pack-table-ag-grid/seed-pack-table-ag-grid.component";
import { SeedPackFormComponent } from "../cms-v2/entities/nurture/seed-pack/seed-pack-form/seed-pack-form.component";
import { SeedPackViewComponent } from "../cms-v2/entities/nurture/seed-pack/seed-pack-view/seed-pack-view.component";

export const seedPackRoutes = {
  prefix: 'seed-pack',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SeedPackTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SeedPackFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SeedPackViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SeedPackFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};