import { AuthGuard } from "../auth/auth.guard";
import { StoreListingV2TableAgGridComponent } from "../cms-v2/store/store-listing-v2/store-listing-v2-table-ag-grid/store-listing-v2-table-ag-grid.component";
import { StoreListingV2FormComponent } from "../cms-v2/store/store-listing-v2/store-listing-v2-form/store-listing-v2-form.component";
import { StoreListingV2ViewComponent } from "../cms-v2/store/store-listing-v2/store-listing-v2-view/store-listing-v2-view.component";

export const storeListingsV2Routes = {
  prefix: 'store-listings-v2',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: StoreListingV2TableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: StoreListingV2FormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: StoreListingV2ViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: StoreListingV2FormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};