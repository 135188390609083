import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SocialUser } from '@abacritt/angularx-social-login';
import { GamedataService } from 'src/app/build-gamedata/services/gamedata.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { SettingsService } from 'src/app/entities/settings/services/settings.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { DataService } from 'src/app/services/data.service';
import JSConfetti from 'js-confetti';
import * as _ from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-game-data-logs-popup',
  templateUrl: './game-data-logs-popup.component.html',
  styleUrls: ['./game-data-logs-popup.component.sass'],
  providers: [ConfirmationService],
})
export class GameDataLogsPopupComponent implements OnInit {
  displayModal: boolean = false;
  displayConfirmModal: boolean = false;
  currentUser: SocialUser = new SocialUser();
  isLoading: boolean = false;
  isInit: boolean = false;
  environmentName: 'development' | 'production' | 'test';
  gamedataLogs = {
    enabled: true,
    devGamedata: {},
    qaGamedata: {},
    testGamedata: {},
    prodGamedata: {},
    method: '',
    s3id: null,
  };
  gamedataLogsDef = {
    enabled: true,
    devGamedata: {},
    qaGamedata: {},
    testGamedata: {},
    prodGamedata: {},
    method: '',
    s3id: null,
  };
  successConfetti: Array<any> = [
    '🌸', '🌷', '🌺', '💮', '💐', '🌹', '🌼', '🌻',
    '🌱', '🌴', '🌲', '🌳', '🌿',
  ];
  failedConfetti: Array<any> = ['💩'];
  cmsSettings: any;
  jsConfetti = new JSConfetti();
  @Output() dataUpdated = new EventEmitter<void>();

  constructor(
    private messageService: MessageService,
    private gamedataService: GamedataService,
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private settingsService: SettingsService,
    private spinnerService: SpinnerService,
    private dataService: DataService,
    public authService: AuthService,

  ) {}

  async ngOnInit() {
    if (window.location.href.includes('prod.cms')) {
      this.environmentName = 'production';
    } else if (window.location.href.includes('test.cms')) {
      this.environmentName = 'test';
    } else {
      this.environmentName = 'development';
    }
    this.isInit = true;

    let result = await this.settingsService.getSettingByQuery({ query: { name: 'cms-settings' } });
    this.loggerService.log('CMS Settings: ', result);
    if (result) {
      this.cmsSettings = result;
    }
    this.isInit = false;
  }

  showModal() {
    this.displayModal = true;
  }

  showConfirmModal() {
    this.displayConfirmModal = true;
  }

  cancelBuild() {
    this.displayConfirmModal = false;
  }

  async cancelPush() {
    try {
      const response = await this.gamedataService.stopPush();

      if (response.success) {
        this.messageService.add({
          severity: 'success',
          summary: 'Stopping PUSH',
          detail: 'Push was canceled!!',
        });
      } else if (response.warning) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Stopping PUSH',
          detail: response.warning,
        });
      }
    } catch (error: any) {
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : 'An error occurred while stopping a push.';
      this.messageService.add({
        severity: 'error',
        summary: 'Error Stopping PUSH',
        detail: errorMessage,
      });
    }
  }

  async confirmBuild() {
    this.displayConfirmModal = false;
    const buildResult = await this.onGameDataBuildV3('dev', true);

    if (buildResult.Success) {
      this.dataUpdated.emit();
    }
  }

  async onGameDataBuildV3(env: string, isConsecutive: boolean = false) {
    this.spinnerService.loadSpinner();
    this.isLoading = true;
    let request = {
      isConsecutive: isConsecutive,
      exportAll: true,
    };

    try {
      let response = await this.gamedataService.buildGamedataV3(env, request);

      console.log('Build Gamedata response: ', response);
      this.gamedataLogs.s3id = response && response.buildVersion ? response.buildVersion : null;

      if (response && response.success) {
        console.log('Build Gamedata Success response.');
        this.messageService.add({
          severity: 'success',
          summary: 'Gamedata built',
          detail:
            env == 'test'
              ? `*Test* Gamedata was built successfully and uploaded to S3. Build Version: ${response.buildVersion}`
              : env == 'dev'
              ? `*Development*  Gamedata was built successfully and skipped s3 upload. NODE_ENV: ${this.environmentName} | Build Version: ${response.buildVersion}`
              : `Gamedata was built successfully and uploaded to S3. Build Version: ${response.buildVersion}`,
        });

        window.scrollTo(0, 0);
        this.jsConfetti.addConfetti({
          emojis: this.successConfetti,
          confettiNumber: 50,
        });
        this.dataUpdated.emit();
        this.isLoading = false;
        this.spinnerService.endSpinner();
        return { Success: true };
      } else {
        console.log('Build Gamedata Error response.', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: response.error ? response.error : 'Build Error',
          detail: response.message ? response.message : 'There was an error in the build.',
        });

        this.handleGamedataError(env, response);
        this.spinnerService.endSpinner();

        return { Success: false, isGamedataBuildInProgress: response.isGamedataBuildInProgress };
      }
    } catch (response: any) {
      console.log('Build Gamedata Catch Error response.', response);

      switch (response.status) {
        case 403:
          this.messageService.add({
            severity: 'error',
            summary: 'Build Error',
            detail: response && response.error ? response.error.error : 'There was an error in the build.',
          });
          break;
        case 400:
          this.messageService.add({
            severity: 'error',
            summary: 'Build Error',
            detail:
              response && response.error && response.error.error == 'Gamedata Validation'
                ? response.error.message
                : response && response.error
                ? response.error.s3Response
                : 'There was an error in the build.',
          });
          break;
        default:
          this.messageService.add({
            severity: 'error',
            summary: 'Build Error',
            detail: 'There was an error in the build.',
          });
          break;
      }
      this.loggerService.log('error response: ', response);

      if (response.status != 504) {
        this.isLoading = false;
        this.spinnerService.endSpinner();
        return { Success: false, isGamedataBuildInProgress: false };
      } else {
        this.isLoading = false;
        this.spinnerService.endSpinner();
        window.scrollTo(0, 0);
        this.jsConfetti.addConfetti({
          emojis: this.successConfetti,
          confettiNumber: 50,
        });
        return { Success: true };
      }
    }
  }

  private handleGamedataError(env: string, response: any) {
    switch (env) {
      case 'dev':
        this.gamedataLogs.devGamedata = {
          status: 'Failed',
          message: response.message ? response.message : '',
          error: response.error ? response.error : '',
        };
        break;
      case 'test':
        this.gamedataLogs.testGamedata = {
          status: 'Failed',
          message: response.message ? response.message : '',
          error: response.error ? response.error : '',
        };
        break;
      case 'stage':
        this.gamedataLogs.qaGamedata = {
          status: 'Failed',
          message: response.message ? response.message : '',
          error: response.error ? response.error : '',
        };
        break;
    }
    this.gamedataLogs.method = 'Manual';
    this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
    this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
  }
}
