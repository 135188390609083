import { AuthGuard } from "../auth/auth.guard";
import { NurtureStageTableAgGridComponent } from "../cms-v2/entities/nurture/nurture-stage/nurture-stage-table-ag-grid/nurture-stage-table-ag-grid.component";
import { NurtureStageFormComponent } from "../cms-v2/entities/nurture/nurture-stage/nurture-stage-form/nurture-stage-form.component";
import { NurtureStageViewComponent } from "../cms-v2/entities/nurture/nurture-stage/nurture-stage-view/nurture-stage-view.component";

export const nurtureStageRoutes = {
  prefix: 'nurture-stage',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: NurtureStageTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: NurtureStageFormComponent,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: NurtureStageViewComponent,     
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: NurtureStageFormComponent,  
        canActivate: [AuthGuard],
      },
    ]
  }
};