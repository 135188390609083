import { AuthGuard } from "../auth/auth.guard";
import { AudioCollectionTableAgGridComponent } from "../cms-v2/entities/audio-collection/audio-collection-table-ag-grid/audio-collection-table-ag-grid.component";
import { AudioCollectionFormV3Component } from "../cms-v2/entities/audio-collection/audio-collection-form-v3/audio-collection-form-v3.component";
import { AudioCollectionViewComponent } from "../cms-v2/entities/audio-collection/audio-collection-view/audio-collection-view.component";

export const audioCollectionsRoutes = {
  prefix: 'audio-collections',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: AudioCollectionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: AudioCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: AudioCollectionViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: AudioCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};