import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Rewards Track DTO
 */
export default class RewardsTrackDTO extends BaseEntityDto
{
    /**
    * List of line-items - Day 1
    */
    dailyRewardDay1: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1250,
            lineItemType: "Currency"
        }
    ];
    /**
     * List of line-items - Day 2
     */
    dailyRewardDay2: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1300,
            lineItemType: "Currency"
        },
        {
            t: {
                enabled: true,
                end: null,
                id: 7,
                name: "SeedPackBox",
                start: null,
                _id: "65cf50758dea1408c2813329",
            },
            id: {
                apiControllerRoute: "seedpackboxes",
                enabled: true,
                id: 1005,
                name: "Global_1",
                _id: "65bd804422ae09551a9cb85f"
            },
            c: 1,
            lineItemType: "SeedPackBox"
        }
    ];
    /**
     * List of line-items - Day 3
     */
    dailyRewardDay3: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1350,
            lineItemType: "Currency"
        }
    ];
    /**
     * List of line-items - Day 4
     */
    dailyRewardDay4: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1400,
            lineItemType: "Currency"
        },
        {
            t: {
                enabled: true,
                end: null,
                id: 7,
                name: "SeedPackBox",
                start: null,
                _id: "65cf50758dea1408c2813329",
            },
            id: {
                apiControllerRoute: "seedpackboxes",
                enabled: true,
                id: 1005,
                name: "Global_1",
                _id: "65bd804422ae09551a9cb85f"
            },
            c: 1,
            lineItemType: "SeedPackBox"
        }
    ];
    /**
     * List of line-items - Day 5
     */
    dailyRewardDay5: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1450,
            lineItemType: "Currency"
        }
    ];
    /**
     * List of line-items - Day 6
     */
    dailyRewardDay6: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1500,
            lineItemType: "Currency"
        },
        {
            t: {
                enabled: true,
                end: null,
                id: 7,
                name: "SeedPackBox",
                start: null,
                _id: "65cf50758dea1408c2813329",
            },
            id: {
                apiControllerRoute: "seedpackboxes",
                enabled: true,
                id: 1005,
                name: "Global_1",
                _id: "65bd804422ae09551a9cb85f"
            },
            c: 1,
            lineItemType: "SeedPackBox"
        }
    ];
    /**
     * List of line-items - Day 7
     */
    dailyRewardDay7: Array<any> = [
        {
            t: {
                enabled: true,
                end: null,
                id: 1,
                name: "Currency",
                start: null,
                _id: "617b62958e2f0e4a67f86c76",
            },
            id: {
                apiControllerRoute: "currencies",
                enabled: true,
                id: 1,
                name: "Diamond",
                _id: "6181ebd17ce55a6cf4ed6f93"
            },
            c: 1600,
            lineItemType: "Currency"
        }
    ];
    /**
     * List of line-items - Day 30
     */
    dailyRewardDay30: Array<any> = [
        
    ];
    /**
     * List of challenge submission values.
     *
     * Each stage corresponds to the track’s rewards.
     */
    stageMilestone: Array<number> = [];
    /**
     * List of arrays of line-items
     */
    free: Array<any> = [];
    /**
     * List of arrays of line-items
     */
    paid: Array<any> = [];
    /**
     * List of arrays of line-items
     */
    friends: Array<any> = [];
    /**
     * List of arrays of line-items
     */
    coop: Array<any> = [];
    /**
     * List of arrays of line-items
     */
    EOrewards: Array<any> = [];
    /**
     * List of arrays of line-items
     */
    EObundles: Array<any> = [];
    /**
     * Whether this track should loop
     */
    isLoop: boolean = false;
    /**
     * List of arrays of line-items
     */
    seasonalPrizes: Array<any> = [];
    /**
     * Reward Track type
     * 1 : daily 1-7
     * 2 : daily 30
     * 3 : season pass free
     * 4 : season pass paid
     * 5 : friends
     */
    type: number;

    [key: string]: any;
}







