import { DailyRewardsDynamicFieldComponent } from '../../../../cms-v2/entities/daily-rewards/daily-rewards-dynamic-field/daily-rewards-dynamic-field.component';
/**
 * Dynamic Input Type Enum
 */
export enum DynamicInputType
{
    // Base Input Field
    BaseInputField,
    // PrimeNG Types/Components
    TextInput = 1,
    InputTextArea,
    InputNumber,
    InputSwitch,
    MultiSelect,
    Password,
    RadioButton,
    ToggleButton,
    Slider,
    Rating,
    ListBox,
    Dropdown,
    Chips,
    AutoComplete,
    Calendar,
    ColorPicker,
    Editor,
    Button,
    CheckBox,
    // Custom Components
    Price,
    MiscImageRecord,
    MultiplePrices,
    SeasonPassComponent,
    DailyRewardsComponent,
    RewardsTrackInputComponent,
    RewardsTrackComponent,
    RestrictionComponent,
    CollectionsCustomField,
    ChallengeCollectionsCustomField,
    LoadingScreenImages,
    DailyRewardsDynamicFieldComponent,
    SeasonPassFormTracksCustomField,
    DropdownDependent,
    PlasticAutoComplete,
    NurtureCustomField,
    MultiList,
    ReleaseTypeOrderList,
    SpaceCol,
    Text,
    assetInputComponent,
    nurtureItemsComponent,
    multiSideThumbnail,
    NurtureStagesField,
    RenderThumbnails,
    MultiSelectV2,
    // ENDLESS OFFERS
    // INPUT
    EndlessOfferRewardsTrackComponent,
    // VIEW
    // Nested Inputs,
    NestedInputComponent,
    // Goal Image MultiSelect
    GoalImageComponentComponent,

    // Read Only Field
    ReadonlyFieldComponent,
    EmbeddedForm,
    CategoryIcon,
    CategoryClimateIcon,
    // Preview
    InGamePreview,

    Enabled
  }
