import { AuthGuard } from "../auth/auth.guard";
import { ReleaseFormComponent } from "../entities/release/release-form/release-form.component";
import { ReleaseViewV2Component } from "../entities/release/release-view-v2/release-viewV2.component";
import { ReleasesAgGridComponent } from "../entities/release/releases-ag-grid/releases-ag-grid.component";
import { ReleasesV2AgGridComponent } from "../entities/release/releases-v2-ag-grid/releases-v2-ag-grid.component";

export const releasesRoutes = {
  prefix: 'releases',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ReleasesAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `v2/${this.prefix}`,
        component: ReleasesV2AgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ReleaseFormComponent,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ReleaseViewV2Component,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ReleaseFormComponent,  
        canActivate: [AuthGuard],
      },
    ]
  }
};