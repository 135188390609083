import { AuthGuard } from "../auth/auth.guard";
import { ChallengeTypeViewV2Component } from "../cms-v2/entities/challenge-type/components/challenge-type-view-v2/challenge-type-view-v2.component";
import { ChallengeTypeFormV3Component } from "../cms-v2/entities/challenge-type/components/challenge-type-form-v3/challenge-type-form-v3.component";
import { ChallengeTypeTableAgGridComponent } from "../cms-v2/entities/challenge-type/components/challenge-type-table-ag-grid/challenge-type-table-ag-grid.component";

export const challengeTypesRoutes = {
  prefix: 'challenge-types',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ChallengeTypeTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ChallengeTypeFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ChallengeTypeViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ChallengeTypeFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};