import { AuthGuard } from "../auth/auth.guard";
import { NurtureCardFormComponent } from "../cms-v2/entities/nurture/nurture-card/nurture-card-form/nurture-card-form.component";
import { NurtureCardTableComponent } from "../cms-v2/entities/nurture/nurture-card/nurture-card-table/nurture-card-table.component";
import { NurtureCardViewComponent } from "../cms-v2/entities/nurture/nurture-card/nurture-card-view/nurture-card-view.component";

export const nurtureCardRoutes = {
  prefix: 'nurture-card',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: NurtureCardTableComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: NurtureCardFormComponent,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: NurtureCardViewComponent,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: NurtureCardFormComponent,  
        canActivate: [AuthGuard],
      },
    ]
  }
};