import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ColorTextComponent } from 'src/app/common/ag-grid-table/cell-editors/color-text/color-text.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';

@Component({
  selector: 'app-challenge-type-table-ag-grid',
  templateUrl: './challenge-type-table-ag-grid.component.html',
  styleUrls: ['./challenge-type-table-ag-grid.component.sass']
})
export class ChallengeTypeTableAgGridComponent implements OnInit {

  public entity: string = 'challenge-types';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = 'image_ref';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Color',
        field: 'color',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Misc. Image Record',
        field: 'image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterKey: 'image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.image_ref,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true,
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      imageKeys: ['image_ref'],
      imageBuildType: BuildType.Images
    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'challenge_types',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    const entities = [
      { ref: 'image_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.Miscellaneous} },
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }

}
