import { AuthGuard } from "../auth/auth.guard";
import { CategoryViewComponent } from "../entities/category/category-view/category-view.component";
import { CategoryTableAgGridComponent } from "../cms-v2/entities/category/components/category-table-ag-grid/category-table-ag-grid.component";
import { CategoryFormV2Component } from "../cms-v2/entities/category/components/category-form-v2/category-form-v2.component";

export const categoriesRoutes = {
  prefix: 'categories',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: CategoryTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: CategoryFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: CategoryViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: CategoryFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};