import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-enterprise';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';


@Component({
  selector: 'app-endless-offer-table-ag-grid',
  templateUrl: './endless-offer-table-ag-grid.component.html',
  styleUrls: ['./endless-offer-table-ag-grid.component.sass']
})
export class EndlessOfferTableAgGridComponent implements OnInit {

  entity: string = 'endless-offer';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  public bulkUpdateColDefs: any[] = [];
  public buildParams: any = {};
  public tableParams: any = {};


  constructor(
    private cellRendererService: CellRendererService,
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
  ) { }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,
      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Internal Name',
        field: 'internalName',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,

        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,

        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRendererParams: {
          entity: this.entity,
          isEnv: true
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['dev', 'qa', 'prod'],
            addEmptyFilters: true,
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          valueObjects: true,
          isFlatList: true
        },
      },
      {
        headerName: 'Column Count',
        field: 'column_count',
        cellRenderer: TextCellRendererComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [1, 2],
          addEmptyFilters: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,

        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Reward Track Track',
        field: 'track_v2_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.track_v2_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        } ,
        floatingFilter: true,

        minWidth: 350,
        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Banner Feed Image',
        field: 'feedBannerImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'feedBannerImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.feedBannerImage_ref,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'IAP Store Image',
        field: 'IAPStoreImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'IAPStoreImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.IAPStoreImage_ref,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Offer Popup Header Banner Image',
        field: 'popupBannerImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'popupBannerImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.popupBannerImage_ref,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Tile Backgrounds',
        field: 'bundleImagery_ref',
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: true,
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.bundleImagery_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {

    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'endlessoffers',
    }
  }

  async setOptions(){
    const entities = [
      { ref: 'track_v2_ref', entity: 'rewards-track'},
      { ref: 'feedBannerImage_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.EOFeedBanner } },
      { ref: 'IAPStoreImage_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.EOIapStore } },
      { ref: 'popupBannerImage_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.EOPopupHeader } },
      { ref: 'bundleImagery_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.EOTileBg } },

    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }

}
