import { AuthGuard } from "../auth/auth.guard";
import { NurtureCollectionGroupsFormComponent } from "../cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-form/nurture-collection-groups-form/nurture-collection-groups-form.component";
import { NurtureCollectionGroupsAgGridComponent } from "../cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-ag-grid/nurture-collection-groups-ag-grid/nurture-collection-groups-ag-grid.component";
import { NurtureCollectionGroupsViewV2Component } from "../cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-view-v2/nurture-collection-groups-view-v2.component";

export const nurtureCollectionGroupsRoutes = {
  prefix: 'nurture-collection-groups',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: NurtureCollectionGroupsAgGridComponent, 
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: NurtureCollectionGroupsFormComponent,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: NurtureCollectionGroupsViewV2Component,             
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: NurtureCollectionGroupsFormComponent,  
        canActivate: [AuthGuard],
      },
    ]
  }
};