import { DataService } from './../../../services/data.service';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from '../../../auth/auth.service';
import { Output, EventEmitter } from '@angular/core';
import { BuildType } from 'src/app/enums/build-type';
import { UserContextService } from '../../services/user-context.service';
import { LoggerService } from '../../services/logger.service';
import { FormService } from 'src/app/services/form.service';
import { MiscImageService } from 'src/app/entities/misc-image/services/misc-image.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'build-asset',
  templateUrl: './build-asset.component.html',
  styleUrls: ['./build-asset.component.sass'],
  providers: [ConfirmationService]
})
export class BuildAssetComponent implements OnInit
{
  @Input() entity: any;
  @Input() btnDisabled: boolean = false;
  @Input() path: string = '';
  @Input() path_ref: any;
  @Input() type: string;
  @Input() label: string;
  @Input() buildType: BuildType;
  @Input() isHighPriority: boolean = false;
  @Input() entityBuildDataType: string;
  @Input() createMiscRecord: boolean = false;
  @Input() skipBuild: boolean = false;
  @Input() entityIndex: number;
  @Input() renderAsset: boolean = false;
  @Input() skipMiscValidation: boolean = false;
  @Output() triggerCompleteEvent = new EventEmitter<boolean>();
  recordId: any;
  currentUser: any;
  status: string;
  disableButton: boolean = false;
  miscSubmitUrl: string = 'miscellaneous-build/add';
  approvedAssetsPrefix: string = 'approved_assets/';
  disabledToolTipMessage: string | null = null; 

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private formService: FormService,
    private miscImageService: MiscImageService,
  ) {}

  /**
   * Build Asset Component Initialization
   */
  ngOnInit(): void
  {
    let user = this.authService.getSocialUser();
    this.currentUser = user.currentUser;
    if(this.entity.entityType == BuildType.Minigames){
      this.buildType = BuildType.Minigames;
    }

    if(environment.env == "test"){
      this.disableButton = true;
      this.disabledToolTipMessage = "Build actions disabled in test environment";
    }
  }

  /**
   * Sets the path for the build.
   */
  setPath()
  {
    if (this.type != 'miscellaneous-build' && this.path_ref && this.path_ref.id && this.path_ref.path)
    {
      this.recordId = this.path_ref.id;
      this.path = this.path_ref.path;
    }
    else if(this.type == 'miscellaneous-build')
    {
      this.recordId = this.entity.id;
    }
  }

  /**
   * Confirm Build Queue action.
   * If the build is high priority, then the confirmation
   * modal will display. If not, the build will be send to
   * the build queue.
   *
   * @param event Event comming from button action.
   */
  async confirmBuildQueue(event: any) {
    let result;
    if(this.buildType == BuildType.Levels){
      result = await this.checkChallengeStatusAndConfirm();
    } else {
      result = true;
    }

    if (result) {
      this.disableButton = true;
      if (this.isHighPriority) {
        await this.displayConfirmHighPriorityModal();
      } else {
        await this.sentToBuildQueue();
      }
    }
  }


  /**
   * Display the confirm high priority build
   */
  async displayConfirmHighPriorityModal()
  {
    this.confirmationService.confirm(
    {
      message: 'Are you sure that you want to send this build as high priority?',
      header: 'High Priority Build',
      icon: 'pi pi-exclamation-triangle',
      accept: async () =>
      {
        setTimeout(async () => await this.sentToBuildQueue(), 500);  // 500ms delay

      },
      reject: () =>
      {
        setTimeout(() => {
          this.disableButton = false;
          return;
        }, 500);  // 500ms delay
      }
    });
  }

  /**
   *
   */
  async displayConfirmBuildDup(messageData: any)
  {
    // console.log(messageData);
    let ids = messageData.DupID.map((obj: any) => obj.assetReferenceId);

    // Map the IDs to links and wrap them in <li> tags
    let listItems = ids.map((id: any) => `<li><a href="/challenges/${id}" target="_blank" class="">${id}</a></li>`).join('');

    this.confirmationService.confirm(
    {
      message: `
      This ${this.type}
      ${messageData.AssetReferenceId ? `ID (${this.entity.id})`: `with asset record ID (${messageData.entityId})`},
      and the following ${this.type} with IDs have the same path:
      <ul>${listItems}</ul>
      <br>
      Do you still want to build?
        `,
      header: 'Building duplicated build record',
      icon: 'pi pi-exclamation-triangle',
      accept: async () =>
      {
        await this.sentToBuildQueue(true);
      },
      reject: () =>
      {
        this.disableButton = false;
        return;
      }
    });
  }


  /**
   * Send the build job request to build queue
   */
  async sentToBuildQueue(skipDupCheck = false): Promise<boolean>
  {
    try {
      if (this.entity.isMultiple) {
        // Handle multiple assets
        for (const asset of this.entity.assets) {
          const path = this.approvedAssetsPrefix + asset.path;
          const payload = this.createBuildPayload({
            path: path,
            entityId: null, // Will be set by getMiscBuildId
            parentRecordId: asset.id,
            skipDupCheck: skipDupCheck,
            name: asset.name
          });

          try {
            if (this.type == 'miscellaneous-build' && this.createMiscRecord) {
              let miscPath = path.replace("approved_assets/", "");
              let response = await this.miscImageService.validateMiscRecordPath(miscPath);
              
              if (this.skipMiscValidation || (response.Success && response.IsOk)) {
                payload.entityId = await this.getMiscBuildId(asset);
              } else {
                this.showAlert({
                  severity: 'error',
                  summary: 'Error Queuing Build',
                  detail: `${miscPath} path already exist. Expected Path to be unique.`,
                });
                continue;
              }
            }

            if (this.validatePath() && !this.skipBuild) {
              await this.processBuildRequest(payload);
            } else {
              this.showAlert({
                severity: 'success',
                summary: 'Asset Record Created',
                detail: `Created Asset record ${payload.entityId || null} and skipped Build`,
              });
            }
          } catch (error) {
            this.showAlert({
              severity: 'error',
              summary: 'Error Processing Asset',
              detail: `Error processing asset ${asset.name}: ${error instanceof Error ? error.message : String(error)}`,
            });
          }
        }
        
        this.triggerCompleteEvent.emit(true);
      } else {
        // Existing single asset logic
        this.setPath();
        interface userData
        {
          [key: string]: any
        }
        // populating userPayload with current users's data
        let userPayload: userData = {};
        userPayload.email = this.currentUser.email;
        userPayload.name = this.currentUser.name;
        userPayload.id = this.currentUser.id;

        var payload =
        {
          path: this.path,
          recursive: true,
          buildType: this.buildType,
          entityId: this.recordId,
          user: userPayload,
          isPriority: null,
          entityBuildDataType: this.entityBuildDataType,
          isFirstBuild: (this.entity.buildData && this.entity.buildData.length > 0) || (this.entity.assetBuildOutput && Object.keys(this.entity.assetBuildOutput).length > 0) ? false : true,
          entityIndex: this.entityIndex,
          renderAsset: this.renderAsset,
          parentRecordId: this.entity.id,
          skipDupCheck: skipDupCheck,
          entityType: this.type != 'miscellaneous-build' ? this.path_ref.entityType : this.entity.entityType,
          assetType: this.type != 'miscellaneous-build' ? this.path_ref.assetType : this.entity.assetType,
          entity: this.type != 'miscellaneous-build' ? this.path_ref : this.entity,
          localized: this.entity.localized ? true : false
        };

        if(this.type == 'miscellaneous-build' && this.createMiscRecord)
        {
          let miscPath = this.path.replace("approved_assets/","")
          let response = await this.miscImageService.validateMiscRecordPath(miscPath);
          if(this.skipMiscValidation || (response.Success && response.IsOk))
          {
            payload.entityId = await this.getMiscBuildId();
          }
          else
          {
            this.showAlert
            (
              {
                severity: 'error',
                summary: 'Error Queuing Build',
                detail: `${miscPath} path already exist. Expected Path to be unique.`,
              }
            );
            this.disableButton = false;
            return false;
          }
        }

        this.loggerService.log('payload from build button', payload);
        console.log('SkipBuild:', this.skipBuild);
        if((payload.path && payload.path.length > 0 && payload.user))
        {
          if(this.validatePath() && !this.skipBuild)
          {
            try
            {
              let response = await this.dataService.sendToBuildQueue(this.buildType, payload, this.isHighPriority);
              this.loggerService.log("SendToBuildQueue - Response: ", response);
              if(response && response.Success)
              {
                let detail = this.isHighPriority ? `Sent to build queue as high priority.` : `Sent to build queue.`;
                this.showAlert(
                {
                  severity: 'success',
                  summary: 'Build Queued',
                  detail: detail,
                });
                this.triggerCompleteEvent.emit(true);
              }
              else
              {
                if(response.Type && response.Type == "originalDuplicate"){
                  await this.displayConfirmBuildDup(response);
                }
                this.showAlert(
                {
                  severity: 'error',
                  summary: 'Error Queuing Build',
                  detail: `${response.Message}`,
                });
              }
            }
            catch (error)
            {
              this.showAlert(
              {
                severity: 'error',
                summary: 'Error Queuing Build',
                detail: `There was an error sending the build to the queue. Please try again.`,
              });
            }
          } else {
            this.showAlert(
              {
                severity: 'success',
                summary: 'Asset Record Created',
                detail: `Created Asset record ${ payload.entityId?payload.entityId : null} and skipped Build`,
              });
              this.triggerCompleteEvent.emit(true);
          }
        }
        else
        {
          this.showAlert(
            {
              severity: 'error',
              summary: 'Error Queuing Build',
              detail: `Path or user not specified. Payload: ${JSON.stringify(payload)}`,
            });
        }
      }
      return true;
    } finally {
      this.disableButton = false;
    }
  }

  /**
   * Create build payload for a single asset
   */
  private createBuildPayload(options: {
    path: string,
    entityId: any,
    parentRecordId: any,
    skipDupCheck: boolean,
    name: string
  }) {
    let userPayload = {
      email: this.currentUser.email,
      name: this.currentUser.name,
      id: this.currentUser.id
    };

    return {
      path: options.path,
      recursive: true,
      buildType: this.buildType,
      entityId: options.entityId,
      user: userPayload,
      isPriority: null,
      entityBuildDataType: this.entityBuildDataType,
      isFirstBuild: true,
      entityIndex: this.entityIndex,
      renderAsset: this.renderAsset,
      parentRecordId: options.parentRecordId,
      skipDupCheck: options.skipDupCheck,
      entityType: this.entity.entityType,
      assetType: this.entity.assetType,
      entity: {
        name: options.name,
        entityType: this.entity.entityType,
        assetType: this.entity.assetType
      },
      localized: this.entity.localized || false
    };
  }

  /**
   * Process a single build request
   */
  private async processBuildRequest(payload: any) {
    try {
      let response = await this.dataService.sendToBuildQueue(this.buildType, payload, this.isHighPriority);
      if (response && response.Success) {
        let detail = this.isHighPriority ? 'Sent to build queue as high priority.' : 'Sent to build queue.';
        this.showAlert({
          severity: 'success',
          summary: 'Build Queued',
          detail: detail,
        });
      } else {
        if (response.Type && response.Type == "originalDuplicate") {
          await this.displayConfirmBuildDup(response);
        } else {
          this.showAlert({
            severity: 'error',
            summary: 'Error Queuing Build',
            detail: `${response.Message}`,
          });
        }
      }
    } catch (error) {
      this.showAlert({
        severity: 'error',
        summary: 'Error Queuing Build',
        detail: 'There was an error sending the build to the queue. Please try again.',
      });
    }
  }

  /**
   * Modified getMiscBuildId to handle single asset data
   */
  async getMiscBuildId(asset?: any) {
    let err = '';
    return new Promise((resolve, reject) => {
      let newMiscRecord = {
        path: (asset?.path || this.path).replace("approved_assets/", ""),
        name: asset?.name || this.entity.name,
        enabled: true,
        entityType: this.entity.entityType,
        assetType: this.entity.assetType,
        localized: this.entity.localized
      };
      
      this.formService
        .submitForm(newMiscRecord, this.miscSubmitUrl, false)
        .subscribe(
          async (val) => {
            resolve(val.id);
          },
          (response) => {
            let errorMessage = response.error.error.message ? response.error.error.message : response.error.message;
            let errorName = response.error.error.name ? response.error.error.name : response.error.error;
            this.loggerService.log('POST call in error', response);

            this.showAlert({
              severity: 'error',
              summary: errorName,
              detail: errorMessage,
            });
            this.disableButton = false;
            reject(response);
          },
        );
    });
  }

  /**
   * Display alert to show message to the user.
   *
   * @param message Message to show to the user when the action was executed.
   */
  showAlert(message: any)
  {
    this.messageService.add(message);
  }

  /**
   * Check if the path is valid to build
   */
  validatePath()
  {
    let pathType = "";
    let fileNameValue = "";
    let isValid = true;

    if(this.type == 'items' || this.type == 'challenges')
    {
      pathType = this.type == 'items' ? "Prefab" : "Scene";

      let fullPath = this.type == 'items' ? this.entity.prefab.split('/') : this.entity.scene.split('/');
      let fileName = fullPath[fullPath.length - 1];
      let path = fullPath[fullPath.length - 2];
      fileNameValue = fileName;

      isValid = fileName == path;

      if(!isValid)
      {
        this.showAlert
        (
          {
            severity: 'error',
            summary: 'Error Queuing Build',
            detail: `${pathType} path error. Filename (${fileNameValue}) mismatch within the path.`,
          }
        );
      }
    }

    return isValid;
  }

  validateChallengeLiveRange() {
    const start = this.entity?.start;
    const end = this.entity?.end;

    // Create voteEnd Date which is one day after the end date
    const voteStart = new Date(end);
    const voteEnd = new Date(voteStart);
    voteEnd.setDate(voteEnd.getDate() + 1);

    // Get current time in San Francisco timezone as a string
    const sfTimeString = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });

    // Convert the string back to a Date object for easier comparison
    const sfTime = new Date(sfTimeString);

    // Validate if the challenge is currently live or in voting period
    const isLive = sfTime >= new Date(start) && sfTime <= new Date(end);
    const isVoting = sfTime <= new Date(voteEnd) && sfTime > new Date(end);

    return { isLive, isVoting };
  }

  async checkChallengeStatusAndConfirm(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const { isLive, isVoting } = this.validateChallengeLiveRange();

      if (isLive || isVoting) {
        this.confirmationService.confirm({
          message: `The challenge is currently ${isLive ? 'live' : 'in voting'}. Are you sure you want to proceed?`,
          header: 'Confirmation Required',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            setTimeout(() => resolve(true), 500);  // 500ms delay
          },
          reject: () => {
            setTimeout(() => resolve(false), 500);  // 500ms delay

          }
        });
      } else {
        resolve(true);  // or resolve(false), depending on what you want to do when it's neither live nor in voting
      }
    });
  }


}
