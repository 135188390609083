import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import CategoryClimateIconDTO from '../../dtos/CategoryClimateIconDTO';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'category-climate-icon',
  templateUrl: './category-climate-icon.component.html',
  styleUrls: ['./category-climate-icon.component.sass']
})
export class CategoryClimateIconComponent implements OnInit {

  @Input() categoryClimateIconRecord: CategoryClimateIconDTO = new CategoryClimateIconDTO();
  options: any = {};
  
  @Output() onChange = new EventEmitter<any>();

  constructor(
    private utilitiesService: UtilitiesService,
    private dataService: DataService
  ) { }

  async ngOnInit() {
    // Initialize climateIcons
    if (this.categoryClimateIconRecord) {
        this.categoryClimateIconRecord.climateIcons = this.categoryClimateIconRecord.climateIcons || [];
    }

    // Fetch options for climates
    await this.utilitiesService.getOptionsFromRef(
      this.options,
      'climates',
      'climates',
      false,
      false,
      'id name _id'
    );

    console.log('options after fetching', this.options['climates']);

    // Additional utilities service call (if needed)
    await this.utilitiesService.getOptionsFromRef(this.options, 'image_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { assetType: { $in: [12] }});

    // Now build climates after fetching options
    if (this.categoryClimateIconRecord.climateIcons && this.categoryClimateIconRecord.climateIcons.length < 3) {
        await this.buildClimates();
    }
}


    async buildClimates() {
    for(var option of this.options['climates']) {
      let climate = {
        id: option.id,
        name: option.name,
        selected: null,
        deselected: null,
        disabled: null,
      }

      this.categoryClimateIconRecord.climateIcons.push(climate)
    }
  }

  onRecordChange(field: string) {
    this.onChange.emit({ field, record: this.categoryClimateIconRecord });
  }

}
