<div class="p-my-5" *ngIf="!isLoading">
    <p-table
    #dt
    [value]="itemsRefTableValue"
    dataKey="id"
    styleClass="p-datatable-itemsref"
    [rows]="10"
    [(selection)]="selectedItems"
    selectionMode=""
    [metaKeySelection]="false"
    (onRowSelect)="onRowCheckboxToggle($event.data, true)"
    (onRowUnselect)="onRowCheckboxToggle($event.data, false)"
    [loading]="isLoading"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [filterDelay]="0"
    [globalFilterFields]="['id','name']"
    >
        <ng-template pTemplate="caption">
          <ng-container  *ngIf="!isLoading">
            <div class="p-d-flex p-grid">
              <div class="p-col-12">
                <span class="p-fluid p-input-icon-left" [ngStyle]="{ align: 'left', width: '50%' }">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search Table" [style]="{ width: '50%' }">
                </span>
              </div>
              <div class="p-col-12 p-d-flex">
                <!-- AutoComplete -->
                <div class="p-col-7">
                  <p-autoComplete
                    class="copyExistingItemDialogAutoComplete"
                    [ngModelOptions]="{ standalone: true }"
                    [suggestions]="suggestions['items_ref']"
                    (completeMethod)="utilitiesService.getSuggestionsForRef(suggestions, $event.query, 'items_ref', 'items')"
                    [showEmptyMessage]="true" field="id" minLength="3"
                    (onSelect)="onSelection($event)"
                    [(ngModel)]="collection.items_ref"
                    placeholder="Add Item" >
                    <ng-template let-item pTemplate="item">
                      ({{ item.id }}) {{ item.name }}
                    </ng-template>
                  </p-autoComplete>
                </div>
                <!-- Switch and Button -->
                <div class="p-col-4 p-ml-auto" [ngStyle]="{ 'display': 'flex', 'justify-content': 'flex-end'}">
                  <span class="p-ml-4">
                    <p-inputSwitch [(ngModel)]="isBulkDelete" (onChange)="toggleBulkDelete($event)"></p-inputSwitch>
                    <small class="p-mb-1">Bulk Delete</small>
                  </span>
                  <p-button
                    label="Delete Selected Items" icon="pi pi-trash" class="p-ml-2" styleClass="p-button-danger p-button-sm"
                    [disabled]="selectedItems.length <= 0" (click)="confirmRemoveSelectedItems()"
                  ></p-button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th></th>
                <th>ID</th>
                <th>Name</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex" class="p-text-uppercase">
                <td>
                  <div *ngIf="!isBulkDelete" pButton pRipple type="button"
                      class="p-button-rounded p-button-text"
                      icon="pi pi-trash"
                      iconPos="left"
                      (click)="confirmRemoveItemRef(item)">
                  </div>
                  <p-tableCheckbox
                    [value]="item"
                    #checkboxTableP
                    [index]="rowIndex"
                    (click)="checkboxTable(checkboxTableP, $event)"
                    *ngIf="isBulkDelete"
                  ></p-tableCheckbox>
                </td>
                <td>
                    <span class="p-column-title">ID</span>
                    {{item.id}}
                </td>
                <td>
                    <span class="p-column-title">Name</span>
                    <a style="color: #81c784;" routerLink="/items/{{ item.id }}">{{item.name}} ({{item.id}})</a>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No Items Found </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-toast></p-toast>
<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
