import { AuthGuard } from "../auth/auth.guard";
import { ChallengeTableAgGridComponent } from "../cms-v2/entities/challenge/components/challenge-table-ag-grid/challenge-table-ag-grid.component";
import { ChallengeViewV2Component } from "../entities/challenge/challenge-view-v2/challenge-view.component";
import { ChallengeHistoryComponent } from "../entities/challenge/challenge-history/challenge-history.component";
import { ChallengeFormComponent } from "../entities/challenge/challenge-form/challenge-form.component";

export const challengesRoutes = {
  prefix: 'challenges',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ChallengeTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ChallengeFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ChallengeViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ChallengeFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/history/:id`,
        component: ChallengeHistoryComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};
