import { LoggerService } from './common/services/logger.service';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { MenuItem, MessageService } from 'primeng/api';
import { ThemeService } from '../app/services/theme.service';
import { AuthService } from './auth/auth.service';
import { Title } from '@angular/platform-browser';
import { UtilitiesService } from './common/services/utilities.service';
import { fromEvent, debounceTime, distinctUntilChanged, map, firstValueFrom } from 'rxjs';
import { DataService } from './services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  menuItems: MenuItem[];
  user: SocialUser | undefined = this.authService.getSocialUser()?.currentUser;
  loggedIn: Boolean = this.authService.getLoggedIn();
  GoogleLoginProvider = GoogleLoginProvider;
  isMenuFixed: boolean = false;
  isScrolling: boolean = false;
  selectedEnvironment: any

  storeTransferCount: number;

  cart: any = {
    sourceEnv: 'dev',
    destinationEnv: 'qa',
    listings: [],
    pricePoint: [],
  }
  enableTransfer: any = true;


  get themeIcon(): string {
    let themeIcon = '';
    switch (this.currentTheme) {
      case 'arya-green':
        themeIcon = 'pi pi-moon';
        break;
      case 'new-green':
        themeIcon = 'pi pi-sun';
        break;
    }
    return themeIcon;
  }

  constructor(
    public http: HttpClient,
    public socialAuthService: SocialAuthService,
    public authService: AuthService,
    private themeService: ThemeService,
    private router: Router,
    private logger: LoggerService,
    private titleService: Title,
    private utilitiesService: UtilitiesService,
    private renderer: Renderer2,
    private messageService: MessageService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
  ) {
    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(30),
        map(() => window.scrollY || window.pageYOffset),
        distinctUntilChanged()
      )
      .subscribe(scrollY => {
        this.handleScroll(scrollY);
      });
  }

  title = 'Flora CMS';

  setDocTitle(title: string) {
    this.logger.log('current title >> ' + this.titleService.getTitle());
    this.titleService.setTitle(title);
  }

  currentTheme: string = '';

  async ngOnInit() {
    const cmsTheme = localStorage.getItem('cmsTheme');
    await this.getGameConfig('qa')

     this.dataService.storeTransferCount$.subscribe(value => {
      this.storeTransferCount = this.getStoreLengths()
    })
    this.currentTheme = cmsTheme ? cmsTheme : 'arya-green';
    this.themeService.setTheme(this.currentTheme);

    const storedEnvironment = localStorage.getItem('selectedEnvironment');
    if (storedEnvironment) {
      this.selectedEnvironment = JSON.parse(storedEnvironment);
    } else {
      // Handle the case where there is no object stored
      this.selectedEnvironment = { label: 'Development', value: 'dev' };
    }

    // auth Service ------
    setTimeout(async () => {
      this.socialAuthService.authState.subscribe(async (user) => {
        setTimeout(async () => {
          if (user) {
            // this.logger.log('user from app', user);
            this.authService.setSocialUser(user);
            this.authService.setLoggedIn(user != null);
            this.authService.loginWithGoogle(user.idToken);
          } else if(this.user) {
            // this.logger.log('there is a session user:', this.user);
            // should validate token's age
            // this.logger.log('verifying token age');
            // await this.authService.evaluateToken();
          } else {
            // force logout if no user object is found.
            this.logger.log('user object is missing. logging out.');
            this.authService.logout();
          }
        }, 500);
      });
    }, 1000);
    // auth Service ------

    if (this.authService.getLoggedIn()) {
      this.authService.loadPermissions().subscribe((permissions) => {
        if (permissions?.length > 0) {
          this.setMenuItems();
        }
      });
    }

    const currentURL = window.location.href;
    const faviconElement = document.getElementById('favicon') as HTMLLinkElement;

    if (currentURL.includes('prod.cms.flora.bgamestudios.com')) {
      faviconElement.href = 'prod-favicon.ico';
    } else {
      faviconElement.href = 'favicon.ico';
    }

    console.log('Loaded Environment: ', environment);
  }

  setMenuItems() {
    this.menuItems = [
      {
        label: 'Releases',
        items: [
          {
            label: 'Series',
            url: '/series',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/series/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Seasonal Prizes',
            url: '/seasonal-prizes',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/seasonal-prizes/add',
                target: '_blank',
              },
            ],
          },
          // {
          //   label: 'Releases Table',
          //   url: 'releases',
          //   items: [
          //     {
          //       label: 'New',
          //       icon: 'pi pi-fw pi-plus',
          //       url: '/releases/add',
          //       target: '_blank',
          //     },
          //   ],
          // },
          {
            label: 'Releases Table',
            url: 'v2/releases',
          },
          //{
          //   label: 'Release Types Table',
          //   url: '/release-type',
          //   items: [
          //     {
          //       label: 'New',
          //       icon: 'pi pi-fw pi-plus',
          //       url: '/release-type/add',
          //       target: '_blank',
          //       command: () => {
          //         this.checkReadOnlyUser('/release-type/add');
          //       },
          //     },
          //   ],
          // },
          {
            label: 'Infinite Series Schedule',
            url: '/infinite-series',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/infinite-series/add',
                target: '_blank',
              },
            ],
          },
        ],
      },
      {
        label: 'Items',
        items: [
          {
            label: 'Items Table',
            url: '/items',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/items/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Sourcing Table',
            url: '/sourcing-groups',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/sourcing-groups/add',
                target: '_blank',
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: 'Batches',
            url: '/batches',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/batches/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Categories',
            url: '/categories',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/categories/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Category Collections',
            url: '/category-collections',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/category-collections/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Collections',
            url: '/collections',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/collections/add?isNUF=false',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Item Sets',
            url: '/item-sets',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/item-sets/add?isNUF=false',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Ext. Plant Data',
            url: '/external-plant-data',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/external-plant-data/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'File Types',
            url: '/item-file-types',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/item-file-types/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Keywords',
            url: '/item-keywords',
          },
          {
            label: 'Filters',
            icon: 'pi pi-fw pi-arrow-right',
            items: [
              {
                label: 'Colors',
                url: '/colors',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/colors/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Materials',
                url: '/materials',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/materials/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Patterns',
                url: '/patterns',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/patterns/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Shapes',
                url: '/shapes',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/shapes/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Styles',
                url: '/styles',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/styles/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Traits',
                url: '/traits',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/traits/add',
                    target: '_blank',
                  },
                ],
              },
            ],
          },
          {
            label: 'Item Types',
            url: '/item-types',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/item-types/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Vendors',
            url: '/vendors',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/vendors/add',
                target: '_blank',
              },
            ],
          },
        ],
      },
      {
        label: 'Challenges',
        items: [
          {
            label: 'Challenges Table',
            url: '/challenges',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/challenges/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Challenge Collections',
            url: '/challenge-collections',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/challenge-collections/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'C. Sourcing Table',
            url: '/sourcing-challenge-groups',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/sourcing-challenge-groups/add',
                target: '_blank',
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: 'Climates',
            url: '/climates',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/climates/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Challenge Types',
            url: '/challenge-types',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/challenge-types/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Keywords',
            url: '/challenges-keywords',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: 'challenge-keywords/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Restrictions',
            url: '/restrictions',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/restrictions/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Sponsors',
            url: '/sponsors',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/sponsors/add',
                target: '_blank',
              },
            ],
          },
        ],
      },
      {
        label: 'Images',
        items: [
          {
            label: 'Loading Screens',
            url: '/loading-screens',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/loading-screens/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Image Bundles',
            url: '/miscellaneous-build',
          },
        ],
      },
      {
        label: 'Nurture',
        items: [
          {
            label: 'Nurture Groups',
            url: '/nurture-collection-groups',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/nurture-collection-groups/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Nurture Collections',
            url: '/nurture-collection',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/nurture-collection/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Nurture Items',
            url: '/nurture',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/nurture/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Nurture Rarities',
            url: '/nurture-rarity',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/nurture-rarity/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Nurture Stages',
            url: '/nurture-stage',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/nurture-stage/add',
                target: '_blank',
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: 'Seed Packs',
            url: '/seed-pack',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/seed-pack/add',
                target: '_blank',
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: 'Seed Pack Boxes',
            url: '/seed-pack-box',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/seed-pack-box/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Seed Pack Box Types',
            url: '/seed-pack-box-types',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/seed-pack-box-types/add',
                target: '_blank',
              },
            ],
          },
        ],
      },
      {
        label: 'Economy',
        items: [
          {
            label: 'Economy Table',
            url: '/econ-table',
          },
          {
            label: 'Currencies',
            url: '/currencies',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/currencies/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Inboxes',
            url: '/inbox-messages',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/inbox-messages/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Progression Levels',
            url: '/progression-levels',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/progression-levels/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Resources',
            url: '/resources',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/resources/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Titles',
            url: '/titles',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/titles/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'NUF Collections',
            url: '/nuf-collection',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/collections/add?isNUF=true',
                target: '_blank',
              },
            ],
          },
          {
            separator: true,
          },
          {
            label: 'Achievements',
            items: [
              {
                label: 'Achievements',
                url: '/achievements',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/achievements/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Achievement Collections',
                url: '/achievements-collection',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/achievements-collection/add',
                    target: '_blank',
                  },
                ],
              },
            ]
          },
          {
            separator: true,
          },
          {
            label: 'Rewards & Tracks',
            items: [
              {
                label: 'Reward Tracks',
                url: '/rewards-track',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/rewards-track/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Login Rewards',
                url: '/daily-rewards',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/daily-rewards/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Garden Pass',
                url: '/season-pass',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/season-pass/add',
                    target: '_blank',
                  },
                ],
              },
              {
                label: 'Co-Op',
                url: '/co-op',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/co-op/add',
                    target: '_blank',
                  },
                ],
              },
            ]
          },
          {
            label: 'Endless Offers',
            items: [
              {
                label: 'Endless Offers',
                url: '/endless-offer',
                items: [
                  {
                    label: 'New',
                    icon: 'pi pi-fw pi-plus',
                    url: '/endless-offer/add',
                    target: '_blank',
                  },
                ],
              },
            ]
          },
          {
            separator: true,
          },
          {
            label: 'Mini Games',
            url: '/minigames',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/minigames/add',
                target: '_blank',
              },
            ],
          },
        ],
      },
      {
        label: 'Store',
        visible: this.authService.hasPermission(['sync'], 'StoreController'),
        items: [
          {
            label: 'Store Data',
            icon: 'fa-solid fa-store',
            url: '/store-game-data',
            target: '_blank',
          },
          {
            label: 'Store Sync Table',
            url: '/store-sync',
          },
          {
            label: 'Store Transfer Table',
            url: '/store-transfer',
          },
          {
            separator: true,
          },
          {
            label: 'Store Listings',
            url: '/store-listings-v2',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/store-listings-v2/add',
                visible: this.authService.hasPermission(['create', 'update'], 'StoreListingV2Controller'),
                target: '_blank',
              },
            ],
          },
          {
            label: 'Price Points',
            url: '/price-points',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                url: '/price-points/add',
                visible: this.authService.hasPermission(['create', 'update'], 'PricePointController'),
                target: '_blank',
              },
            ],
          },
        ],
      },
      {
        label: 'Development',
        items: [
          {
            label: 'Art Tools',
            icon: 'fa-solid fa-pen-ruler',
            items: [
              {
                label: 'Sync Resources',
                icon: 'fa-solid fa-download',
                url: '/art-tools',
                target: '_blank',
              },
              {
                label: 'Render Preview Tool',
                icon: 'fa-solid fa-image',
                url: '/render-preview-tool',
                target: '_blank',
              },
              {
                label: 'Render Diff Jobs',
                icon: 'fa-solid fa-clone',
                url: '/diff-jobs',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Gamedata',
            icon: 'fa-solid fa-gamepad',
            url: '/build-gamedata',
            target: '_blank',
            visible: this.authService.hasPermission(['read'], 'GamedataController'),
          },
          {
            label: 'Promote To Prod',
            icon: 'fa-solid fa-cloud-arrow-up',
            url: '/prod-gamedata',
            target: '_blank',
            visible: this.authService.hasPermission(['prod_push'], 'GamedataController'),
          },
          {
            label: 'Gamedata Diff History',
            icon: 'pi pi-fw pi-history',
            url: '/gamedata/diff',
            target: '_blank',
          },
          {
            label: 'Bulk Build Search',
            icon: 'pi pi-fw pi-search',
            url: '/bulk-build-search',
            target: '_blank',
          },
          {
            label: 'Asset Bundles',
            icon: 'fa-solid fa-file-image',
            url: '/asset-bundles',
          },
          {
            separator: true,
          },
          {
            label: 'Audio Collections',
            url: '/audio-collections',
            icon: 'fa-solid fa-file-audio',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus ',
                url: '/audio-collections/add',
                target: '_blank',
              },
            ],
          },
          {
            label: 'Localization Strings',
            icon: 'fa-solid fa-flag',
            url: '/localization-strings',
            target: '_blank',
          },
          {
            label: 'Client Strings',
            icon: 'fa fa-language',
            url: '/client-strings',
            target: '_blank',
          },
        ]
      },
    ];
  }

  onThemeSwitched() {
    switch (this.currentTheme) {
      case 'arya-green':
        this.currentTheme = 'new-green';
        break;
      case 'new-green':
        this.currentTheme = 'arya-green';
        break;
    }
    this.themeService.setTheme(this.currentTheme);
  }

  async getGameConfig(env: any) {
    try {
      // let result = await this.utilitiesService.gameConfig();
      let result = await this.utilitiesService.getGameConfigFromBackend();
      localStorage.setItem('gameConfig', JSON.stringify(result.configs));
    } catch (error) {
      console.error('Error fetching game config:', error);
    }
  }

  sidebarVisible: boolean = false;

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  transferModalVisible: boolean = false;

  toggleTransferModal() {
    this.transferModalVisible = !this.transferModalVisible
  }

  handleScroll(scrollY: number) {
  window.requestAnimationFrame(() => {
    const menuBar = this.el.nativeElement.querySelector('.p-menubar');
    const contentHeight = document.body.scrollHeight; // get the height of the page content
    const screenHeight = window.innerHeight; // get the height of the visible screen
    // console.log('contentHeight', contentHeight);
    // console.log('screenHeight', screenHeight);
    // check if the page content is at least 10% larger than the visible screen size
    if (contentHeight < screenHeight * 1.1) {
      // if it's not, do not fix the menu
      if (this.isMenuFixed) {
        this.renderer.removeClass(menuBar, 'fixed-menu');
        this.isMenuFixed = false;
      }
      return;
    }

    if (scrollY > 0) {
      if (!this.isMenuFixed) {
        this.renderer.addClass(menuBar, 'fixed-menu');
        this.isMenuFixed = true;
      }
    } else {
      if (this.isMenuFixed) {
        this.renderer.removeClass(menuBar, 'fixed-menu');
        this.isMenuFixed = false;
      }
    }
  });
}

  getStoreLengths() {
    this.reloadCart();

    if(this.cart != null || this.cart != undefined) {
       return this.cart.listings?.length + this.cart.pricePoint?.length
    } else {
      return 0
    }
  }

  async storeTransfer() {
    let cart = JSON.parse(localStorage.getItem('storeTransferCart') || 'null')

    if(cart) {
      let pricePointsIds = cart.pricePoint.length > 0 ? cart.pricePoint.map((a:any) => a.id) : []
      if (pricePointsIds.length > 0) {
        try {
          const pricePoints =  await firstValueFrom(this.http.post<any>('/api/store/transfer?entity=price-points&source='+cart.sourceEnv+'&destination='+cart.destinationEnv+'&ids='+pricePointsIds.join(), ''));

          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Price Point Records Transferred Succesfully',
            detail: pricePoints.recordsTransferred + ' Records Transferred Successfully'
          });
        } catch (error) {
          console.error('Error transferring price point records:', error);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Error Transferring Price Point Records',
            detail: 'An error occurred while transferring Price Point Records. Please try again later.'
          })
        }
      }

      let storeListingsIds = cart.listings.length > 0 ? cart.listings.map((a:any) => a.id) : []
      if (storeListingsIds.length > 0) {
        try {
          const storeListings =  await firstValueFrom(this.http.post<any>('/api/store/transfer?entity=store-listings&source='+cart.sourceEnv+'&destination='+cart.destinationEnv+'&ids='+storeListingsIds.join(), ''));
          this.messageService.add(
            {
              sticky: true,
              severity: 'success',
              summary: 'Store Listing Records Transferred Succesfully',
              detail: storeListings.recordsTransferred + ' Records Transferred Successfully'
            });
        } catch (error) {
          console.error('Error transferring store listings:', error);
            this.messageService.add({
              sticky: true,
              severity: 'error',
              summary: 'Error Transferring Store Listings',
              detail: 'An error occurred while transferring Store Listing Records. Please try again later.'
            });
        }

      }
      this.storeTransferCount = storeListingsIds?.length + pricePointsIds?.length;
    }

    const cartEmpy = {
      listings: [],
      pricePoint: [],
      sourceEnv: cart.sourceEnv,
      destinationEnv: cart.destinationEnv
    }

    localStorage.setItem('storeTransferCart', JSON.stringify(cartEmpy))

    this.transferModalVisible = false;
  }

  reloadCart() {
    this.cart = JSON.parse(localStorage.getItem('storeTransferCart') || 'null');
  }

  setEnableTransfer(event: any) {
    this.enableTransfer = event
    this.cdr.detectChanges()
  }

  /**
   * Emits event when logout requested
   */
  logout(redirectToLogin: boolean = true) {
    this.authService.logout(redirectToLogin);
    window.location.reload();
  }
}
