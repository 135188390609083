import { AuthGuard } from "../auth/auth.guard";
import { SeedPackBoxTableAgGridComponent } from "../cms-v2/entities/nurture/seed-pack-box/seed-pack-box-table-ag-grid/seed-pack-box-table-ag-grid.component";
import { SeedPackBoxFormComponent } from "../cms-v2/entities/nurture/seed-pack-box/seed-pack-box-form/seed-pack-box-form.component";
import { SeedPackBoxViewComponent } from "../cms-v2/entities/nurture/seed-pack-box/seed-pack-box-view/seed-pack-box-view.component";

export const seedPackBoxRoutes = {
  prefix: 'seed-pack-box',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SeedPackBoxTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SeedPackBoxFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: SeedPackBoxViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SeedPackBoxFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};