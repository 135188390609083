import { AuthGuard } from "../auth/auth.guard";
import { CollectionFormV3Component } from "../cms-v2/entities/collection/components/collection-form-v3/collection-form-v3.component";
import { CollectionTableViewAgGridComponent } from "../cms-v2/entities/collection/components/collection-view-ag-grid/collection-table-view-ag-grid.component";
import { CollectionTableAgGridComponent } from "../cms-v2/entities/collection/components/collection-table-ag-grid/collection-table-ag-grid.component";
import { TableViewComponent } from "../common/components/table-view/table-view/table-view.component";

export const collectionsRoutes = {
  prefix: 'collections',       
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: CollectionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: CollectionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: CollectionTableViewAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: CollectionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/table-view/:id`,
        component: TableViewComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};