import { LoggerService } from 'src/app/common/services/logger.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-enterprise';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { values } from 'lodash';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { BuildStatusCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { BuildType } from 'src/app/enums/build-type';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';

@Component({
  selector: 'app-misc-image-table-ag-grid',
  templateUrl: './misc-image-table-ag-grid.component.html',
  styleUrls: ['./misc-image-table-ag-grid.component.sass']
})
export class MiscImageTableAgGridComponent implements OnInit {

  public entity: string = 'miscellaneous-build';
  public columnDefs: ColDef[] = [];
  public columnSelection: string;
  public columnsPreProcessing: any = {}
  public autopopulateSelect: string = '';
  public options: any = {};
  public tableParams: any = {};
  public bulkUpdateColDefs: any[] = [];
  public buildParams: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private gettersService: GettersService,
    private loggerService: LoggerService
  ) { }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,

      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Localized',
        field: 'localized',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Localized',
          offLabel: 'Not Localized',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Localized' : 'Not Localized'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,

        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,

        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRendererParams: {
          entity: this.entity,
          isEnv: true
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['dev', 'qa', 'prod'],
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          valueObjects: true,
          isFlatList: true
        },
      },
      {
        headerName: 'Path',
        field: 'path',
        cellRendererParams: {
          entity: this.entity
        },
        valueGetter: (params: any) => {
          if (params.data.path){
            return params.data.path;
          } else {
            return null;
          }
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

      },
      {
        headerName: 'Asset Type',
        field: 'assetType',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params: any) => {
          if (this.options.assetType_ref && this.options.assetType_ref.length > 0) {
              let assetType = this.options.assetType_ref.find((item: any) => item.value === params.data.assetType);
              return assetType ? assetType.name : 'No data';
          } else {
              return 'No data';
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.assetType_ref,
          valueFormatter: (params: any) => {
            console.log('assetBundleOptions: ',params);
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          },
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name"
        },
        type: 'editableColumn',
      },
      // {
      //   headerName: 'Entity Type',
      //   field: 'entityType',
      //   cellRendererParams: {
      //     entity: this.entity
      //   },
      //   cellRenderer: TextCellRendererComponent,
      //   valueGetter: (params: any) => {
      //     if (this.entityTypeSettings && this.entityTypeSettings.length) {
      //         let entityType = this.entityTypeSettings.find((item: any) => item.value === params.data.entityType);
      //         return entityType ? entityType.name : 'No data';
      //     } else {
      //         return 'No data';
      //     }
      //   },
      //   resizable: true,
      //
      //   cellEditor: DropdownEditorComponent,
      //   cellEditorPopup: true,
      //   cellEditorParams: {
      //     optionValue: "value",
      //     optionLabel: "name"
      //   },
      //   type: 'editableColumn',
      // },
      {
        headerName: 'Thumbnail',
        field: 'thumbnails',
        sortable: false,
        resizable: true,
        minWidth: 120,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          return params.data.thumbnails && params.data.thumbnails[1]?
              params.data.thumbnails[1].path :
              'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
      },

      },
      {
        headerName: 'Image Versions Count',
        field: 'image_versions',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,

        valueGetter: (params) => {
          if(params.data.image_versions){
            return `${params.data.image_versions.length}`
          } else {
            return 'No data'
          }
        }
      },
      {
        headerName: 'Last Hash',
        field: 'lastHash',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

      },
      {
        headerName: 'Build Status',
        field: 'buildOutput',
        cellRendererParams: {
          entity: this.entity,
          isMiscAsset: true
        },
        cellRenderer: BuildStatusCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
            addEmptyFilters: true,
            isAggregate: true,
            filterKey: 'SKIP',
            isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Image Promoted Status',
        field: 'isImageUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.lastHash;
          const versions = params.data.image_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;

          let response = 'No data';

          if (!lastHash && versions && versions.length > 0){
            return 'Outdated';
          }

          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }
          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'No data', 'Outdated', 'Up to date'
          ],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'SKIP',
          isImageUpToDate: true,
        },
        floatingFilter: true,

      },
      {
        headerName: 'Master Build Date',
        field: 'masterBuildDate',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['clear'],
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'greaterThan',
            'inRange'
          ],
          filterKey: 'SKIP',
          isMasterBuildDate: true,
          isAggregate: true,
        },
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          entity: this.entity,
        },
        valueGetter: (params: any) => {
          const source = params.data ? params.data.buildOutput : null;
          return this.agGridToolsService.getBuildDate(source);
        },
        resizable: true,

      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params: any) => {
          return params.data.bundleImage ? 'Bundle' : 'Not Bundle'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        cellEditorPopup: true,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params: any) => {
          return params.data.bundleImageAnd ? 'Bundle' : 'Not Bundle'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        cellEditorPopup: true,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      // {
      //   headerName: 'Build Status',
      //   field: 'buildStatus',
      //   cellRenderer: BuildStatusCellRendererComponent,
      //   cellRendererParams: {
      //     key: 'buildOutput'
      //   },
      //   sortable: true,
      //   unSortIcon: true,
      //   resizable: true,
      //   filter: 'agSetColumnFilter',
      //   filterParams: {
      //       values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)']
      //   },
      //   floatingFilter: true,
      //
      //   autoHeight: true,
      //   width: 300,
      // },
    ]

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.tableParams = {
      customName: "Image Bundles",
      hideAdd: true,
      customButtons: ['addAsset'],
      defaultQuery: {  "entityType" : {"$in": [3, 4]}  },
      entityTypes: [BuildType.Images]
    }

    this.buildParams = {
      imageKeys: ['SELF'],
    }

  }

  async setOptions(){
    const entities = [
      { ref: 'assetType_ref', entity: 'ImagerySettings', select: 'id _id name path value', query: { "value": { "$ne": -1 } } },
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }
}
