import { AuthGuard } from "../auth/auth.guard";
import { AchievementCollectionViewV2Component } from "../cms-v2/entities/achievements-collection/achievement-collection-view-v2/achievement-collection-view-v2.component";
import { AchievementsCollectionFormV3Component } from "../cms-v2/entities/achievements-collection/achievements-collection-form-v3/achievements-collection-form-v3.component";
import { AchievementCollectionTableAgGridComponent } from "../cms-v2/entities/achievements-collection/achievement-collection-table-ag-grid/achievement-collection-table-ag-grid.component";

export const achievementsCollectionRoutes = {
  prefix: 'achievements-collection',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: AchievementCollectionTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: AchievementsCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: AchievementCollectionViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: AchievementsCollectionFormV3Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};