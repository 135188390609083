<div class="p-my-1 p-inputgroup" *ngIf="showPrize && !isLoading">
  <div>
    <span [style]="{ 'width': '100%' }" ngClass="p-float-label">
      <div>
        <p-fieldset class="p-formgroup-inline">
          <!-- Header -->
          <ng-template pTemplate="header">
            <div class="p-formgroup-inline">
              <span class="p-my-auto p-mr-2">{{ fieldsetName }}</span>
              <!-- Add Reward Button -->
              <button pButton icon="pi pi-plus p-panel-header-icon" class="p-button-rounded p-button-text p-button"
                (click)="addReward()" type="button"></button>
            </div>
          </ng-template>
          <ng-container *ngIf="version==1">
            <div class="p-formgroup-inline">
              <div *ngFor="let reward of prize; let pointIndex = index">
                <p-card class="p-formgroup p-m-2 p-shadow-4" *ngIf="reward">
                  <ng-template pTemplate="header">
                    <button pButton type="button" icon="pi pi-times"
                      class="p-button-rounded p-button-text p-button-danger float-right"
                      (click)="deleteReward(pointIndex)"></button>
                  </ng-template>

                  <ng-template pTemplate="content">
                    <div class="p-mt-2">
                      <p-dropdown [(ngModel)]="reward.t" [ngModelOptions]="{standalone: true}" [options]="options['t']"
                        optionLabel="name" placeholder="Select a Resource" [showClear]="true" [autoDisplayFirst]="false"
                        [filter]="true" [filterBy]="'name'" [style]="{ 'width': '100%' }" [required]="true"
                        [optionValue]="useOptionValue ? '_id' : null"
                        (onChange)="updateDependentField($event,pointIndex)"></p-dropdown>
                    </div>
                    <div class="p-mt-3">
                      <p-autoComplete *ngIf="!isCostField" [(ngModel)]="reward.id" [ngModelOptions]="{standalone: true}"
                        [suggestions]="suggestions['id']" [showEmptyMessage]="true"
                        (completeMethod)="utilitiesService.getSuggestionsForRef(suggestions, $event.query, 'id', resourceMap[reward.t.name])"
                        field="name" minLength="1" placeholder="Search by Name or ID" [required]="true"
                        (onSelect)="onPrizeChange.emit(this.prize)" (onUnselect)="onPrizeChange.emit(this.prize)"
                        (onClear)="onPrizeChange.emit(this.prize)">
                      </p-autoComplete>
                      <p-dropdown *ngIf="isCostField" [(ngModel)]="reward.id" [options]="options['id']"
                        placeholder="Select a Currency" optionLabel="name" optionValue="_id" [showClear]="true"
                        [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'" [style]="{ 'width': '100%' }"
                        [required]="true">
                        <ng-template let-currency pTemplate="item">
                          <p>{{currency.name}} ({{currency.id}})</p>
                        </ng-template>
                      </p-dropdown>
                    </div>
                    <div class="p-mt-4">
                      <span [style]="{ 'width': '100%' }" ngClass="p-float-label">
                        <p-inputNumber [(ngModel)]="reward.c" [ngModelOptions]="{standalone: true}" pInputText
                          class="field-item" (onInput)="onPrizeChange.emit(this.prize)"></p-inputNumber>
                        <label for="c"> Count </label>
                      </span>
                    </div>
                  </ng-template>
                </p-card>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="version==2">
            <p-orderList [value]="prize" (onReorder)="reorder(prize);"
              [listStyle]="{ 'min-height': '10rem', 'width': '100%' }" [header]="''" [dragdrop]="true">
              <ng-template let-reward pTemplate="item" let-i="index">
                <div class="flex justify-items-center w-full flex-wrap">
                  <div class="w-full text-center">
                    <div class="p-grid">
                      <div class="p-col-8">
                        <div class="p-mt-1">
                          <h3 *ngIf="isChallengePrize">
                            {{ i == 0 ? '4.0' : '5.0' }} Prize
                          </h3>
                          <p-dropdown [(ngModel)]="reward.t" appendTo="body" [ngModelOptions]="{standalone: true}"
                            [options]="options['t']" optionLabel="name" placeholder="Select a Resource"
                            [showClear]="true" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'"
                            [style]="{ 'width': '100%' }" [required]="true"
                            [optionValue]="useOptionValue ? '_id' : null" (onChange)="updateDependentField($event,i)">
                          </p-dropdown>
                        </div>
                        <div class="p-mt-3">
                          <p-autoComplete *ngIf="!isCostField" appendTo="body" [(ngModel)]="reward.id"
                            [ngModelOptions]="{standalone: true}" [suggestions]="suggestions['id']"
                            [showEmptyMessage]="true"
                            (completeMethod)="utilitiesService.getSuggestionsForRef(suggestions, $event.query, 'id', resourceMap[reward.t.name])"
                            field="name" minLength="1" placeholder="Search by Name or ID" [required]="true"
                            (onSelect)="isChallengePrize && i === 0 ? onChallengePrizeChange.emit($event) : onPrizeChange.emit(this.prize)"
                            (onUnselect)="onPrizeChange.emit(this.prize)" (onClear)="onPrizeChange.emit(this.prize)">
                          </p-autoComplete>
                          <p-dropdown *ngIf="isCostField" appendTo="body" [(ngModel)]="reward.id"
                            [options]="options['id']" placeholder="Select a Currency" optionLabel="name"
                            [showClear]="true" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'"
                            [style]="{ 'width': '100%' }" [required]="true" optionValue="_id">
                            <ng-template let-currency pTemplate="item">
                              <p>{{currency.name}} ({{currency.id}})</p>
                            </ng-template>
                          </p-dropdown>
                        </div>
                      </div>
                      <div class="p-col-4">
                        <button pButton type="button" icon="pi pi-times"
                          class="p-button-rounded p-button-text p-button-danger float-right"
                          (click)="deleteReward(i)"></button>
                        <div class="p-mt-4">
                          <span [style]="{ 'width': '100%' }" ngClass="p-float-label">
                            <p-inputNumber [(ngModel)]="reward.c" [ngModelOptions]="{standalone: true}" pInputText
                              class="field-item" (onInput)="onPrizeChange.emit(this.prize)"></p-inputNumber>
                            <label for="c"> Count </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-orderList>
          </ng-container>
          <ng-container *ngIf="version==3">
            <p-orderList [value]="prize" (onReorder)="reorder(prize);"
              [listStyle]="{ 'width': '100%', 'max-height': '40vh', 'overflow-y': 'auto' }" [header]="''"
              [dragdrop]="true">
              <ng-template let-reward pTemplate="item" let-i="index">
                
                <ng-container>
                  <div style="display: flex; justify-content: space-between; align-items: flex-start">
                    <ng-container *ngIf="isChallenge">
                      <h3 class="p-my-2">Prize {{i == 0 ? '4.0' : '5.0'}}</h3>
                    </ng-container>
                    <ng-container *ngIf="!isChallenge">
                      <small class="p-my-2">Resource {{i + 1}}</small>
                    </ng-container>
                    <button pButton type="button" icon="pi pi-times"
                        class="p-button-rounded p-button-text p-button-danger delete-button p-mb-1"
                        (click)="deleteReward(i)"></button>
                  </div>
                </ng-container>
                  <div class="flex-container">
                    <!-- Dropdown for Resource Selection -->
                    <div class="flex-item" *ngIf="defaultResourceType === null">
                    <p-dropdown [(ngModel)]="reward.t" appendTo="body" [ngModelOptions]="{standalone: true}"
                      [options]="options['t']" optionLabel="name" placeholder="Resource" [showClear]="true"
                      [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'" [required]="true"
                      [optionValue]="useOptionValue ? '_id' : null" (onChange)="updateDependentField($event,i)">
                    </p-dropdown>
                  </div>
                  <!-- AutoComplete or Dropdown for ID -->
                  <div class="flex-item">
                    <!-- Conditional rendering for AutoComplete or Dropdown -->
                    <p-autoComplete *ngIf="!isCostField" appendTo="body" [(ngModel)]="reward.id"
                      [ngModelOptions]="{standalone: true}" [suggestions]="suggestions['id']" [showEmptyMessage]="true"
                      (completeMethod)="utilitiesService.getSuggestionsForRef(suggestions, $event.query, 'id', resourceMap[reward.t.name])"
                      field="name" minLength="1" placeholder="Search by Name or ID" [required]="true"
                      (onSelect)="onPrizeChange.emit(this.prize)" (onUnselect)="onPrizeChange.emit(this.prize)"
                      (onClear)="onPrizeChange.emit(this.prize)">
                    </p-autoComplete>
                    <p-dropdown *ngIf="isCostField" appendTo="body" [(ngModel)]="reward.id" [options]="options['id']"
                      placeholder="Select a Currency" optionLabel="name" optionValue="_id" [showClear]="true"
                      [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'" [style]="{ 'width': '100%' }"
                      [required]="true" (onChange)="onPrizeChange.emit(this.prize)">
                      <ng-template let-currency pTemplate="item">
                        <p>{{currency.name}} ({{currency.id}})</p>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <!-- Input for Count and Delete Button -->
                  <div class="flex-item count-container">
                    <span class="p-float-label count-input">
                      <p-inputNumber [(ngModel)]="reward.c" [ngModelOptions]="{standalone: true}"
                        (onInput)="onPrizeChange.emit(this.prize)" [style]="{'max-width': '150px'}"></p-inputNumber>
                      <label htmlFor="number-input">Count</label>
                    </span>
                  </div>
                </div>
              </ng-template>
            </p-orderList>
          </ng-container>
        </p-fieldset>
      </div>
    </span>
  </div>
</div>
