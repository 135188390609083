import { AuthGuard } from "../auth/auth.guard";
import { ReleaseTypeAgGridComponent } from "../cms-v2/entities/release-type/components/release-type-ag-grid/release-type-ag-grid.component";
import { ReleaseTypeFormV2Component } from "../cms-v2/entities/release-type/components/release-type-form-v2/release-type-form-v2.component";
import { ReleaseTypeViewV2Component } from "../cms-v2/entities/release-type/components/release-type-view-v2/release-type-view-v2.component";

export const releaseTypeRoutes = {
  prefix: 'release-type',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: ReleaseTypeAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: ReleaseTypeFormV2Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ReleaseTypeViewV2Component,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: ReleaseTypeFormV2Component,  
        canActivate: [AuthGuard],
      },
    ]
  }
};