import { AuthGuard } from "../auth/auth.guard";
import { StoreSyncTableAgGridComponent } from "../cms-v2/store/store-sync/store-sync-table-ag-grid/store-sync-table-ag-grid.component";
import { Storesyncv2viewComponent } from "../cms-v2/store/storesyncv2view/storesyncv2view/storesyncv2view.component";

export const storeSyncRoutes = {
  prefix: 'store-sync',        
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: StoreSyncTableAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: Storesyncv2viewComponent,                                              
        canActivate: [AuthGuard],
      },
    ]
  }
};